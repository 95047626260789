/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";
import { employeeList } from "../../utils/appConstants";
import { convertToAmountString, isNotEmptyObj } from "../../utils/helper";
import {
  EmployeeSaleListType,
  EmployeeSalesListResponseType,
} from "../types/types";
import EmployeeStore from "./Store";
import "./index.scss";
import Table, { ColumnProps } from "../../components/Table";

const SalesListContent = (): JSX.Element => {
  const navigate = useNavigate();
  const { employeeSaleListResp, getEmployeeSaleList } = EmployeeStore();
  const [salesList, setSalesList] = useState<EmployeeSaleListType[]>();
  const [pageNumber, setPageNumber] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(20);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [tableData, setTableData] = useState<Array<any>>([]);

  useEffect(() => {
    const payload = {
      employeeId: "",
      entriesPerPage: entriesPerPage,
      pageNumber: pageNumber,
    };
    getEmployeeSaleList(payload);
  }, []);

  useEffect(() => {
    if (isNotEmptyObj(employeeSaleListResp)) {
      const {
        list,
        pageNumber: pageNo,
        entriesPerPage: entriesInPage,
        totalCount: total,
      } = employeeSaleListResp as EmployeeSalesListResponseType;

      setSalesList(list);
      setPageNumber(pageNo);
      setEntriesPerPage(entriesInPage);
      setTotalCount(total);
    }
  }, [employeeSaleListResp]);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ): void => {
    if (event) {
      const payload = {
        employeeId: "",
        entriesPerPage: entriesPerPage,
        pageNumber: page,
      };
      getEmployeeSaleList(payload);
      setPageNumber(page);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEntriesPerPage(+event.target.value);
    setPageNumber(0);
    const payload = {
      employeeId: "",
      entriesPerPage: entriesPerPage,
      pageNumber: 0,
    };
    getEmployeeSaleList(payload);
    setPageNumber(0);
  };

  const pushToDetails = (index: number): void => {
    navigate(`/sales/preview/1/${salesList ? salesList[index].sheetId : ""}`);
  };

  useEffect(() => {
    if (salesList) {
      setTableData(
        salesList.map((item, index) => ({
          slno: index + 1,
          employee: item.employeeName,
          date: item.date,
          startTime: item.startTime,
          endTime: item.endTime,
          cashSales: convertToAmountString(item.cashSales),
          cardSales: convertToAmountString(item.cardSales),
          upiSales: convertToAmountString(item.upiSales),
          diff: item.diff,
        }))
      );
    }
  }, [salesList]);

  const columnData: ColumnProps[] = [
    {
      key: "slno",
      title: "Sl No",
      alignment: "left",
    },
    {
      key: "employee",
      title: "Employee",
      alignment: "left",
    },
    {
      key: "date",
      title: "Date",
      alignment: "left",
    },
    {
      key: "startTime",
      title: "Start Time",
      alignment: "left",
    },
    {
      key: "endTime",
      title: "End Time",
      alignment: "left",
    },
    {
      key: "cashSales",
      title: "Cash Sales",
      alignment: "right",
    },
    {
      key: "cardSales",
      title: "Card Sales",
      alignment: "right",
    },
    {
      key: "upiSales",
      title: "UPI Sales",
      alignment: "right",
    },
    {
      key: "diff",
      title: "Difference",
      alignment: "center",
    },
  ];

  return (
    <div className="sales-list-container">
      <div className="sales-list-header-container">
        <div className="sales-list-header">Sales List</div>
      </div>
      <div className="sales-list-table-container">
        <Table
          columnData={columnData}
          dataSource={tableData}
          isPaginated
          pagination={{
            pageNumber: pageNumber,
            onPageChange: handlePageChange,
            entriesPerPage: entriesPerPage,
            totalCount: totalCount,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          rowClick={(index: number): void => {
            pushToDetails(index);
          }}
          style={{ maxHeight: "calc(100vh - 178px)" }}
        />
      </div>
    </div>
  );
};

const SalesList: React.FunctionComponent = () => {
  return (
    <div>
      <Layout content={<SalesListContent />} />
    </div>
  );
};

export default SalesList;
