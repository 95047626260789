/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Button,
  TableContainer,
  Table,
  TableRow,
  TableBody,
} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import "./index.scss";
import ElectricCarOutlinedIcon from "@mui/icons-material/ElectricCarOutlined";
import { CustomerDetailsResponseType, VehicleType } from "../../types/types";
import CustomerState from "../Store";
import { isNotEmptyObj } from "../../../utils/helper";
import CreditListTable from "./CreditListTable";
import PaymentListTable from "./PaymentsListTable";
import PaidIcon from "@mui/icons-material/Paid";
import AddPaymentModal from "./AddPaymentModal";
import { AccountStatementEntryType } from "../types";
import AccountStatementModal from "./AccountStatementTable";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";

interface CustomerDetailPageProps {
  customerId: string;
}

const CustomerDetailsPage = (props: CustomerDetailPageProps): JSX.Element => {
  const { customerId } = props;

  const {
    customerDetailsResponse,
    customerDetailsResponseError,
    clearCustomerCreditAndPayments,
    getCustomerDetails,
    getAccountStatement,
    getAccountStatementRespose,
    getAccountStatementError,
    clearAccountStatementResponses,
  } = CustomerState();

  const [vehicleList, setVehicleList] = useState<VehicleType[]>();

  const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);

  const [showStatement, setShowStatement] = useState(false);

  const [customerDetails, setCustomerDetails] =
    useState<CustomerDetailsResponseType>();

  const [isCreditSelected, setIsCreditSelected] = useState(true);

  const [statementEntries, setStatementEntries] =
    useState<AccountStatementEntryType[]>();

  useEffect(() => {
    if (customerId) {
      const payload = {
        customerId: customerId,
      };
      getCustomerDetails(payload);
    }
  }, []);

  useEffect(() => {
    if (customerId) {
      const payload = {
        customerId: customerId,
      };
      getCustomerDetails(payload);
      clearCustomerCreditAndPayments();
      clearAccountStatementResponses();
      setCustomerDetails({} as CustomerDetailsResponseType);
      setStatementEntries({} as AccountStatementEntryType[]);
    }
  }, [customerId]);

  useEffect(() => {
    if (isNotEmptyObj(customerDetailsResponse)) {
      setCustomerDetails(customerDetailsResponse);
      setVehicleList(customerDetailsResponse?.vehicles);
    }
  }, [customerDetailsResponse]);

  const getCustomerStatements = (): void => {
    getAccountStatement(customerId);
  };

  useEffect(() => {
    if (isNotEmptyObj(getAccountStatementRespose)) {
      setStatementEntries(getAccountStatementRespose?.list);
    }
  }, [getAccountStatementRespose, getAccountStatementError]);

  return (
    <>
      {showAddPaymentModal &&
        isNotEmptyObj(customerDetails) &&
        customerDetails && (
          <AddPaymentModal
            closeModal={(): void => setShowAddPaymentModal(false)}
            customerId={customerDetails?.customerId}
            customerName={customerDetails?.customerName}
          />
        )}
      {showStatement && isNotEmptyObj(statementEntries) && statementEntries && (
        <AccountStatementModal
          closeModal={(): void => {
            setShowStatement(false);
            getCustomerStatements();
          }}
          customerName={customerDetails?.customerName || ""}
          entries={statementEntries}
        />
      )}
      {isNotEmptyObj(customerDetails) && customerDetails && (
        <div className="customer-details-page-container">
          <div className="customer-page-header-container is-flex is-justify-content-space-between is-align-items-center">
            <div className="customer-page-name">
              {customerDetails.customerName}
            </div>
            <div className="is-flex is-justify-content-space-between is-align-items-center">
              <div className="customer-buttons">
                <Button
                  variant="contained"
                  onClick={(): void => {
                    setShowAddPaymentModal(true);
                  }}
                  color="success"
                  startIcon={<PaidIcon />}
                >
                  Add Payment
                </Button>
              </div>
              <div className="customer-buttons">
                <Button
                  variant="contained"
                  onClick={(): void => {}}
                  color="success"
                  startIcon={<ElectricCarOutlinedIcon />}
                >
                  Add Vehicle
                </Button>
              </div>
            </div>
          </div>
          <div className="details-content-container">
            <div className="columns">
              <div className="column left-side">
                <div className="contact-container">
                  <div className="contact-details">
                    <div className="contact-title">Phone Number:</div>
                    <div className="contact-content">
                      {customerDetails.contactNumber}
                    </div>
                  </div>
                </div>
                <div className="customer-vehicle-list">
                  <div className="vehicles-header">Vehicles</div>
                  <div className="vehicle-list-table">
                    {vehicleList && vehicleList.length !== 0 && (
                      <div className="vehicles-added-list">
                        <TableContainer sx={{ maxWidth: 400 }}>
                          <Table aria-label="simple table">
                            <TableBody>
                              {vehicleList.map((row) => (
                                <TableRow
                                  key={row.id}
                                  // sx={{
                                  //   "&:last-child td, &:last-child th": {
                                  //     border: 1,
                                  //   },
                                  // }}
                                >
                                  <TableCell component="th" scope="row">
                                    {row.name}
                                  </TableCell>
                                  <TableCell align="right">
                                    {row.number}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="customer-info-container">
                  <div className="contact-title">Last Credit On:</div>
                  <div className="contact-content">
                    {customerDetails.lastCreditOn}
                  </div>
                </div>
                <div className="customer-info-container">
                  <div className="contact-title">Last Credit Amount:</div>
                  <div className="contact-content">
                    {Number(customerDetails.lastCreditAmount).toLocaleString("en-IN")}
                  </div>
                </div>
                <div className="customer-info-container">
                  <div className="contact-title">Last Payment On:</div>
                  <div className="contact-content">
                    {customerDetails.lastPaymentOn}
                  </div>
                </div>
                <div className="customer-info-container">
                  <div className="contact-title">Last Payment Amount:</div>
                  <div className="contact-content">
                    {Number(customerDetails.lastPaymentAmount).toLocaleString("en-IN")}
                  </div>
                </div>
                <div className="customer-info-container">
                  <div className="contact-title">Amount Outstanding:</div>
                  <div className="contact-content">
                    {Number(customerDetails.amountOutStanding).toLocaleString("en-IN")}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="customer-transaction-container">
            <div className="transaction-selector-container">
              <div className="customer-transactions-tab-container">
                <div className="tab-container">
                  <div className="item">
                    <div
                      className={`tab-item ${
                        isCreditSelected ? "active-tab" : ""
                      }`}
                      onClick={(): void => {
                        setIsCreditSelected(true);
                      }}
                    >
                      Credit
                      <div className="select-marker-tab" />
                    </div>
                  </div>
                  <div className="item">
                    <div
                      className={`tab-item ${
                        !isCreditSelected ? "active-tab" : ""
                      }`}
                      onClick={(): void => {
                        setIsCreditSelected(false);
                      }}
                    >
                      Payments
                      <div className="select-marker-tab" />
                    </div>
                  </div>
                </div>
                <div className="">
                  <Button
                    variant="outlined"
                    onClick={(): void => {
                      setShowStatement(true);
                      getCustomerStatements();
                    }}
                    color="success"
                    startIcon={<DocumentScannerIcon />}
                  >
                    View Statement
                  </Button>
                </div>
              </div>
            </div>
            <div className="customer-transaction-table-container">
              {isCreditSelected ? (
                <CreditListTable customerId={customerId} />
              ) : (
                <PaymentListTable customerId={customerId} />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomerDetailsPage;
