/* eslint-disable no-sequences */
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { useState, useCallback, useMemo, useEffect } from "react";
import { SUCCESS_STATUS_CODES, apiEndPoints } from "./appConstants";
import * as LocalStorageHelper from "./localStorageHelper";
import { ResponseType } from "./types";

export enum Methods {
  GET = "GET",
  POST = "POST",
  DELETE = "DELETE",
}

export const client_new = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL as string,
});

export const client_old = axios.create({
  baseURL: process.env.REACT_APP_OLD_BASE_URL as string,
});

export const useAxiosLoader = (): boolean => {
  const client =
    LocalStorageHelper.getItem("selectedVersion") === "old"
      ? client_old
      : client_new;
  const [counter, setCounter] = useState(0);
  const inc = useCallback(() => setCounter((value) => value + 1), [setCounter]); // add to counter
  const dec = useCallback(() => setCounter((value) => value - 1), [setCounter]); // remove from counter

  const interceptors = useMemo(
    () => ({
      request: (config: AxiosRequestConfig): AxiosRequestConfig => (
        inc(), config
      ),
      response: (response: AxiosResponse): AxiosResponse => (dec(), response),
      error: (error: AxiosError) => (dec(), Promise.reject(error)),
    }),
    [inc, dec]
  );

  useEffect(() => {
    const reqInterceptor = client.interceptors.request.use(
      interceptors.request,
      interceptors.error
    );
    const resInterceptor = client.interceptors.response.use(
      interceptors.response,
      interceptors.error
    );
    return (): void => {
      client.interceptors.request.eject(reqInterceptor);
      client.interceptors.response.eject(resInterceptor);
    };
  }, [client.interceptors.request, client.interceptors.response, interceptors]);

  return counter > 0;
};

client_new.interceptors.request.use((config) => {
  const { headers } = config;

  let configWithCredentials = config;

  if (apiEndPoints.login !== (config.url as string)) {
    configWithCredentials = {
      ...config,
      headers: {
        Authorization: `Bearer ${LocalStorageHelper.getItem(
          LocalStorageHelper.KEYS.TOKEN
        )}`,
        ...headers,
      },
    };
  }
  return {
    ...configWithCredentials,
    data: { ...configWithCredentials.data },
  };
});

client_new.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error.response);
  }
);

client_old.interceptors.request.use((config) => {
  const { headers } = config;

  let configWithCredentials = config;

  if (apiEndPoints.login !== (config.url as string)) {
    configWithCredentials = {
      ...config,
      headers: {
        Authorization: `Bearer ${LocalStorageHelper.getItem(
          LocalStorageHelper.KEYS.TOKEN
        )}`,
        ...headers,
      },
    };
  }
  return {
    ...configWithCredentials,
    data: { ...configWithCredentials.data },
  };
});

client_old.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error.response);
  }
);

async function request<T>(
  options: AxiosRequestConfig
): Promise<ResponseType<T>> {
  const onSuccess = (
    response: AxiosResponse<ResponseType<T>>
  ): ResponseType<T> | Promise<never> => {
    if (
      response.data &&
      SUCCESS_STATUS_CODES.includes(response.status) &&
      (SUCCESS_STATUS_CODES.includes(response.data.statusCode) ||
        response.config.url === apiEndPoints.downloadClosingReport)
    ) {
      if (response.config.url === apiEndPoints.login) {
        const responseHeaders = response.headers;
        LocalStorageHelper.setItem(
          LocalStorageHelper.KEYS.TOKEN,
          responseHeaders["auth"]
        );
        LocalStorageHelper.setItem(
          LocalStorageHelper.KEYS.ISLOGGEDIN,
          LocalStorageHelper.CONSTVALS.LOGGEDINFLAG
        );
        return { ...response.data, ...response.headers };
      }
      return response.data;
    }
    return Promise.reject(response.data);
  };

  const onError = (error: AxiosResponse): Promise<never> => {
    if (error.status === 401) {
      window.location.replace("/");
      LocalStorageHelper.clear();
    }
    return Promise.reject(error);
  };

  try {
    console.log(LocalStorageHelper.getItem("selectedVersion"));
    const client =
      LocalStorageHelper.getItem("selectedVersion") === "old"
        ? client_old
        : client_new;
    const response = await client(options);
    return onSuccess(response);
  } catch (error) {
    return onError(error as AxiosResponse<any>);
  }
}

export default request;
