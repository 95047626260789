import React, { useState } from "react";
import ClickOutHandler from "react-clickout-handler";
import "./index.scss";
import { InputAdornment, TextField } from "@mui/material";
import { TextFieldProps } from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { ListValueType } from "../../features/types/types";
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";

interface PropType {
  items: Array<ListValueType>;
  value: string;
  placeholder: string;
  name: string;
  error?: string;
  onChange?: (value: string) => void;
  icon?: React.ReactNode;
  iconColor?: string;
  inputClass?: string;
  inputDisable?: boolean;
  width?: number;
}

const DropDownInput = (props: PropType): JSX.Element => {
  const { placeholder, value, items, onChange, error, width, inputDisable } =
    props;
  const [arrowDown, setArrowDown] = useState(true);

  const toggleArrowDown = (): void => setArrowDown(!arrowDown);
  const handleOnBlur = (): void => setArrowDown(true);

  const handleItemClick = (item: ListValueType): void => {
    if (item && item.code && onChange) {
      onChange(item.code);
      handleOnBlur();
    }
  };
  const dropDwonStyle = {
    width: width,
  };

  const DropDownTextField = styled((props: TextFieldProps) => (
    <TextField
      // InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
      {...props}
    />
  ))({
    "& label.Mui-focused": {
      color: `${arrowDown ? "#1976d2" : "gray"}`,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#1976d2",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: `${arrowDown ? "1px solid gray" : "1px solid #1976d2"}`,
      },
      "&:hover fieldset": {
        border: "1px solid black",
      },
      "&.Mui-focused fieldset": {
        border: "2px solid #1976d2",
      },
    },
  });

  return (
    <ClickOutHandler onClickOut={handleOnBlur}>
      <div
        className={
          inputDisable ? "drop-container cursor-disable" : "drop-container"
        }
      >
        <div onClick={toggleArrowDown}>
          <DropDownTextField
            sx={{ width: width, color: "blue", fontSize: 8 }}
            value={value}
            id="dropDownInput"
            label={placeholder}
            variant="outlined"
            error={error ? true : false}
            helperText={error}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ArrowDropDownCircleOutlinedIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <ul
          className={`${
            arrowDown ? "" : "drop-active"
          } drop-list drop-list-max`}
          style={dropDwonStyle}
        >
          {items.map((item, index) => (
            <li key={index.toString()}>
              <button
                type="button"
                onClick={(): void => handleItemClick(item)}
                className="list-item"
              >
                {item.name}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </ClickOutHandler>
  );
};

DropDownInput.defaultProps = {
  onChange: (): void => {
    //
  },
  error: "",
  icon: "",
  iconColor: "",
  inputClass: "",
  inputDisable: false,
  width: 400,
};

export default DropDownInput;
