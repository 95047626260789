import { ResponseType } from "../../utils/types";
import request, { Methods } from "../../utils/api";
import { apiEndPoints } from "../../utils/appConstants";
import { LoginRequestType, LoginResponseType } from "./../types/types";

const callLoginApi = (
  payload: LoginRequestType
): Promise<ResponseType<LoginResponseType>> =>
  request<LoginResponseType>({
    url: apiEndPoints.login,
    method: Methods.POST,
    data: payload,
  });

const LoginService = {
  callLoginApi,
};

export default LoginService;
