import React from "react";
import "./index.scss";

interface AmountCardProps {
  className: string;
  name: string;
  value: string;
}

const AmountCard = (props: AmountCardProps): JSX.Element => {
  const { name, value, className } = props;
  return (
    <div className={`amount-card-container ${className}`}>
      <div className="name-container">{name}</div>
      <div className="value-container">
        {!isNaN(Number(value)) ? Number(value).toLocaleString("en-IN") : value}
      </div>
    </div>
  );
};

export default AmountCard;
