import { ResponseType, GenericSuccessResponseType } from "../../utils/types";
import request, { Methods } from "../../utils/api";
import { apiEndPoints } from "../../utils/appConstants";
import {
  AddNozzleReadingsRequestType,
  AddSettlementsRequestType,
  DashboardDataRequestType,
  DashboardDataResponseType,
} from "./types";

const loadDashboardData = (
  payload: DashboardDataRequestType
): Promise<ResponseType<DashboardDataResponseType>> =>
  request<DashboardDataResponseType>({
    url: apiEndPoints.loadDashboard,
    method: Methods.POST,
    data: payload,
  });

const addNozzleReadings = (
  payload: AddNozzleReadingsRequestType,
  type: string
): Promise<ResponseType<null>> =>
  request<null>({
    url:
      type === "starting"
        ? apiEndPoints.addStartingReadings
        : apiEndPoints.addClosingReadings,
    method: Methods.POST,
    data: payload,
  });

const addSettlements = (
  payload: AddSettlementsRequestType
): Promise<ResponseType<GenericSuccessResponseType>> =>
  request<GenericSuccessResponseType>({
    url: apiEndPoints.addSettlements,
    method: Methods.POST,
    data: payload,
  });

const downloadClosingReport = (
  payload: DashboardDataRequestType
): Promise<ResponseType<Blob>> =>
  request<Blob>({
    url: apiEndPoints.downloadClosingReport,
    method: Methods.POST,
    data: payload,
    responseType: 'blob'
    // headers: {
    //   "Accept": "application/pdf"
    // }
  });

const DashboardService = {
  loadDashboardData,
  addNozzleReadings,
  addSettlements,
  downloadClosingReport,
};

export default DashboardService;
