/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AssessmentIcon from "@mui/icons-material/Assessment";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import EngineeringIcon from "@mui/icons-material/Engineering";
import DashboardCustomizeRoundedIcon from "@mui/icons-material/DashboardCustomizeRounded";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import AddCardOutlinedIcon from "@mui/icons-material/AddCardOutlined";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Popover } from "@mui/material";
import * as LocalStorageHelper from "../../utils/localStorageHelper";
import ElderlyWomanIcon from "@mui/icons-material/ElderlyWoman";

interface Props {
  content: JSX.Element;
}

const drawerWidth = 180;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  backgroundColor: "#1976d2",
  color: "white",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
  backgroundColor: "#1976d2",
  color: "white",
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Layout: React.FC<Props> = ({ content }: Props) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [isNew, setIsNew] = useState(true);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClose = (): void => setAnchorEl(null);
  const openPop = Boolean(anchorEl);

  const id = openPop ? "simple-popover" : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleSwitchVersions = (): void => {
    if (isNew) {
      LocalStorageHelper.setItem("selectedVersion", "old");
      setIsNew(false);
    } else {
      LocalStorageHelper.setItem("selectedVersion", "new");
      setIsNew(true);
    }
  };

  useEffect(() => {
    LocalStorageHelper.setItem("selectedVersion", "new");
  }, []);

  const logoutHandler = (): void => {
    LocalStorageHelper.clear();
    navigate("/");
  };

  return (
    <div className="layout-container is-flex" key={isNew ? "new" : "old"}>
      <div className="">
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              {isNew ? "Your Money" : "Your Money - Old"}
            </Typography>
            <IconButton
              sx={{
                color: "#f58c49",
                fontWeight: 600,
                marginLeft: 1,
              }}
              onClick={handleSwitchVersions}
            >
              <CurrencyExchangeIcon />
            </IconButton>
            <IconButton
              sx={{
                color: "#f58c49",
                fontWeight: 600,
                marginLeft: 1,
              }}
              onClick={handleClick}
            >
              <AccountCircleIcon />
            </IconButton>
            <Popover
              id={id}
              open={openPop}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Button sx={{ p: 2 }} onClick={(): void => logoutHandler()}>
                Logout
              </Button>
            </Popover>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon htmlColor="#ffffff" />
              ) : (
                <ChevronLeftIcon htmlColor="#ffffff" />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />

          <Divider />
          <List>
            <ListItem
              button
              key="dashboard-menu"
              onClick={(): void => {
                navigate("/dashboard");
              }}
            >
              <ListItemIcon>
                <DashboardCustomizeRoundedIcon
                  htmlColor="#ffffff"
                  sx={{ width: 30 }}
                />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
            <ListItem
              button
              key="customers-menu"
              onClick={(): void => {
                navigate("/customers");
              }}
            >
              <ListItemIcon>
                <EngineeringIcon htmlColor="#ffffff" />
              </ListItemIcon>
              <ListItemText primary="Customers" />
            </ListItem>
            <ListItem
              button
              key="add-sales-menu"
              onClick={(): void => {
                navigate("/saveRecords");
              }}
            >
              <ListItemIcon>
                <AddCardOutlinedIcon htmlColor="#ffffff" />
              </ListItemIcon>
              <ListItemText primary="Add Sales" />
            </ListItem>
            <ListItem
              button
              key="sales-menu"
              onClick={(): void => {
                navigate("/salesList");
              }}
            >
              <ListItemIcon>
                <ListAltOutlinedIcon htmlColor="#ffffff" />
              </ListItemIcon>
              <ListItemText primary="Sales" />
            </ListItem>
            <ListItem
              button
              key="employee-menu"
              onClick={(): void => {
                navigate("/employees");
              }}
            >
              <ListItemIcon>
                <ElderlyWomanIcon htmlColor="#ffffff" />
              </ListItemIcon>
              <ListItemText primary="Employees" />
            </ListItem>
            <ListItem
              button
              key="employee-menu"
              onClick={(): void => {
                navigate("/reports");
              }}
            >
              <ListItemIcon>
                <AssessmentIcon htmlColor="#ffffff" />
              </ListItemIcon>
              <ListItemText primary="Employees" />
            </ListItem>
          </List>
        </Drawer>
      </div>
      <div className="layout-main-content">
        <DrawerHeader />
        {content}
      </div>
    </div>
  );
};

export default Layout;
