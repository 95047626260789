import React, { useState } from "react";
import { AccountStatementEntryType } from "../types";
import "./index.scss";
import Modal from "../../../components/Modal";
import ClearIcon from "@mui/icons-material/Clear";
import { useEffect } from "react";
import Table, { ColumnProps } from "../../../components/Table";
import { IconButton } from "@mui/material";

interface Props {
  customerName: string;
  entries: Array<AccountStatementEntryType>;
  closeModal: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const AccountStatementTable = (props: Props): JSX.Element => {
  const { entries } = props;
  const [tableData, setTableData] = useState<Array<any>>([]);

  const columnData: ColumnProps[] = [
    {
      key: "slno",
      title: "Sl.No",
      alignment: "left",
    },
    {
      key: "date",
      title: "Date",
      alignment: "center",
    },
    {
      key: "type",
      title: "Type",
      alignment: "center",
    },
    {
      key: "description",
      title: "Description",
      alignment: "center",
    },
    {
      key: "purchase",
      title: "Purchase",
      alignment: "right",
    },
    {
      key: "payment",
      title: "Payment",
      alignment: "right",
    },
    {
      key: "balance",
      title: "Balance",
      alignment: "right",
    },
  ];

  useEffect(() => {
    if (entries) {
      setTableData(
        entries.map((item, index) => ({
          slno: index + 1,
          date: item.date,
          type: item.type,
          description: item.description,
          purchase:
            item.type === "Purchase"
              ? Number(Number(item.amount).toFixed(2)).toLocaleString("en-IN")
              : "",
          payment:
            item.type === "Payment"
              ? Number(Number(item.amount).toFixed(2)).toLocaleString("en-IN")
              : "",
          balance: Number(Number(item.balance).toFixed(2)).toLocaleString(
            "en-IN"
          ),
        }))
      );
    }
  }, [entries]);

  return (
    <div className="account-statement-table-container">
      <Table
        columnData={columnData}
        dataSource={tableData}
        style={{ height: "calc(100vh - 200px)" }}
      />
    </div>
  );
};

const AccountStatementModal = (props: Props): JSX.Element => {
  const [show, setShow] = useState(true);
  const { closeModal, customerName } = props;
  return (
    <>
      <Modal
        show={show}
        closeModal={(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
          setShow(false);
          // closeModal(e);
        }}
        content={
          <div className="statement-modal-container">
            <div className="statement-header is-flex is-align-items-center is-justify-content-space-between">
              {`Account Statement for ${customerName}`}
              <IconButton onClick={(e): void => closeModal(e)}>
                <ClearIcon color="error" />
              </IconButton>
            </div>
            <div className="statement-table-container">
              <AccountStatementTable {...props} />
            </div>
          </div>
        }
        persistent
      />
    </>
  );
};

export default AccountStatementModal;
