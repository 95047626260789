/* eslint-disable react-hooks/exhaustive-deps */
import {
  Snackbar,
  Alert,
  AlertTitle,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import { isToday } from "date-fns";
import React, { useEffect, useState } from "react";
import Modal from "../../../components/Modal";
import { isNotEmptyObj, stripZerosAndAlpha } from "../../../utils/helper";
import ClearIcon from "@mui/icons-material/Clear";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DashboardState from "../Store";

interface Props {
  closeModal: () => void;
  date: Date;
}

const AddSettlementsContent = (props: Props): JSX.Element => {
  const { date, closeModal } = props;
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const [cardAmount, setCardAmount] = useState("");
  const [upiAmount, setUpiAmount] = useState("");

  const {
    addSettlements,
    addSettlementsError,
    addSettlementsSuccess,
    clearAddSettlementsResponses,
  } = DashboardState();

  const handleChange = (
    type: string,
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    let input = event.target.value;
    input = stripZerosAndAlpha(input);
    if (type === "card") {
      setCardAmount(input);
    } else {
      setUpiAmount(input);
    }
  };

  const isSubmissible = (): boolean => {
    if (cardAmount !== "") return true;
    return false;
  };

  const saveSettlements = (): void => {
    addSettlements({
      date: date.toLocaleDateString(),
      cardAmount: cardAmount,
      upiAmount: upiAmount,
    });
  };

  useEffect(() => {
    if (isNotEmptyObj(addSettlementsSuccess)) {
      setShowSuccess(true);
      clearAddSettlementsResponses();
      setTimeout(() => {
        closeModal();
      }, 2000);
    }
    if (isNotEmptyObj(addSettlementsError)) {
      setShowError(true);
      clearAddSettlementsResponses();
    }
  }, [addSettlementsError, addSettlementsSuccess]);

  return (
    <>
      <div className="add-readings-modal">
        {showSuccess && (
          <Snackbar
            open={showSuccess}
            autoHideDuration={3000}
            onClose={(): void => setShowSuccess(false)}
            message="Note archived"
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert
              severity="success"
              onClose={(): void => setShowSuccess(false)}
              className="sucess-alert"
            >
              <AlertTitle>Success</AlertTitle>
              Successfully added settlements
            </Alert>
          </Snackbar>
        )}
        {showError && (
          <Snackbar
            open={showError}
            autoHideDuration={4000}
            onClose={(): void => setShowError(false)}
            message="Note archived"
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert
              severity="error"
              onClose={(): void => setShowError(false)}
              className="sucess-alert"
            >
              <AlertTitle>Error</AlertTitle>
              Couldn't add settlements. Please try again.
            </Alert>
          </Snackbar>
        )}
        <div className="add-readings-container">
          <div className="header-container is-flex is-justify-content-space-between is-align-items-center">
            <div className="credit-sale-header">{`Add Settlements for ${
              !isToday(date) ? date.toLocaleDateString() : "Today"
            }`}</div>
            <IconButton onClick={(): void => closeModal()}>
              <ClearIcon color="error" />
            </IconButton>
          </div>
          <div className="add-readings-form">
            {
              <>
                <div className="columns">
                  <div className="column">
                    <TextField
                      id="card-amount"
                      label="Card Amount"
                      variant="outlined"
                      value={cardAmount}
                      sx={{ width: 250 }}
                      onChange={(
                        e: React.ChangeEvent<HTMLInputElement>
                      ): void => {
                        handleChange("card", e);
                      }}
                    />
                  </div>
                  <div className="column">
                    <TextField
                      id="upi-amount"
                      label="UPI Amount"
                      variant="outlined"
                      value={upiAmount}
                      sx={{ width: 250 }}
                      onChange={(
                        e: React.ChangeEvent<HTMLInputElement>
                      ): void => {
                        handleChange("upi", e);
                      }}
                    />
                  </div>
                </div>
              </>
            }
          </div>
          <div className="save-readings-container is-flex is-justify-content-flex-end">
            <div className="reset-button">
              <Button
                variant="contained"
                onClick={(): void => {}}
                startIcon={<RestartAltIcon />}
              >
                Reset
              </Button>
            </div>
            <div>
              <Button
                variant="contained"
                onClick={(): void => {
                  saveSettlements();
                }}
                disabled={!isSubmissible()}
                color="success"
              >
                Save Readings
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const AddSettlements = (props: Props): JSX.Element => {
  const [show, setShow] = useState(true);
  const { closeModal } = props;

  return (
    <Modal
      show={show}
      closeModal={(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        setShow(false);
        closeModal();
      }}
      content={<AddSettlementsContent {...props} />}
      persistent
    />
  );
};

export default AddSettlements;
