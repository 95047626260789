/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./index.scss";
import { EmployeeAttendanceDetailsType, EmployeeDetailsProps } from "./types";
import { ReactComponent as EmployeeIcon } from "../../images/employee-icon.svg";
import { ReactComponent as DownArrow } from "../../images/arrow-down.svg";
import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Table,
} from "@mui/material";
import { isNotEmptyObj } from "../../utils/helper";
import EmployeeStore from "./Store";

const EmployeeDetailsCard = (props: EmployeeDetailsProps): JSX.Element => {
  const {
    employeeId,
    employeeName,
    month,
    year,
    monthName,
    totalHours,
    totalAmount,
    totalDiff,
    contactNumber,
    totalAdvancePaid,
    balance,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const {
    employeeAttendaceRecords,
    getEmployeeRecords,
    setEmployeeAttendanceRecord,
    getEmployeeRecordsSuccess,
    clearGetEmployeeRecordsResponses,
  } = EmployeeStore();

  const [attendanceRecords, setAttendanceRecords] =
    useState<EmployeeAttendanceDetailsType>(
      {} as EmployeeAttendanceDetailsType
    );

  const openAccordian = (): void => {
    setIsOpen(!isOpen);
    getEmployeeRecords({
      month: month,
      employeeId: employeeId,
    });
    // if (!isNotEmptyObj(attendanceRecords)) {
    //   const attendaceRecord = employeeAttendaceRecords.find(
    //     (item) => item.employeeId === employeeId
    //   );
    //   if (attendaceRecord) setAttendanceRecords(attendaceRecord);
    //   else {
    //     getEmployeeRecords({
    //       month: month,
    //       employeeId: employeeId,
    //     });
    //   }
    // }
  };

  useEffect(() => {
    if (
      isNotEmptyObj(getEmployeeRecordsSuccess) &&
      getEmployeeRecordsSuccess?.employeeId === employeeId
    ) {
      setEmployeeAttendanceRecord([
        ...employeeAttendaceRecords,
        getEmployeeRecordsSuccess,
      ]);
      setAttendanceRecords(getEmployeeRecordsSuccess);
      clearGetEmployeeRecordsResponses();
    }
  }, [getEmployeeRecordsSuccess]);

  return (
    <div className="employee-details-card">
      <div className="details-header-container columns">
        <div className="name-container column p-0">
          <div className="employee-icon-container ">
            <EmployeeIcon className="employee-icon" />
          </div>
          <div className="text-bold employee-name">{employeeName}</div>
        </div>
        <div className="total-amount-container column">
          <div className="heading-4 balance-container-header">
            <p>Balance: </p>
          </div>
          <div className="heading-2 balance-container">
            {Number(balance).toLocaleString("en-IN")}
          </div>
        </div>
      </div>
      <div className="columns attendance-details-container is-multiline">
        <div className="column">
          <div className="attendance-details-header">
            <p className="heading-5 text-bold attendance-details">
              Total Hours Worked:
            </p>
            <p className="heading-5">{totalHours}</p>
          </div>
          <div className="attendance-details-header">
            <p className="heading-5 text-bold attendance-details">
              Total Amount:
            </p>
            <p className="heading-5">{totalAmount}</p>
          </div>
        </div>
        <div className="column">
          <div className="attendance-details-header-right">
            <div className="attendance-content">
              <p className="heading-5 text-bold attendance-details">
                Total Salary Advance:
              </p>
              <p className="heading-5">{totalAdvancePaid}</p>
            </div>
          </div>
          <div className="attendance-details-header-right">
            <div className="attendance-content">
              <p className="heading-5 text-bold attendance-details">
                Total Difference In Closing:
              </p>
              <p className="heading-5">{totalDiff}</p>
            </div>
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="employee-records-container">
          <div className="columns is-multiline">
            <div className="column">
              <div className="heading-4">Attendance Details</div>
              {isNotEmptyObj(attendanceRecords) && (
                <div className="attendance-table">
                  <TableContainer>
                    <Table
                      sx={{ minWidth: 600, fontFamily: "Roboto" }}
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell align="right">Date</TableCell>
                          <TableCell align="right">Start Hour</TableCell>
                          <TableCell align="right">End Hour</TableCell>
                          <TableCell align="right">Work Hours</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {attendanceRecords.attendanceRecords.map(
                          (row, index) => (
                            <TableRow
                              key={`${row.date} ${employeeId}`}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                align="right"
                              >
                                {row.date}
                              </TableCell>
                              <TableCell align="right">
                                {row.startHour}
                              </TableCell>
                              <TableCell align="right">{row.endHour}</TableCell>
                              <TableCell align="right">
                                {row.totalWorkHours}
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
            </div>
            <div className="column">
              <div className="heading-4">Salary Advances</div>
              {isNotEmptyObj(attendanceRecords) && (
                <div className="attendance-table">
                  <TableContainer>
                    <Table
                      sx={{ minWidth: 600, fontFamily: "Roboto" }}
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell align="right">Sl No.</TableCell>
                          <TableCell align="right">Date</TableCell>
                          <TableCell align="right">Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {attendanceRecords.salaryRecords.map((row, index) => (
                          <TableRow
                            key={`${row.date} ${employeeId}`}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell component="th" scope="row" align="right">
                              {index + 1}
                            </TableCell>
                            <TableCell align="right">{row.date}</TableCell>
                            <TableCell align="right">{row.amount}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="card-accordian-arrow-container" onClick={openAccordian}>
        <div className="arrow-container">
          <div className={`down-arrow-icon ${isOpen ? "rotate-arrow" : ""}`}>
            <DownArrow className={`${isOpen ? "rotate-arrow" : ""}`} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeDetailsCard;
