import { ResponseType, GenericSuccessResponseType } from "../../utils/types";
import request, { Methods } from "../../utils/api";
import { apiEndPoints } from "../../utils/appConstants";
import {
  AddAttendanceRequestType,
  GetAttendanceSalaryRecordsRequestType,
  GetAttendanceSalaryRecordsResponseType,
  GetEmployeeAttendanceRequestType,
  GetEmployeeAttendanceResponseType,
} from "./types";

const getEmployeeAttendances = (
  payload: GetEmployeeAttendanceRequestType
): Promise<ResponseType<GetEmployeeAttendanceResponseType>> =>
  request<GetEmployeeAttendanceResponseType>({
    url: apiEndPoints.getEmployeeDetails,
    method: Methods.POST,
    data: payload,
  });

const getEmployeeRecords = (
  payload: GetAttendanceSalaryRecordsRequestType
): Promise<ResponseType<GetAttendanceSalaryRecordsResponseType>> =>
  request<GetAttendanceSalaryRecordsResponseType>({
    url: apiEndPoints.getEmployeeRecords,
    method: Methods.POST,
    data: payload,
  });

const addAttendance = (
  payload: AddAttendanceRequestType
): Promise<ResponseType<GenericSuccessResponseType>> =>
  request<GenericSuccessResponseType>({
    url: apiEndPoints.addAttendance,
    method: Methods.POST,
    data: payload,
  });

const EmployeeService = {
  getEmployeeAttendances,
  getEmployeeRecords,
  addAttendance,
};

export default EmployeeService;
