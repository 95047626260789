import {
  CreateCustomerRequestType,
  CreateCustomerResponseType,
  CreateDailySaleRecordRequestType,
  CreateDailySaleRecordResponseType,
  FetchDailySalesRecordRequestType,
  FetchTodaysPriceResponseType,
  ProductListResponseType,
  ProductType,
  SavePriceReponseType,
  SavePriceRequestType,
} from "./../types/types";
import { useState } from "react";
import { createStore } from "reusable";
import {
  AddCreditCustomerType,
  CreditSalesType,
  GetCustomersResponseType,
} from "../types/types";
import { ResponseType } from "../../utils/types";

import AddSalesDetailsService from "./Service";

const AddSalesState = createStore(() => {
  const [creditSalesList, setCreditSalesList] = useState<CreditSalesType[]>([]);

  const [customersList, setCustomersList] = useState<AddCreditCustomerType[]>(
    []
  );
  const [productList, setProductList] = useState<ProductType[]>([]);

  const [productListResponseError, setProductListResponseError] =
    useState<ResponseType<ProductListResponseType>>();

  const clearProductListResponse = (): void => {
    setProductList({} as ProductType[]);
    setProductListResponseError({} as ResponseType<ProductListResponseType>);
  };

  const clearCreditSalesList = (): void => {
    setCreditSalesList([] as CreditSalesType[]);
  };

  const [customersListError, setCustomersListError] = useState<
    ResponseType<GetCustomersResponseType>
  >({} as ResponseType<GetCustomersResponseType>);

  const [createCustomerResponseData, setCreateCustomerResponseData] =
    useState<CreateCustomerResponseType>();

  const [createCustomerErrorResponseData, setCreateCustomerErrorResponseData] =
    useState<ResponseType<CreateCustomerResponseType>>();

  const [createDailySalesRecordRespData, setCreateDailySalesRecordRespData] =
    useState<CreateDailySaleRecordResponseType>();
  const [createDailySalesRecordRespError, setCreateDailySalesRecordRespError] =
    useState<ResponseType<CreateDailySaleRecordResponseType>>();

  const [fetchPriceResponse, setFetchPriceReponse] =
    useState<FetchTodaysPriceResponseType>();

  const [fetchPriceResponseError, setFetchPriceResponseError] =
    useState<ResponseType<FetchTodaysPriceResponseType>>();

  const clearFetchPriceResponse = (): void => {
    setFetchPriceReponse({} as FetchTodaysPriceResponseType)
    setFetchPriceResponseError({} as ResponseType<FetchTodaysPriceResponseType>)
  }

  const [savePriceResponse, setSavePriceResponse] =
    useState<SavePriceReponseType>();

  const [savePriceResponseError, setSavePriceResponseError] =
    useState<ResponseType<SavePriceReponseType>>();

  const clearSavePriceResponses = (): void => {
    setSavePriceResponse({} as SavePriceReponseType);
    setSavePriceResponseError({} as ResponseType<SavePriceReponseType>);
  };

  const [deleteSalesRecord, setDeleteSalesRecord] =
    useState<ResponseType<null>>();

  const [deleteSalesRecordError, setDeleteSalesRecordError] =
    useState<ResponseType<null>>();

  const clearDeleteSalesRecordResponses = (): void => {
    setDeleteSalesRecord({} as ResponseType<null>);
    setDeleteSalesRecordError({} as ResponseType<null>);
  };

  const clearsetCreateDailySalesRecordResp = (): void => {
    setCreateDailySalesRecordRespData({} as CreateDailySaleRecordResponseType);
    setCreateDailySalesRecordRespError(
      {} as ResponseType<CreateDailySaleRecordResponseType>
    );
  };

  const clearCustomersList = (): void => {
    setCustomersList([]);
    setCustomersListError({} as ResponseType<GetCustomersResponseType>);
  };

  const getProductList = (date: Date): void => {
    const payload = {
      date: date.toLocaleDateString(),
    };
    AddSalesDetailsService.getProductList(payload)
      .then((response) => {
        setProductList(response.data.productList);
      })
      .catch((error) => {
        setProductListResponseError(error);
      });
  };

  const getAllCustomer = (): void => {
    const payload = {
      requestId: "1231231231",
      data: {},
    };
    AddSalesDetailsService.getCustomersList(payload)
      .then((response) => {
        setCustomersList(response.data.list);
      })
      .catch((error) => {
        setCustomersListError(error);
      });
  };

  const callCreateCustomer = (payload: CreateCustomerRequestType): void => {
    AddSalesDetailsService.callCreateCustomer(payload)
      .then((response) => {
        setCreateCustomerResponseData(response.data);
      })
      .catch((error) => {
        setCreateCustomerErrorResponseData(error);
      });
  };

  const callCreateDailySalesRecord = (
    payload: CreateDailySaleRecordRequestType
  ): void => {
    AddSalesDetailsService.callCreateDailySales(payload)
      .then((response) => {
        setCreateDailySalesRecordRespData(response.data);
      })
      .catch((error) => {
        setCreateDailySalesRecordRespError(error.data);
      });
  };

  const [fetchDailySalesResponse, setFetchDailySalesResponse] =
    useState<CreateDailySaleRecordRequestType>();

  const [fetchDailySalesRespError, setFetchDailySalesRespError] =
    useState<ResponseType<CreateDailySaleRecordRequestType>>();

  const clearDailySalesResponse = (): void => {
    setFetchDailySalesResponse({} as CreateDailySaleRecordRequestType);
    setFetchDailySalesRespError(
      {} as ResponseType<CreateDailySaleRecordRequestType>
    );
  };

  const fetchDailySalesRecord = (
    payload: FetchDailySalesRecordRequestType
  ): void => {
    AddSalesDetailsService.fetchDailySalesRecord(payload)
      .then((response) => {
        setFetchDailySalesResponse(response.data);
      })
      .catch((error) => {
        setFetchDailySalesRespError(error);
      });
  };

  const fetchTodaysPrice = (): void => {
    const today = new Date();
    const payload = {
      date: today.toLocaleDateString(),
    };
    AddSalesDetailsService.fetchTodaysPrice(payload)
      .then((response) => {
        setFetchPriceReponse(response.data);
      })
      .catch((error) => {
        setFetchPriceResponseError(error);
      });
  };

  const saveTodaysPrice = (payload: SavePriceRequestType): void => {
    AddSalesDetailsService.saveTodaysPrice(payload)
      .then((response) => {
        setSavePriceResponse(response.data);
      })
      .catch((error) => {
        setSavePriceResponseError(error);
      });
  };

  const deleteDailySalesRecord = (payload: { sheetId: string }): void => {
    AddSalesDetailsService.deleteDailySalesRecord(payload)
      .then((response) => {
        setDeleteSalesRecord(response);
      })
      .catch((error) => {
        setDeleteSalesRecordError(error);
      });
  };

  return {
    creditSalesList,
    clearCreditSalesList,
    customersList,
    getAllCustomer,
    getProductList,
    customersListError,
    clearCustomersList,
    callCreateCustomer,
    productList,
    productListResponseError,
    clearProductListResponse,
    setCreditSalesList,
    clearsetCreateDailySalesRecordResp,
    createDailySalesRecordRespData,
    createDailySalesRecordRespError,
    createCustomerResponseData,
    createCustomerErrorResponseData,
    callCreateDailySalesRecord,
    fetchDailySalesResponse,
    fetchDailySalesRespError,
    clearDailySalesResponse,
    fetchDailySalesRecord,
    fetchPriceResponse,
    fetchPriceResponseError,
    fetchTodaysPrice,
    saveTodaysPrice,
    savePriceResponse,
    savePriceResponseError,
    clearSavePriceResponses,
    deleteSalesRecord,
    deleteSalesRecordError,
    clearDeleteSalesRecordResponses,
    deleteDailySalesRecord,
    clearFetchPriceResponse
  };
});

export default AddSalesState;
