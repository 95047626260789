import React, { Fragment } from "react";
import "./index.scss";
import {
  TableContainer,
  Table as MuiTable,
  TableHead,
  TableRow,
  TableBody,
  TablePagination,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import TableCell, { tableCellClasses } from "@mui/material/TableCell";

export interface TableProps {
  columnData: Array<ColumnProps>;
  dataSource: Array<any>;
  isPaginated?: boolean;
  pagination?: PaginationProps;
  rowClick?: (index: number) => void;
  isHeaderLess?: boolean;
  style: any;
}

export interface ColumnProps {
  key: string;
  title: string;
  colSpan?: number;
  rowSpan?: number;
  width?: number;
  action?: { handleClick: (i: any) => void; isClickable: boolean };
  //   render?: {
  //     renderElement: <T>(i: any, key: any, index: number) => void;
  //     isRender: boolean;
  //   } | null;
  alignment: "center" | "justify" | "left" | "right";
}

export interface PaginationProps {
  pageNumber: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => void;
  forcePage?: number | undefined;
  entriesPerPage: number;
  totalCount: number;
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#3981DE",
    color: theme.palette.common.white,
    fontWeight: "800",
    fontSize: "15px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Table: React.FunctionComponent<TableProps> = (props: TableProps) => {
  const {
    columnData,
    dataSource,
    rowClick,
    isPaginated,
    pagination,
    isHeaderLess,
    style,
  } = props;

  const renderRow = (item: any, index: number) => {
    return columnData.map((col, i) => {
      return (
        <StyledTableCell
          key={`${item[col.key]}+${i}`}
          onClick={(): void => {}}
          style={{ textAlign: col.alignment }}
          sx={{ width: col.width }}
        >
          {item[col.key]}
        </StyledTableCell>
      );
    });
  };

  return (
    <div className="custom-table-container">
      <TableContainer className="table-comp" style={style}>
        <MuiTable
          sx={{ fontFamily: "Roboto", borderRadius: 3 }}
          aria-label="simple table"
          stickyHeader
        >
          {!isHeaderLess && (
            <TableHead className="table-head">
              <TableRow>
                {columnData &&
                  columnData.map((item, index) => (
                    <StyledTableCell
                      style={{ textAlign: item.alignment }}
                      colSpan={item.colSpan}
                      rowSpan={item.rowSpan}
                    >
                      {item.title}
                    </StyledTableCell>
                  ))}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {!!dataSource &&
              dataSource?.length > 0 &&
              dataSource.map((item, index) => (
                <Fragment key={index.toString()}>
                  <StyledTableRow
                    key={`${index}+keyys`}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                    className={`${rowClick ? "clickable-table-row" : ""}`}
                    onClick={(): void => {
                      if (rowClick) rowClick(index);
                    }}
                  >
                    {renderRow(item, index)}
                  </StyledTableRow>
                </Fragment>
              ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
      {isPaginated && pagination && (
        <TablePagination
          count={pagination.totalCount}
          rowsPerPageOptions={[20, 30]}
          component="div"
          rowsPerPage={pagination.entriesPerPage}
          page={pagination.pageNumber}
          onPageChange={pagination.onPageChange}
          onRowsPerPageChange={pagination.onRowsPerPageChange}
        />
      )}
    </div>
  );
};

export default Table;
