import { useState } from "react";
import { createStore } from "reusable";
import { ResponseType } from "../../../utils/types";
import {
  AddExpenseRequestData,
  ExpensesRequestData,
  ExpensesResponseData,
} from "./types";
import ExpenseService from "./Service";

const ExpenseStore = createStore(() => {
  const [getExpensesReportSuccess, setGetExpensesReportSuccess] =
    useState<ExpensesResponseData>();

  const [getExpensesReportError, setGetExpensesReportError] =
    useState<ResponseType<null>>();

  const clearGetExpensesReportResponses = (): void => {
    setGetExpensesReportSuccess({} as ExpensesResponseData);
    setGetExpensesReportError({} as ResponseType<null>);
  };

  const [addExpensesResponse, setAddExpensesResponse] =
    useState<ResponseType<null>>();
  const [addExpensesResponseError, setAddExpensesResponseError] =
    useState<ResponseType<null>>();

  const clearAddExpensesResponse = (): void => {
    setAddExpensesResponse({} as ResponseType<null>);
    setAddExpensesResponseError({} as ResponseType<null>);
  };

  const getExpensesReport = (payload: ExpensesRequestData): void => {
    ExpenseService.getExpenses(payload)
      .then((response) => {
        setGetExpensesReportSuccess(response.data);
      })
      .catch((error) => {
        setGetExpensesReportError(error);
      });
  };

  const addExpenses = (payload: AddExpenseRequestData): void => {
    ExpenseService.addExpenses(payload)
      .then((response) => {
        setAddExpensesResponse(response);
      })
      .catch((error) => {
        setAddExpensesResponseError(error);
      });
  };

  return {
    getExpensesReportSuccess,
    getExpensesReportError,
    clearGetExpensesReportResponses,
    getExpensesReport,
    addExpenses,
    addExpensesResponse,
    addExpensesResponseError,
    clearAddExpensesResponse,
  };
});

export default ExpenseStore;
