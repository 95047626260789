import { ResponseType } from "../../../utils/types";
import request, { Methods } from "../../../utils/api";
import { apiEndPoints } from "../../../utils/appConstants";
import { ProfitLossRequestData, ProfitLossResponseData } from "./types";

const getProfitLoss = (
  payload: ProfitLossRequestData
): Promise<ResponseType<ProfitLossResponseData>> =>
  request<ProfitLossResponseData>({
    url: apiEndPoints.profitLoss,
    method: Methods.POST,
    data: payload,
  });

const ProfitLossService = {
  getProfitLoss,
};

export default ProfitLossService;
