import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layout";
import "./index.scss";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import ExpenseStore from "./Store";
import { ExpensesResponseData } from "./types";
import { isNotEmptyObj } from "../../../utils/helper";
import ExpenseTotals from "../../../components/ExpensesCard";
import PaidIcon from "@mui/icons-material/Paid";
import Table, { ColumnProps } from "../../../components/Table";
import { Button, TextField } from "@mui/material";
import AddExpensesModal from "./AddExpenseModal";

interface ExpenseTableData {
  date: string;
  description: string;
  expenseType: string;
  amount: string;
}

enum ExpenseDisplayTypes {
  TotalExpense = "Total Expense",
  SalaryExpense = "Salary Expense",
  OwnerExpense = "Owner Expense",
  PurchaseExpense = "Purchase Expenses",
}

const ExpensesContent = (): JSX.Element => {
  const today = new Date();
  const [showAddExpenses, setShowAddExpenses] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState<Date>(
    new Date(today.getFullYear(), today.getMonth(), 1)
  );
  const [expensesData, setExpensesData] =
    useState<ExpensesResponseData | null>();
  const [tableData, setTableData] = useState<Array<ExpenseTableData>>([]);

  const { getExpensesReport, getExpensesReportSuccess } = ExpenseStore();
  const handleDateChange = (newValue: Date | null) => {
    if (newValue != null) {
      setSelectedMonth(newValue);
      getExpensesReport({
        month: newValue.toLocaleDateString("en-IN"),
      });
    }
  };
  useEffect(() => {
    getExpensesReport({
      month: selectedMonth.toLocaleDateString("en-IN"),
    });
  }, []);
  useEffect(() => {
    if (isNotEmptyObj(getExpensesReportSuccess?.expenses)) {
      setExpensesData(getExpensesReportSuccess || null);
      setTableData(
        getExpensesReportSuccess?.expenses.map((item) => ({
          date: item.date,
          description: item.description,
          expenseType: item.expenseType,
          amount: !isNaN(Number(item.amount))
            ? Number(item.amount).toLocaleString("en-IN")
            : item.amount,
        })) || []
      );
    }
  }, [getExpensesReportSuccess]);

  const columnData: ColumnProps[] = [
    {
      key: "date",
      title: "Date",
      alignment: "left",
    },
    {
      key: "description",
      title: "Discription",
      alignment: "left",
    },
    {
      key: "expenseType",
      title: "Expense Type",
      alignment: "left",
    },
    {
      key: "amount",
      title: "Amount",
      alignment: "right",
    },
  ];
  return (
    <>
      {showAddExpenses && (
        <AddExpensesModal
          closeModal={(): void => {
            setShowAddExpenses(false);
          }}
        />
      )}
      <div className="expenses-page-container">
        <div className="expenses-page-headers">
          <div className="heading-3 text-bold">{`Expense Report of ${selectedMonth.toLocaleString(
            "default",
            {
              month: "long",
              year: "numeric",
            }
          )}`}</div>

          <div className="expense-selector">
            <div className="add-expense-button">
              <Button
                variant="contained"
                onClick={(): void => {
                  setShowAddExpenses(true);
                }}
                color="success"
                startIcon={<PaidIcon />}
              >
                Add Expenses
              </Button>
            </div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                views={["year", "month"]}
                openTo="month"
                value={selectedMonth}
                label="Change Month"
                onChange={handleDateChange}
                renderInput={(params: any) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <ExpenseTotals
              month={selectedMonth.toLocaleString("default", {
                month: "long",
                year: "numeric",
              })}
              totalMonthlyExpenses={expensesData?.totalMonthlyExpenses || ""}
              salaryExpense={expensesData?.salaryExpense || ""}
              ownerExpense={expensesData?.ownerExpense || ""}
              msHsdPurchaseExpense={expensesData?.msHsdPurchaseExpense || ""}
              lubePurchaseExpense={expensesData?.lubePurchaseExpense || ""}
              bankExpenses={expensesData?.bankExpenses || ""}
              miscellaneousExpense={expensesData?.miscellaneousExpense || ""}
              discountExpenses={expensesData?.discountExpenses || ""}
            />
          </div>
          <div className="column expenses-list-container is-three-fifths">
            <Table
              columnData={columnData}
              dataSource={tableData}
              isPaginated
              // pagination={{
              //   pageNumber: pageNumber,
              //   onPageChange: handlePageChange,
              //   entriesPerPage: entriesPerPage,
              //   totalCount: totalCount,
              //   onRowsPerPageChange: handleChangeRowsPerPage,
              // }}
              // rowClick={(index: number): void => {
              //   pushToDetails(index);
              // }}
              style={{ maxHeight: "calc(100vh - 178px)" }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const Expenses: React.FunctionComponent = () => {
  return (
    <div>
      <Layout content={<ExpensesContent />} />
    </div>
  );
};

export default Expenses;
