/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from "@mui/x-date-pickers";
import {
  Button,
  Snackbar,
  Alert,
  AlertTitle,
  IconButton,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import "./index.scss";
import { useState, useEffect } from "react";
import DropDownInput from "../../../components/DropdownInput";
import { paymentModeListForAdmin } from "../../../utils/appConstants";
import { stripZerosAndAlpha, isNotEmptyObj } from "../../../utils/helper";
import CustomerState from "../Store";
import ClearIcon from "@mui/icons-material/Clear";
import Modal from "../../../components/Modal";
import { PaymentsRecievedType } from "../../types/types";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";

interface PaymentModalProps {
  customerId: string;
  customerName: string;
  closeModal: () => void;
}
const AddPaymentContent = (props: PaymentModalProps): JSX.Element => {
  const { closeModal, customerId, customerName } = props;

  const {
    getCustomerDetails,
    addCustomerPayment,
    addCustomerPaymentResponse,
    addCustomerPaymentResponseError,
    clearAddCustomerPaymentResponse,
  } = CustomerState();

  const [payments, setPayments] = useState<PaymentsRecievedType[]>([]);

  const [amount, setAmount] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [date, setDate] = useState<Date>(new Date());

  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const handleAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => setAmount(stripZerosAndAlpha(event.target.value));

  const handleDateChange = (newValue: Date | null) => {
    if (newValue != null) {
      setDate(newValue);
    }
  };

  const handlePaymentTypeSelection = (value: string): void => {
    const paymentmode = paymentModeListForAdmin.find(
      (item) => item.code === value
    );
    if (paymentmode) setPaymentType(paymentmode.name);
  };

  useEffect(() => {
    if (isNotEmptyObj(addCustomerPaymentResponse)) {
      setShowSuccessAlert(true);
      clearAddCustomerPaymentResponse();
      setTimeout(() => {
        closeModal();
      }, 3000);
      const payload = {
        customerId: customerId,
      };
      getCustomerDetails(payload);
    }

    if (isNotEmptyObj(addCustomerPaymentResponseError)) {
      setShowErrorAlert(true);
      clearAddCustomerPaymentResponse();
    }
  }, [addCustomerPaymentResponse, addCustomerPaymentResponseError]);

  const savePaymentMade = (): void => {
    const payload = {
      employeeId: "ADMIN",
      customerId: customerId,
      paymentsReceived: payments,
    };
    addCustomerPayment(payload);
  };

  const addPaymentRecieved = (): void => {
    setPayments([
      ...payments,
      {
        date: date.toLocaleDateString(),
        paymentId: Math.random().toString(36).substring(2, 7),
        customerId: customerId,
        customerName: customerName,
        amount: amount,
        paymentMode: paymentType,
      },
    ]);
    setAmount("");
    setPaymentType("");
    setDate(new Date());
  };

  const deletePaymentRecieved = (id: string): void => {
    const filteredList = payments.filter((item) => item.paymentId !== id);
    setPayments(filteredList);
  };

  return (
    <div className="add-payment-modal">
      {showSuccessAlert && (
        <Snackbar
          open={showSuccessAlert}
          autoHideDuration={4000}
          onClose={(): void => setShowSuccessAlert(false)}
          message="Note archived"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            severity="success"
            onClose={(): void => setShowSuccessAlert(false)}
          >
            <AlertTitle>Success</AlertTitle>
            Successfully added payment
          </Alert>
        </Snackbar>
      )}
      {showErrorAlert && (
        <Snackbar
          open={showErrorAlert}
          autoHideDuration={4000}
          onClose={(): void => setShowErrorAlert(false)}
          message="Note archived"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            severity="error"
            onClose={(): void => setShowErrorAlert(false)}
          >
            <AlertTitle>Failed to add payment</AlertTitle>
            Error while adding payment. Please try again later
          </Alert>
        </Snackbar>
      )}
      <div className="add-payments-container">
        <div className="header-container is-flex is-justify-content-space-between is-align-items-center">
          <div className="add-payment-header">{`Add Payment`}</div>
          <IconButton onClick={(): void => closeModal()}>
            <ClearIcon color="error" />
          </IconButton>
        </div>
        <div className="add-payment-form">
          <div className="customer-name-header">{`${customerName}`}</div>
          <div className="columns">
            <div className="column">
              <LocalizationProvider dateAdapter={AdapterDateFns} disablePortal>
                <div className="column">
                  <DesktopDatePicker
                    className="date-picker-new"
                    value={date}
                    inputFormat="dd/MM/yyyy"
                    onChange={handleDateChange}
                    renderInput={(params: any) => (
                      <TextField {...params} sx={{ width: 190 }} />
                    )}
                  />
                </div>
              </LocalizationProvider>
            </div>
            <div className="column">
              <div className="payment-mode-input">
                <DropDownInput
                  value={paymentType}
                  items={paymentModeListForAdmin}
                  placeholder="Select Mode"
                  name="payment-mode-name"
                  onChange={handlePaymentTypeSelection}
                  width={190}
                />
              </div>
            </div>
            <div className="column">
              <div className="payment-mode-input">
                <TextField
                  id="payment-amount"
                  label="Amount"
                  variant="outlined"
                  value={amount}
                  sx={{ width: 190 }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                    handleAmountChange(e)
                  }
                />
              </div>
            </div>
            <div className="column is-1 is-flex is-align-items-center">
              <div className="payment-add-icon">
                {amount && (
                  <IconButton onClick={addPaymentRecieved}>
                    <AddCircleIcon sx={{ fontSize: 30 }} />
                  </IconButton>
                )}
              </div>
            </div>
          </div>
          <div className="payments-table-container">
            {payments.length !== 0 && (
              <>
                <TableContainer>
                  <Table
                    sx={{ minWidth: 600, fontFamily: "Roboto" }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align="right">Sl.No</TableCell>
                        <TableCell align="right">Date</TableCell>
                        <TableCell align="right">Amount</TableCell>
                        <TableCell align="right">Payment Mode</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {payments.map((row, index) => (
                        <TableRow
                          key={row.paymentId}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell component="th" scope="row" align="right">
                            {index + 1}
                          </TableCell>
                          <TableCell align="right">{row.date}</TableCell>
                          <TableCell align="right">{row.amount}</TableCell>
                          <TableCell align="right">{row.paymentMode}</TableCell>
                          <TableCell align="right">
                            <IconButton
                              onClick={(): void =>
                                deletePaymentRecieved(row.paymentId)
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </div>
        </div>
        <div className="save-sales-container is-flex is-justify-content-flex-end">
          <div>
            <Button
              variant="contained"
              onClick={(): void => {
                savePaymentMade();
              }}
              color="success"
            >
              Add Payment Made
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const AddPaymentModal = (props: PaymentModalProps): JSX.Element => {
  const [show, setShow] = useState(true);
  const { closeModal, customerId, customerName } = props;
  return (
    <>
      <Modal
        show={show}
        closeModal={(): void => {
          setShow(false);
          closeModal();
        }}
        content={
          <AddPaymentContent
            closeModal={closeModal}
            customerId={customerId}
            customerName={customerName}
          />
        }
        persistent
      />
    </>
  );
};

export default AddPaymentModal;
