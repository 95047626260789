import React from "react";
import "./index.scss";
import Layout from "../../components/Layout";
import DashboardContent from "./DashboardContent";
import * as LocalStorageHelper from "../../utils/localStorageHelper";

const Dashboard: React.FunctionComponent = () => {
  return (
    <div>
      <Layout
        content={
          LocalStorageHelper.getItem(LocalStorageHelper.KEYS.ROLE) ===
          "ADMIN" ? (
            <DashboardContent />
          ) : (
            <div className="dashboard-main-container is-flex is-justify-content-center is-align-items-center">
              <div className="heading-0">Coming soon....</div>
            </div>
          )
        }
      />
    </div>
  );
};

export default Dashboard;
