import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";
import "./index.scss";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import MoneyOutlinedIcon from "@mui/icons-material/MoneyOutlined";
import AlignVerticalBottomIcon from "@mui/icons-material/AlignVerticalBottom";

interface OptionButtonsProps {
  name: string;
  icon: React.ReactNode;
  handleClick: () => void;
}

const OptionButtons = (props: OptionButtonsProps): JSX.Element => {
  const { name, icon, handleClick } = props;

  return (
    <div className="options-button" onClick={handleClick}>
      <div>{icon}</div>
      <div className="option-name">{name}</div>
    </div>
  );
};

const ReportsContent = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <>
      <div className="save-records-container is-flex is-justify-content-center is-align-items-center">
        <div className="">
          <div className="heading-3 headers-save">Financial Reports</div>
          <div className="save-recods-options flex flex-grow">
            <div className="options-container">
              <OptionButtons
                name="Expenses"
                icon={<MoneyOutlinedIcon sx={{ fontSize: 100 }} />}
                handleClick={(): void => navigate("/expenses")}
              />
            </div>
            <div className="options-container">
              <OptionButtons
                name="Profit & Loss"
                icon={<AlignVerticalBottomIcon sx={{ fontSize: 100 }} />}
                handleClick={(): void => navigate("/profit-loss")}
              />
            </div>
            {/* <div className="options-container">
              <OptionButtons
                name="Settlements"
                icon={<AccountBalanceWalletIcon sx={{ fontSize: 100 }} />}
                handleClick={(): void => {}}
              />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

const Reports: React.FunctionComponent = () => {
  return (
    <div>
      <Layout content={<ReportsContent />} />
    </div>
  );
};

export default Reports;
