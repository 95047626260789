import { useState } from "react";
import { createStore } from "reusable";
import { ResponseType } from "../../utils/types";
import { LoginResponseType, LoginRequestType } from "../types/types";

import * as LocalStorage from "../../utils/localStorageHelper";

import LoginService from "./Service";

const LoginState = createStore(() => {
  const [loginResponseData, setLoginResponseData] =
    useState<LoginResponseType>();

  const [loginResponseErrorData, setLoginResponseErrorData] =
    useState<ResponseType<LoginResponseType>>();

  const clearLoginResponseData = (): void => {
    setLoginResponseData({} as LoginResponseType);
    setLoginResponseErrorData({} as ResponseType<LoginResponseType>);
  };

  const callLoginApi = (payload: LoginRequestType): void => {
    LoginService.callLoginApi(payload)
      .then((response) => {
        setLoginResponseData(response.data);
      })
      .catch((error) => {
        setLoginResponseErrorData(error);
      });
  };

  return {
    loginResponseData,
    callLoginApi,
    loginResponseErrorData,
    clearLoginResponseData,
  };
});

export default LoginState;
