/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Layout from "../../../components/Layout";
import CustomerState from "../Store";
import "./index.scss";
import CustomerDetailsPage from "./CustomerDetails";
import {
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TablePagination,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import {
  CustomersListResponseType,
  CustomersListType,
} from "../../types/types";
import { isNotEmptyObj, paginateList } from "../../../utils/helper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#3981DE",
    color: theme.palette.common.white,
    fontWeight: "600",
    fontSize: "15px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const CustomerDetailsContent = (): JSX.Element => {
  const customerId = window.location.href.split("/")[4];

  const {
    customersListResponse,
    customersListResponseError,
    getCustomersList,
  } = CustomerState();

  const [selectedCustomerId, setSelectedCustomerId] = useState(customerId);
  const [pageNumber, setPageNumber] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(20);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [customersList, setCustomersList] = useState<CustomersListType[]>();

  useEffect(() => {
    if (isNotEmptyObj(customersListResponse)) {
      const { list } = customersListResponse as CustomersListResponseType;
      const {
        data,
        pageNumber: pageNo,
        entriesPerPage: entriesInPage,
        totalCount: total,
      } = paginateList(list, pageNumber, entriesPerPage);
      setCustomersList(data);
      setPageNumber(pageNo);
      setEntriesPerPage(entriesInPage);
      setTotalCount(total);
    }
  }, [customersListResponse, customersListResponseError]);

  useEffect(() => {
    if (!isNotEmptyObj(customersListResponse)) {
      const payload = {
        employeeId: "",
        entriesPerPage: entriesPerPage,
        pageNumber: pageNumber,
      };
      getCustomersList(payload);
    }
  }, []);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ): void => {
    if (event) {
      const { list } = customersListResponse as CustomersListResponseType;
      const { data } = paginateList(list, page, entriesPerPage);
      setCustomersList(data);
      setPageNumber(page);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEntriesPerPage(+event.target.value);
    setPageNumber(0);
    const payload = {
      employeeId: "",
      entriesPerPage: entriesPerPage,
      pageNumber: 0,
    };
    getCustomersList(payload);
    setPageNumber(0);
  };

  const pushToDetails = (customerId: string): void => {
    setSelectedCustomerId(customerId);
  };

  return (
    <div className="customer-details-container">
      <div className="customer-list">
        <div className="customers-list-header-container">
          <div className="customers-list-header">Customers</div>
        </div>
        <div className="customer-list-table">
          <TableContainer className="customer-table">
            <Table sx={{ fontFamily: "Roboto" }} aria-label="simple table">
              <TableBody>
                {customersList &&
                  customersList.map((row, index) => (
                    <StyledTableRow
                      key={row.customerId}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                      className="customer-list-table-row"
                    >
                      <StyledTableCell
                        onClick={(): void => pushToDetails(row.customerId)}
                        align="right"
                      >
                        {row.customerName}
                      </StyledTableCell>
                      <StyledTableCell
                        onClick={(): void => pushToDetails(row.customerId)}
                        style={{ textAlign: "right" }}
                        className="customer-list-table-cell-center is-Desktop-only"
                      >
                        {row.amountOutStanding !== ""
                          ? Number(row.amountOutStanding).toLocaleString(
                              "en-IN"
                            )
                          : 0}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              count={totalCount}
              rowsPerPageOptions={[20, 30]}
              component="div"
              rowsPerPage={entriesPerPage}
              page={pageNumber}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{ maxWidth: 200 }}
            />
          </TableContainer>
        </div>
      </div>
      {selectedCustomerId && (
        <div className="customer-details">
          <CustomerDetailsPage customerId={selectedCustomerId} />
        </div>
      )}
    </div>
  );
};

const CustomerDetails: React.FunctionComponent = () => {
  return (
    <div>
      <Layout content={<CustomerDetailsContent />} />
    </div>
  );
};

export default CustomerDetails;
