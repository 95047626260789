import { GenericSuccessResponseType } from "./../../utils/types";
import { useState } from "react";
import { createStore } from "reusable";
import { ResponseType } from "../../utils/types";
import EmployeeService from "./Service";
import {
  AddAttendanceRequestType,
  EmployeeAttendanceDetailsType,
  GetAttendanceSalaryRecordsRequestType,
  GetAttendanceSalaryRecordsResponseType,
  GetEmployeeAttendanceRequestType,
  GetEmployeeAttendanceResponseType,
} from "./types";

const EmployeeStore = createStore(() => {
  const [employeeAttendaceRecords, setEmployeeAttendanceRecord] = useState<
    EmployeeAttendanceDetailsType[]
  >([]);

  const [getEmployeeAttendanceSuccess, setgetEmployeeAttendanceSuccess] =
    useState<GetEmployeeAttendanceResponseType>();

  const [getEmployeeAttendanceError, setGetEmployeeAttendaceError] =
    useState<ResponseType<null>>();

  const [getEmployeeRecordsSuccess, setGetEmployeeRecordsSuccess] =
    useState<GetAttendanceSalaryRecordsResponseType>();

  const [getEmployeeRecordsError, setGetEmployeeRecordsError] =
    useState<ResponseType<null>>();

  const [addAttendanceSuccess, setAddAttendanceSuccess] =
    useState<GenericSuccessResponseType>();

  const [addAttendanceError, setAddAttendanceError] =
    useState<ResponseType<null>>();

  const clearGetEmployeeAttendacesResponses = (): void => {
    setgetEmployeeAttendanceSuccess({} as GetEmployeeAttendanceResponseType);
    setGetEmployeeAttendaceError({} as ResponseType<null>);
  };

  const clearGetEmployeeRecordsResponses = (): void => {
    setGetEmployeeRecordsSuccess({} as GetAttendanceSalaryRecordsResponseType);
    setGetEmployeeRecordsError({} as ResponseType<null>);
  };

  const clearAddAttendanceResponses = (): void => {
    setAddAttendanceSuccess({} as GenericSuccessResponseType);
    setAddAttendanceError({} as ResponseType<null>);
  };

  const getEmployeeAttendances = (
    payload: GetEmployeeAttendanceRequestType
  ): void => {
    EmployeeService.getEmployeeAttendances(payload)
      .then((response) => {
        setgetEmployeeAttendanceSuccess(response.data);
      })
      .catch((error) => {
        setGetEmployeeAttendaceError(error);
      });
  };

  const getEmployeeRecords = (
    payload: GetAttendanceSalaryRecordsRequestType
  ): void => {
    EmployeeService.getEmployeeRecords(payload)
      .then((response) => {
        setGetEmployeeRecordsSuccess(response.data);
      })
      .catch((error) => {
        setGetEmployeeRecordsError(error);
      });
  };

  const addAttendance = (payload: AddAttendanceRequestType): void => {
    console.log(payload);
    EmployeeService.addAttendance(payload)
      .then((response) => {
        setAddAttendanceSuccess(response.data);
      })
      .catch((error) => {
        setAddAttendanceError(error);
      });
  };

  return {
    employeeAttendaceRecords,
    setEmployeeAttendanceRecord,
    getEmployeeAttendanceSuccess,
    getEmployeeAttendanceError,
    getEmployeeAttendances,
    clearGetEmployeeAttendacesResponses,
    getEmployeeRecords,
    getEmployeeRecordsSuccess,
    getEmployeeRecordsError,
    clearGetEmployeeRecordsResponses,
    addAttendanceSuccess,
    addAttendanceError,
    addAttendance,
    clearAddAttendanceResponses,
  };
});

export default EmployeeStore;
