/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Autocomplete, Button, TextField, IconButton } from "@mui/material";
import Modal from "../../components/Modal";
import {
  AddCreditCustomerType,
  CreditSalesType,
  ProductType,
  VehicleType,
} from "../types/types";
import { useFormik } from "formik";
import "./index.scss";
import AddSalesState from "./Store";
import ClearIcon from "@mui/icons-material/Clear";
import { isNotEmptyObj, stripZerosAndAlpha } from "../../utils/helper";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import GroupAddRoundedIcon from "@mui/icons-material/GroupAddRounded";

interface Props {
  closeModal: () => void;
  showAddCustomer: () => void;
}

const AddCreditSalesContent = (props: Props): JSX.Element => {
  const { closeModal, showAddCustomer } = props;

  const [selectedCustomer, setSelectedCustomer] =
    useState<AddCreditCustomerType>();

  const [selectedProduct, setSelectedProduct] = useState<ProductType>();

  const { customersList, productList, creditSalesList, setCreditSalesList } =
    AddSalesState();

  const formik = useFormik({
    initialValues: {
      customerId: "",
      name: "",
      amount: "",
      product: "",
      productCode: "",
      price: "",
      quantity: "",
      vehicleNumber: "",
      vehicleName: "",
    },
    onSubmit: (value) => {
      //
    },
  });

  const handleCustomerSelection = (
    customer: AddCreditCustomerType | null
  ): void => {
    if (customer) {
      setSelectedCustomer(customer);
      formik.setFieldValue("customerId", customer.customerId);
      formik.setFieldValue("name", customer.customerName);
    } else {
      setSelectedCustomer({} as AddCreditCustomerType);
      formik.setFieldValue("customerId", "");
      formik.setFieldValue("name", "");
    }
  };

  const handleProductSelection = (product: ProductType | null): void => {
    if (product) {
      formik.setFieldValue("product", product.productName);
      formik.setFieldValue("productCode", product.productCode);
      formik.setFieldValue("price", product.price);
      setSelectedProduct(product);
    }
  };

  const handleChange = (
    type: string,
    event: React.ChangeEvent<HTMLInputElement>,
    isAmount: boolean
  ): void => {
    let input = event.target.value;
    if (isAmount) input = stripZerosAndAlpha(input);
    formik.setFieldValue(type, input);
  };

  const resetCreditSales = (): void => {
    formik.resetForm();
    setSelectedCustomer({} as AddCreditCustomerType);
    setSelectedProduct({} as ProductType);
  };

  const handleVehicleSelection = (vehicle: VehicleType | null): void => {
    if (vehicle) {
      formik.setFieldValue("vehicleNumber", vehicle.number);
      formik.setFieldValue("vehicleName", vehicle.name);
    }
  };

  const saveCreditSale = (): void => {
    setCreditSalesList([
      ...creditSalesList,
      {
        saleId: Math.random().toString(36).substring(2, 7),
        customerId: formik.values.customerId,
        name: formik.values.name,
        product: formik.values.product,
        productCode: formik.values.productCode,
        quantity: formik.values.quantity,
        price: formik.values.price,
        amount: formik.values.amount,
        vehicleNumber: formik.values.vehicleNumber,
        vehicleName: formik.values.vehicleName,
      },
    ]);
    resetCreditSales();
  };

  const handleAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    handleChange("amount", event, true);
    formik.setFieldValue(
      "quantity",
      (Number(event.target.value) / Number(formik.values.price))
        .toFixed(2)
        .toString()
    );
  };

  const isCreditSaleSavable = (): boolean => {
    if (
      isNotEmptyObj(selectedCustomer) &&
      isNotEmptyObj(selectedProduct) &&
      formik.values.amount &&
      formik.values.vehicleName
    )
      return true;
    return false;
  };

  return (
    <div className="credit-sales-modal">
      <div className="credit-sales-container">
        <div className="header-container is-flex is-justify-content-space-between is-align-items-center">
          <div className="credit-sale-header">Add Credit Sales</div>
          <IconButton onClick={(): void => closeModal()}>
            <ClearIcon color="error" />
          </IconButton>
        </div>
        <div className="credit-sale-form">
          <div className="select-customer-container columns">
            <div className="select-customer-dropdown column">
              <Autocomplete
                disablePortal
                id="customer-search-box"
                key={selectedCustomer?.customerId}
                options={customersList}
                getOptionLabel={(option) =>
                  option.customerName ? option.customerName : ""
                }
                sx={{ width: 250 }}
                value={selectedCustomer}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="credit-sale-customer"
                    label="Customer Name"
                    variant="outlined"
                    value={formik.values.name}
                  />
                )}
                onChange={(
                  event: any,
                  newValue: AddCreditCustomerType | null
                ) => {
                  handleCustomerSelection(newValue);
                }}
              />
            </div>
            {isNotEmptyObj(selectedCustomer) ? (
              <div className="select-customer-dropdown column">
                <Autocomplete
                  disablePortal
                  id="customer-search-box"
                  options={selectedCustomer?.vehicles || []}
                  getOptionLabel={(option) =>
                    `${option.name} | ${option.number}`
                  }
                  sx={{ width: 250 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="credit-sale-customer"
                      label="Select Vehicle"
                      variant="outlined"
                      value={formik.values.vehicleNumber}
                    />
                  )}
                  onChange={(event: any, newValue: VehicleType | null) => {
                    handleVehicleSelection(newValue);
                  }}
                />
              </div>
            ) : (
              <div className="add-customer-button column is-flex is-justify-content-center is-align-items-center">
                <Button
                  onClick={(): void => {
                    closeModal();
                    showAddCustomer();
                  }}
                  variant="contained"
                  startIcon={<GroupAddRoundedIcon />}
                >
                  Add customer
                </Button>
              </div>
            )}
          </div>
          <div className="columns">
            <div className="select-customer-dropdown column">
              <Autocomplete
                disablePortal
                id="product-search-box"
                options={productList}
                getOptionLabel={(option) =>
                  option.productName ? option.productName : ""
                }
                sx={{ width: 250 }}
                value={selectedProduct}
                key={selectedProduct?.productCode}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="credit-sale-customer"
                    label="Select Product"
                    variant="outlined"
                    value={formik.values.product}
                  />
                )}
                onChange={(event: any, newValue: ProductType | null) => {
                  handleProductSelection(newValue);
                }}
              />
            </div>
            <div className="column">
              <TextField
                id="quantity"
                label="Quantity"
                variant="outlined"
                value={formik.values.quantity}
                sx={{ width: 250 }}
                disabled
              />
            </div>
          </div>
          {isNotEmptyObj(formik.values.productCode) && (
            <div className="columns">
              <div className="column">
                <TextField
                  id="price"
                  label="Price"
                  variant="outlined"
                  value={formik.values.price}
                  sx={{ width: 250 }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    handleChange("price", e, true);
                  }}
                />
              </div>
              <div className="column">
                <TextField
                  type="number"
                  id="amount"
                  label="Amount"
                  variant="outlined"
                  value={formik.values.amount}
                  sx={{ width: 250 }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    handleAmountChange(e);
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div className="save-sales-container is-flex is-justify-content-flex-end">
          <div className="reset-button">
            <Button
              variant="contained"
              onClick={(): void => {
                resetCreditSales();
              }}
              startIcon={<RestartAltIcon />}
            >
              Reset
            </Button>
          </div>
          <div>
            <Button
              variant="contained"
              onClick={(): void => {
                saveCreditSale();
              }}
              disabled={!isCreditSaleSavable()}
              color="success"
            >
              Add Credit Sale
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const AddCreditSales = (prop: Props): JSX.Element => {
  const [show, setShow] = useState(true);
  const { closeModal, showAddCustomer } = prop;
  return (
    <Modal
      show={show}
      closeModal={(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        setShow(false);
        closeModal();
      }}
      content={
        <AddCreditSalesContent
          closeModal={closeModal}
          showAddCustomer={showAddCustomer}
        />
      }
      persistent
    />
  );
};

export default AddCreditSales;
