import React from "react";
import "./index.scss";

interface Props {
  month: string;
  totalMonthlyExpenses: string;
  salaryExpense: string;
  ownerExpense: string;
  msHsdPurchaseExpense: string;
  lubePurchaseExpense: string;
  bankExpenses: string;
  miscellaneousExpense: string;
  discountExpenses: string;
}

const ExpenseTotals = (props: Props) => {
  // Calculate totals for each type
  //   const totals = expenses.reduce((acc, expense) => {
  //     if (!acc[expense.type]) {
  //       acc[expense.type] = 0;
  //     }
  //     acc[expense.type] += expense.amount;
  //     return acc;
  //   }, {});

  return (
    <div className="expense-totals">
      <h2>{`Total Expenses of ${props.month}`}</h2>
      <ul>
        <li className="expense-item">
          <span className="expense-type">Salary Expenses</span>

          <span className="expense-amount">
            ₹
            {!isNaN(Number(props.salaryExpense))
              ? Number(props.salaryExpense).toLocaleString("en-IN")
              : props.salaryExpense}
          </span>
        </li>
        <li className="expense-item">
          <span className="expense-type">Owners Drawings</span>
          <span className="expense-amount">
            ₹
            {!isNaN(Number(props.ownerExpense))
              ? Number(props.ownerExpense).toLocaleString("en-IN")
              : props.ownerExpense}
          </span>
        </li>
        <li className="expense-item">
          <span className="expense-type">Petrol and Diesel Purchase</span>
          <span className="expense-amount">
            ₹
            {!isNaN(Number(props.msHsdPurchaseExpense))
              ? Number(props.msHsdPurchaseExpense).toLocaleString("en-IN")
              : props.msHsdPurchaseExpense}
          </span>
        </li>
        <li className="expense-item">
          <span className="expense-type">Lubricants and Gas Purchase</span>
          <span className="expense-amount">
            ₹
            {!isNaN(Number(props.lubePurchaseExpense))
              ? Number(props.lubePurchaseExpense).toLocaleString("en-IN")
              : props.lubePurchaseExpense}
          </span>
        </li>
        <li className="expense-item">
          <span className="expense-type">Banking and Interest Expenses</span>
          <span className="expense-amount">
            ₹
            {!isNaN(Number(props.bankExpenses))
              ? Number(props.bankExpenses).toLocaleString("en-IN")
              : props.bankExpenses}
          </span>
        </li>
        <li className="expense-item">
          <span className="expense-type">Miscellaneous Expenses</span>
          <span className="expense-amount">
            ₹
            {!isNaN(Number(props.miscellaneousExpense))
              ? Number(props.miscellaneousExpense).toLocaleString("en-IN")
              : props.miscellaneousExpense}
          </span>
        </li>
        <li className="expense-item">
          <span className="expense-type">Discount Expenses</span>
          <span className="expense-amount">
            ₹
            {!isNaN(Number(props.discountExpenses))
              ? Number(props.discountExpenses).toLocaleString("en-IN")
              : props.discountExpenses}
          </span>
        </li>
        <li className="expense-item-total">
          <span className="expense-type-total">Total Monthly Expenses: </span>
          <span className="expense-amount-total">
            ₹
            {!isNaN(Number(props.totalMonthlyExpenses))
              ? Number(props.totalMonthlyExpenses).toLocaleString("en-IN")
              : props.totalMonthlyExpenses}
          </span>
        </li>
      </ul>
    </div>
  );
};

export default ExpenseTotals;
