import { ResponseType } from "../../utils/types";
import request, { Methods } from "../../utils/api";
import {
  AddCustomerPaymentRequestType,
  AddCustomerPaymentResponseType,
  CreateCustomerRequestType,
  CreateCustomerResponseType,
  CustomerCreditResponseType,
  CustomerDetailsRequestType,
  CustomerDetailsResponseType,
  CustomerPaymentsResponseType,
  CustomersListRequestType,
  CustomersListResponseType,
  CustomerTransactionRequestType,
} from "../types/types";
import { apiEndPoints } from "../../utils/appConstants";
import { AccountStatementResponseType } from "./types";

const callCreateCustomer = (
  payload: CreateCustomerRequestType
): Promise<ResponseType<CreateCustomerResponseType>> =>
  request<CreateCustomerResponseType>({
    url: apiEndPoints.createCustomer,
    method: Methods.POST,
    data: payload,
  });

const getCustomersList = (
  payload: CustomersListRequestType
): Promise<ResponseType<CustomersListResponseType>> => {
  return request({
    url: apiEndPoints.getCustomersList,
    method: Methods.POST,
    data: payload,
  });
};

const getCustomerDetails = (
  payload: CustomerDetailsRequestType
): Promise<ResponseType<CustomerDetailsResponseType>> => {
  return request({
    url: apiEndPoints.getCustomerDetails,
    method: Methods.POST,
    data: payload,
  });
};

const getCustomerCreditList = (
  payload: CustomerTransactionRequestType
): Promise<ResponseType<CustomerCreditResponseType>> => {
  return request({
    url: apiEndPoints.getCustomerCredits,
    method: Methods.POST,
    data: payload,
  });
};

const getCustomerPaymentsList = (
  payload: CustomerTransactionRequestType
): Promise<ResponseType<CustomerPaymentsResponseType>> => {
  return request({
    url: apiEndPoints.getCustomerPayments,
    method: Methods.POST,
    data: payload,
  });
};

const addCustomerPayment = (
  payload: AddCustomerPaymentRequestType
): Promise<ResponseType<AddCustomerPaymentResponseType>> => {
  return request({
    url: apiEndPoints.addCustometPayment,
    method: Methods.POST,
    data: payload,
  });
};

const getCustomerStatement = (
  payload: CustomerTransactionRequestType
): Promise<ResponseType<AccountStatementResponseType>> => {
  return request({
    url: apiEndPoints.getStatement,
    method: Methods.POST,
    data: payload,
  });
};

const CustomerService = {
  callCreateCustomer,
  getCustomersList,
  getCustomerDetails,
  getCustomerCreditList,
  getCustomerPaymentsList,
  addCustomerPayment,
  getCustomerStatement,
};

export default CustomerService;
