import { useState } from "react";
import { createStore } from "reusable";
import {
  AddNozzleReadingsRequestType,
  AddSettlementsRequestType,
  DashboardDataRequestType,
  DashboardDataResponseType,
} from "./types";
import { ResponseType, GenericSuccessResponseType } from "../../utils/types";
import DashboardService from "./Service";
import fileDownload from "js-file-download";
import { formateDateToMonthString } from "../../utils/helper";

const DashboardState = createStore(() => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  const [dashboardData, setDashboardData] =
    useState<DashboardDataResponseType>();

  const [dashboardDataResponseError, setDashboardDataResponseError] =
    useState<ResponseType<DashboardDataResponseType>>();

  const [addReadingsSuccess, setAddReadingsSuccess] =
    useState<ResponseType<null>>();

  const [addReadingsError, setAddReadingsError] =
    useState<ResponseType<null>>();

  const [addSettlementsSuccess, setAddSettlementsSuccess] =
    useState<GenericSuccessResponseType>();

  const [addSettlementsError, setAddSettlementsError] =
    useState<ResponseType<null>>();

  const [downloadReportError, setDownloadReportError] =
    useState<ResponseType<null>>();

  const clearDownloadReponseData = (): void =>
    setDownloadReportError({} as ResponseType<null>);

  const clearDashboardData = (): void => {
    setDashboardData({} as DashboardDataResponseType);
    setDashboardDataResponseError(
      {} as ResponseType<DashboardDataResponseType>
    );
  };

  const clearAddReadingResponses = (): void => {
    setAddReadingsSuccess({} as ResponseType<null>);
    setAddReadingsError({} as ResponseType<null>);
  };

  const clearAddSettlementsResponses = (): void => {
    setAddSettlementsSuccess({} as GenericSuccessResponseType);
    setAddSettlementsError({} as ResponseType<null>);
  };

  const loadDashboardData = (payload: DashboardDataRequestType): void => {
    DashboardService.loadDashboardData(payload)
      .then((response) => {
        setDashboardData(response.data);
      })
      .catch((error) => {
        setDashboardDataResponseError(error);
      });
  };

  const addReadings = (
    payload: AddNozzleReadingsRequestType,
    type: string
  ): void => {
    DashboardService.addNozzleReadings(payload, type)
      .then((response) => {
        setAddReadingsSuccess(response);
      })
      .catch((error) => {
        setAddReadingsError(error);
      });
  };

  const addSettlements = (payload: AddSettlementsRequestType): void => {
    DashboardService.addSettlements(payload)
      .then((response) => {
        setAddSettlementsSuccess(response.data);
      })
      .catch((error) => {
        setAddSettlementsError(error);
      });
  };

  const downloadDailyClosingReport = (
    payload: DashboardDataRequestType
  ): void => {
    DashboardService.downloadClosingReport(payload)
      .then((response) => {
        console.log('>>>')
        fileDownload(
          response as unknown as Blob,
          `Closing_Report_${payload.date.replace("/", "_")}.pdf`
        );
      })
      .catch((error) => { });
  };

  return {
    selectedDate,
    setSelectedDate,
    loadDashboardData,
    dashboardData,
    dashboardDataResponseError,
    clearDashboardData,
    addReadings,
    addReadingsSuccess,
    addReadingsError,
    clearAddReadingResponses,
    addSettlementsSuccess,
    addSettlementsError,
    addSettlements,
    clearAddSettlementsResponses,
    clearDownloadReponseData,
    downloadDailyClosingReport,
    downloadReportError,
  };
});

export default DashboardState;
