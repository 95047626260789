import { ResponseType } from "../../utils/types";
import request, { Methods } from "../../utils/api";
import {
  CreateCustomerRequestType,
  CreateCustomerResponseType,
  CreateDailySaleRecordRequestType,
  CreateDailySaleRecordResponseType,
  FetchDailySalesRecordRequestType,
  FetchTodaysPriceRequestType,
  FetchTodaysPriceResponseType,
  GetCustomerListRequest,
  GetCustomersResponseType,
  ProductListRequestType,
  ProductListResponseType,
  SavePriceReponseType,
  SavePriceRequestType,
} from "../types/types";
import { apiEndPoints } from "../../utils/appConstants";

const getCustomersList = (
  payload: GetCustomerListRequest
): Promise<ResponseType<GetCustomersResponseType>> =>
  request<GetCustomersResponseType>({
    url: apiEndPoints.getCustomerList,
    method: Methods.POST,
    data: payload,
  });

const callCreateCustomer = (
  payload: CreateCustomerRequestType
): Promise<ResponseType<CreateCustomerResponseType>> =>
  request<CreateCustomerResponseType>({
    url: apiEndPoints.createCustomer,
    method: Methods.POST,
    data: payload,
  });

const getProductList = (
  payload: ProductListRequestType
): Promise<ResponseType<ProductListResponseType>> =>
  request<ProductListResponseType>({
    url: apiEndPoints.getProductList,
    method: Methods.POST,
    data: payload,
  });

const callCreateDailySales = (
  payload: CreateDailySaleRecordRequestType
): Promise<ResponseType<CreateDailySaleRecordResponseType>> =>
  request<CreateDailySaleRecordResponseType>({
    url: apiEndPoints.createDailySale,
    method: Methods.POST,
    data: payload,
  });

const fetchDailySalesRecord = (
  payload: FetchDailySalesRecordRequestType
): Promise<ResponseType<CreateDailySaleRecordRequestType>> =>
  request<CreateDailySaleRecordRequestType>({
    url: apiEndPoints.fetchDailySales,
    method: Methods.POST,
    data: payload,
  });

const fetchTodaysPrice = (
  payload: FetchTodaysPriceRequestType
): Promise<ResponseType<FetchTodaysPriceResponseType>> => {
  return request<FetchTodaysPriceResponseType>({
    url: apiEndPoints.fetchTodaysPrice,
    method: Methods.POST,
    data: payload,
  });
};

const saveTodaysPrice = (
  payload: SavePriceRequestType
): Promise<ResponseType<SavePriceReponseType>> => {
  return request<SavePriceReponseType>({
    url: apiEndPoints.saveTodaysPrice,
    method: Methods.POST,
    data: payload,
  });
};

const deleteDailySalesRecord = (payload: {
  sheetId: string;
}): Promise<ResponseType<null>> => {
  return request<null>({
    url: apiEndPoints.deleteDailySalesRecord,
    method: Methods.POST,
    data: payload,
  });
};

const AddSalesDetailsService = {
  getCustomersList,
  callCreateCustomer,
  getProductList,
  callCreateDailySales,
  fetchDailySalesRecord,
  fetchTodaysPrice,
  saveTodaysPrice,
  deleteDailySalesRecord,
};

export default AddSalesDetailsService;
