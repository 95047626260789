/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import {
  Button,
  Snackbar,
  Alert,
  AlertTitle,
  IconButton,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import "./index.scss";
import { useState, useEffect } from "react";
import DropDownInput from "../../../components/DropdownInput";
import { employeeList, expenseTypeList } from "../../../utils/appConstants";
import { stripZerosAndAlpha, isNotEmptyObj } from "../../../utils/helper";
import ClearIcon from "@mui/icons-material/Clear";
import Modal from "../../../components/Modal";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { AddExpenseData } from "./types";
import ExpenseStore from "./Store";
import { AccountCircle } from "@mui/icons-material";

interface PaymentModalProps {
  closeModal: () => void;
}
const AddExpensesContent = (props: PaymentModalProps): JSX.Element => {
  const { closeModal } = props;

  const {
    addExpenses,
    addExpensesResponse,
    addExpensesResponseError,
    clearAddExpensesResponse,
    getExpensesReport,
  } = ExpenseStore();

  const [expenses, setExpenses] = useState<AddExpenseData[]>([]);

  const [amount, setAmount] = useState("");
  const [expenseType, setExpenseType] = useState("");
  const [date, setDate] = useState<Date>(new Date());
  const [description, setDescription] = useState("");
  const [fromAccount, setFromAccount] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [emplyeeName, setEmployeeName] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const handleAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => setAmount(stripZerosAndAlpha(event.target.value));

  const handleDateChange = (newValue: Date | null) => {
    if (newValue != null) {
      setDate(newValue);
    }
  };

  const handleExpenseTypeSelection = (value: string): void => {
    const paymentmode = expenseTypeList.find((item) => item.code === value);
    if (paymentmode) setExpenseType(paymentmode.code);
  };

  const handleSalaryEmployeeSelection = (value: string): void => {
    const employee = employeeList.find((item) => item.code === value);
    if (employee) {
      setEmployeeId(employee.code);
      setEmployeeName(employee.name);
      setDescription(`${employee.name} Salary`);
    }
  };

  useEffect(() => {
    if (isNotEmptyObj(addExpensesResponse)) {
      setShowSuccessAlert(true);
      clearAddExpensesResponse();
      setTimeout(() => {
        closeModal();
      }, 3000);
    }

    if (isNotEmptyObj(addExpensesResponseError)) {
      setShowErrorAlert(true);
      clearAddExpensesResponse();
    }
  }, [addExpensesResponse, addExpensesResponseError]);

  const saveExpenses = (): void => {
    addExpenses({ expenses: expenses });
  };

  const addExpensesHandler = (): void => {
    setExpenses([
      ...expenses,
      {
        date: date.toLocaleDateString(),
        expenseId: Math.random().toString(36).substring(2, 7),
        amount: amount,
        expenseType: expenseType,
        description: description,
        fromAccount: fromAccount,
        employeeId: employeeId,
        employeeName: emplyeeName,
      },
    ]);
    setAmount("");
    setDate(new Date());
    setDescription("");
    setFromAccount("");
    setEmployeeId("");
    setEmployeeName("");
  };

  const deletePaymentRecieved = (id: string): void => {
    const filteredList = expenses.filter((item) => item.expenseId !== id);
    setExpenses(filteredList);
  };

  return (
    <div className="add-payment-modal">
      {showSuccessAlert && (
        <Snackbar
          open={showSuccessAlert}
          autoHideDuration={4000}
          onClose={(): void => setShowSuccessAlert(false)}
          message="Note archived"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            severity="success"
            onClose={(): void => setShowSuccessAlert(false)}
          >
            <AlertTitle>Success</AlertTitle>
            Successfully added expenses
          </Alert>
        </Snackbar>
      )}
      {showErrorAlert && (
        <Snackbar
          open={showErrorAlert}
          autoHideDuration={4000}
          onClose={(): void => setShowErrorAlert(false)}
          message="Note archived"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            severity="error"
            onClose={(): void => setShowErrorAlert(false)}
          >
            <AlertTitle>Failed to add expenses</AlertTitle>
            Error while adding expenses. Please try again later
          </Alert>
        </Snackbar>
      )}
      <div className="add-payments-container">
        <div className="header-container is-flex is-justify-content-space-between is-align-items-center">
          <div className="add-payment-header">{`Add Expenses`}</div>
          <IconButton onClick={(): void => closeModal()}>
            <ClearIcon color="error" />
          </IconButton>
        </div>
        <div className="add-payment-form">
          <div className="columns">
            <div className="column">
              <LocalizationProvider dateAdapter={AdapterDateFns} disablePortal>
                <div className="column">
                  <DesktopDatePicker
                    className="date-picker-new"
                    value={date}
                    inputFormat="dd/MM/yyyy"
                    onChange={handleDateChange}
                    renderInput={(params: any) => (
                      <TextField {...params} sx={{ width: 190 }} />
                    )}
                  />
                </div>
              </LocalizationProvider>
            </div>
            <div className="column">
              <div className="payment-mode-input">
                <DropDownInput
                  value={expenseType}
                  items={expenseTypeList}
                  placeholder="Select Mode"
                  name="payment-mode-name"
                  onChange={handleExpenseTypeSelection}
                  width={190}
                />
              </div>
            </div>
            {expenseType !== "SALARY" && (
              <div className="column">
                <div className="payment-mode-input">
                  <TextField
                    id="payment-description"
                    label="Description"
                    variant="outlined"
                    value={description}
                    sx={{ width: 190 }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                      setDescription(e.target.value)
                    }
                  />
                </div>
              </div>
            )}
            {expenseType === "SALARY" && (
              <div className="column">
                <div className="payment-mode-input">
                  <DropDownInput
                    value={emplyeeName}
                    items={employeeList}
                    placeholder="Select Person"
                    name="select-person-name"
                    onChange={handleSalaryEmployeeSelection}
                    width={190}
                    icon={<AccountCircle />}
                  />
                </div>
              </div>
            )}
            <div className="column">
              <div className="payment-mode-input">
                <TextField
                  id="payment-amount"
                  label="Amount"
                  variant="outlined"
                  value={amount}
                  sx={{ width: 190 }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                    handleAmountChange(e)
                  }
                />
              </div>
            </div>
            <div className="column">
              <div className="payment-mode-input">
                <DropDownInput
                  value={fromAccount}
                  items={[
                    { code: "CASH", name: "Cash" },
                    { code: "BANK", name: "Bank Account" },
                  ]}
                  placeholder="Select Mode"
                  name="payment-mode-name"
                  onChange={(value: string) => setFromAccount(value)}
                  width={190}
                />
              </div>
            </div>
            <div className="column is-1 is-flex is-align-items-center">
              <div className="payment-add-icon">
                {amount && description && fromAccount && (
                  <IconButton onClick={addExpensesHandler}>
                    <AddCircleIcon sx={{ fontSize: 30 }} />
                  </IconButton>
                )}
              </div>
            </div>
          </div>
          <div className="payments-table-container">
            {expenses.length !== 0 && (
              <>
                <TableContainer>
                  <Table
                    sx={{ minWidth: 600, fontFamily: "Roboto" }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align="right">Sl.No</TableCell>
                        <TableCell align="right">Date</TableCell>
                        <TableCell align="right">Description</TableCell>
                        <TableCell align="right">Expense Type</TableCell>
                        <TableCell align="right">Amount</TableCell>
                        <TableCell align="right">From Account</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {expenses.map((row, index) => (
                        <TableRow
                          key={row.expenseId}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell component="th" scope="row" align="right">
                            {index + 1}
                          </TableCell>
                          <TableCell align="right">{row.date}</TableCell>
                          <TableCell align="right">{row.description}</TableCell>
                          <TableCell align="right">{row.expenseType}</TableCell>
                          <TableCell align="right">{row.amount}</TableCell>
                          <TableCell align="right">{row.fromAccount}</TableCell>
                          <TableCell align="right">
                            <IconButton
                              onClick={(): void =>
                                deletePaymentRecieved(row.expenseId)
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </div>
        </div>
        <div className="save-sales-container is-flex is-justify-content-flex-end">
          <div>
            <Button
              variant="contained"
              onClick={(): void => {
                saveExpenses();
              }}
              color="success"
            >
              Add Payment Made
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const AddExpensesModal = (props: PaymentModalProps): JSX.Element => {
  const [show, setShow] = useState(true);
  const { closeModal } = props;
  return (
    <>
      <Modal
        show={show}
        closeModal={(): void => {
          setShow(false);
          closeModal();
        }}
        content={<AddExpensesContent closeModal={closeModal} />}
        persistent
      />
    </>
  );
};

export default AddExpensesModal;
