import { employeeListToShow } from "./appConstants";

export const isNotEmptyObj = <T>(object: T): boolean =>
  !!(object && Object.keys(object).length > 0 && object !== undefined);

export const stripZerosAndAlpha = (input: string): string =>
  input.replace(/^0+/, "").replace(/[^\d.]/g, "");

export const parseDateFrom = (input: string): Date => {
  const parts = input.split("/");
  return new Date(Number(parts[2]), Number(parts[1]) - 1, Number(parts[0]));
};

export const parseDateTimeFrom = (input: string): Date => {
  const date = input.split(",")[0];
  const time = input.split(",")[1].substring(1);
  const dataParts = date.split("/");
  const timeParts = time.split(":");
  return new Date(
    Number(dataParts[2]),
    Number(dataParts[1]) - 1,
    Number(dataParts[0]),
    Number(timeParts[0]),
    Number(timeParts[1])
  );
};

export const formateDateToLongString = (date: Date): string => {
  var days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    days[date.getDay()] +
    ", " +
    months[date.getMonth()] +
    " " +
    date.getDate() +
    " " +
    date.getFullYear()
  );
};

export const formateDateToMonthString = (date: Date): string => {
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  return (
    months[date.getMonth()] + " " + date.getDate() + " " + date.getFullYear()
  );
};



export const findEmployeeName = (code: string): string => {
  const employee = employeeListToShow.find((item) => item.code === code);
  return employee ? employee.name : "";
};

export const convertToAmountString = (value: string | number): string => {
  if (typeof value === "string") return Number(value).toLocaleString("en-IN");
  return value.toLocaleString("en-IN");
};

export interface PaginationObject<T> {
  data: Array<T>;
  pageNumber: number;
  entriesPerPage: number;
  totalCount: number;
}

export const paginateList = <T>(
  data: Array<T>,
  pageNumber: number,
  entriesPerPage: number
): PaginationObject<T> => {
  const paginatedData = data.slice(
    pageNumber * entriesPerPage,
    (pageNumber + 1) * entriesPerPage
  );
  return {
    data: paginatedData,
    pageNumber: pageNumber,
    entriesPerPage: entriesPerPage,
    totalCount: data.length,
  };
};
