import request, { Methods } from "../../utils/api";
import { ResponseType } from "../../utils/types";
import {
  EmployeeSalesListRequestType,
  EmployeeSalesListResponseType,
} from "../types/types";
import { apiEndPoints } from "../../utils/appConstants";

const getEmployeeSalesList = (
  payload: EmployeeSalesListRequestType
): Promise<ResponseType<EmployeeSalesListResponseType>> => {
  return request<EmployeeSalesListResponseType>({
    url: apiEndPoints.getSalesList,
    method: Methods.POST,
    data: payload,
  });
};

const SalesListService = {
  getEmployeeSalesList,
};

export default SalesListService;
