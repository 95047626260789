export const setItem = (key: string, value: string): void => {
  localStorage.setItem(key, value);
};

export const getItem = (key: string): string => {
  return localStorage.getItem(key) as string;
};

export const removeItem = (key: string): void => {
  if (!localStorage.getItem(key)) {
    return;
  }
  localStorage.removeItem(key);
};

export const clear = (): void => {
  localStorage.clear();
};

export const KEYS = {
  ISLOGGEDIN: "ISLOGGEDIN",
  USERID: "USERID",
  USERNAME: "USERNAME",
  NAME: "NAME",
  TOKEN: "TOKEN",
  ROLE: "ROLE",
};

export const CONSTVALS = {
  LOGGEDINFLAG: "LOGGEDINFLAG",
};
