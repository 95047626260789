import {
  AddCustomerPaymentRequestType,
  AddCustomerPaymentResponseType,
  CreateCustomerRequestType,
  CreateCustomerResponseType,
  CustomerCreditResponseType,
  CustomerDetailsRequestType,
  CustomerDetailsResponseType,
  CustomerPaymentsResponseType,
  CustomersListRequestType,
  CustomersListResponseType,
  CustomerTransactionRequestType,
} from "./../types/types";
import { useState } from "react";
import { createStore } from "reusable";
import { ResponseType } from "../../utils/types";

import CustomerService from "./Service";
import { AccountStatementResponseType } from "./types";

const CustomerState = createStore(() => {
  const [createCustomerResponseData, setCreateCustomerResponseData] =
    useState<CreateCustomerResponseType>();

  const [createCustomerErrorResponseData, setCreateCustomerErrorResponseData] =
    useState<ResponseType<CreateCustomerResponseType>>();

  const [customersListResponse, setCustomersListResponse] =
    useState<CustomersListResponseType>();

  const [customersListResponseError, setCustomersListResponseError] =
    useState<ResponseType<CustomersListResponseType>>();

  const [customerDetailsResponse, setCustomerDetailsResponse] =
    useState<CustomerDetailsResponseType>();

  const [customerDetailsResponseError, setCustomerDetailsResponseError] =
    useState<ResponseType<CustomerDetailsResponseType>>();

  const [customerCreditResponse, setCustomerCreditResponse] =
    useState<CustomerCreditResponseType>();

  const [customerCreditResponseError, setCustomerCreditResponseError] =
    useState<ResponseType<CustomerCreditResponseType>>();

  const [customerPaymentResponse, setCustomerPaymentResponse] =
    useState<CustomerPaymentsResponseType>();

  const [customerPaymentResponseError, setCustomerPaymentResponseError] =
    useState<ResponseType<CustomerPaymentsResponseType>>();

  const [addCustomerPaymentResponse, setAddCustomerPaymentResponse] =
    useState<AddCustomerPaymentResponseType>();

  const [addCustomerPaymentResponseError, setAddCustomerPaymentResponseError] =
    useState<ResponseType<AddCustomerPaymentResponseType>>();

  const [getAccountStatementRespose, setGetAccountStatementResponse] =
    useState<AccountStatementResponseType>();

  const [getAccountStatementError, setGetAccountStatementError] =
    useState<ResponseType<null>>();

  const clearCustomersListResponse = (): void => {
    setCustomersListResponse({} as CustomersListResponseType);
    setCustomersListResponseError(
      {} as ResponseType<CustomersListResponseType>
    );
  };

  const clearCreateCustomerData = (): void => {
    setCreateCustomerResponseData({} as CreateCustomerResponseType);
    setCreateCustomerErrorResponseData(
      {} as ResponseType<CreateCustomerResponseType>
    );
  };

  const clearAddCustomerPaymentResponse = (): void => {
    setAddCustomerPaymentResponse({} as AddCustomerPaymentResponseType);
    setAddCustomerPaymentResponseError(
      {} as ResponseType<AddCustomerPaymentResponseType>
    );
  };

  const clearCustomerCreditAndPayments = (): void => {
    setCustomerCreditResponse({} as CustomerCreditResponseType);
    setCustomerPaymentResponse({} as CustomerPaymentsResponseType);
  };

  const clearAccountStatementResponses = (): void => {
    setGetAccountStatementResponse({} as AccountStatementResponseType);
    setGetAccountStatementError({} as ResponseType<null>);
  };

  const callCreateCustomer = (payload: CreateCustomerRequestType): void => {
    CustomerService.callCreateCustomer(payload)
      .then((response) => {
        setCreateCustomerResponseData(response.data);
      })
      .catch((error) => {
        setCreateCustomerErrorResponseData(error);
      });
  };

  const getCustomersList = (payload: CustomersListRequestType): void => {
    CustomerService.getCustomersList(payload)
      .then((response) => {
        setCustomersListResponse(response.data);
      })
      .catch((error) => {
        setCustomersListResponseError(error);
      });
  };

  const getCustomerDetails = (payload: CustomerDetailsRequestType): void => {
    CustomerService.getCustomerDetails(payload)
      .then((response) => {
        setCustomerDetailsResponse(response.data);
      })
      .catch((error) => {
        setCustomerDetailsResponseError(error);
      });
  };

  const getCustomerCreditList = (
    payload: CustomerTransactionRequestType
  ): void => {
    CustomerService.getCustomerCreditList(payload)
      .then((response) => {
        setCustomerCreditResponse(response.data);
      })
      .catch((error) => {
        setCustomerCreditResponseError(error);
      });
  };

  const getCustomerPaymentList = (
    payload: CustomerTransactionRequestType
  ): void => {
    CustomerService.getCustomerPaymentsList(payload)
      .then((response) => {
        setCustomerPaymentResponse(response.data);
      })
      .catch((error) => {
        setCustomerPaymentResponseError(error);
      });
  };

  const addCustomerPayment = (payload: AddCustomerPaymentRequestType): void => {
    CustomerService.addCustomerPayment(payload)
      .then((response) => {
        setAddCustomerPaymentResponse(response.data);
      })
      .catch((error) => {
        setAddCustomerPaymentResponseError(error);
      });
  };

  const getAccountStatement = (customerId: string): void => {
    CustomerService.getCustomerStatement({
      customerId: customerId,
      entriesPerPage: 10,
      pageNumber: 0,
    })
      .then((response) => {
        setGetAccountStatementResponse(response.data);
      })
      .catch((error) => {
        setGetAccountStatementError(error);
      });
  };

  return {
    callCreateCustomer,
    createCustomerResponseData,
    createCustomerErrorResponseData,
    clearCreateCustomerData,
    getCustomersList,
    customersListResponse,
    customersListResponseError,
    clearCustomersListResponse,
    customerDetailsResponse,
    customerDetailsResponseError,
    getCustomerDetails,
    getCustomerCreditList,
    getCustomerPaymentList,
    customerCreditResponse,
    customerCreditResponseError,
    customerPaymentResponse,
    customerPaymentResponseError,
    addCustomerPaymentResponse,
    addCustomerPaymentResponseError,
    clearAddCustomerPaymentResponse,
    addCustomerPayment,
    clearCustomerCreditAndPayments,
    getAccountStatement,
    getAccountStatementRespose,
    getAccountStatementError,
    clearAccountStatementResponses,
  };
});

export default CustomerState;
