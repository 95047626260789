import { ResponseType } from "../../../utils/types";
import request, { Methods } from "../../../utils/api";
import { apiEndPoints } from "../../../utils/appConstants";
import {
  AddExpenseRequestData,
  ExpensesRequestData,
  ExpensesResponseData,
} from "./types";

const getExpenses = (
  payload: ExpensesRequestData
): Promise<ResponseType<ExpensesResponseData>> =>
  request<ExpensesResponseData>({
    url: apiEndPoints.expenses,
    method: Methods.POST,
    data: payload,
  });

const addExpenses = (
  payload: AddExpenseRequestData
): Promise<ResponseType<null>> => {
  return request({
    url: apiEndPoints.addExpenses,
    method: Methods.POST,
    data: payload,
  });
};

const ExpenseService = {
  getExpenses,
  addExpenses,
};

export default ExpenseService;
