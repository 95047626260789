/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import Modal from "../../../components/Modal";
import "./index.scss";
import {
  IconButton,
  TextField,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Alert,
  AlertTitle,
  Snackbar,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import DropDownInput from "../../../components/DropdownInput";
import {
  AddCreditCustomerType,
  CreateCustomerRequestType,
  VehicleType,
} from "../../types/types";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import CustomerState from "../Store";
import { isNotEmptyObj } from "../../../utils/helper";
import AddSalesState from "../../AddSaleDetails/Store";

interface Props {
  closeModal: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const AddCustomer: React.FunctionComponent<Props> = (props: Props) => {
  const [show, setShow] = useState(true);
  const { closeModal } = props;

  return (
    <>
      <Modal
        show={show}
        closeModal={(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
          setShow(false);
          // closeModal(e);
        }}
        content={<AddCustomerContent closeModal={closeModal} />}
        persistent
      />
    </>
  );
};

const AddCustomerContent: React.FunctionComponent<Props> = (props: Props) => {
  const { closeModal } = props;

  const {
    callCreateCustomer,
    createCustomerResponseData,
    createCustomerErrorResponseData,
    clearCreateCustomerData,
    customersListResponse,
    customersListResponseError,
  } = CustomerState();

  const { getAllCustomer, customersList } = AddSalesState();

  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const [isValidCustomerName, setIsValidCustomerName] = useState(true);

  const [isValidContact, setIsValidContact] = useState(true);

  const [customers, setCustomers] = useState<AddCreditCustomerType[]>();

  useEffect(() => {
    if (isNotEmptyObj(createCustomerResponseData)) {
      setShowSuccessAlert(true);
      clearCreateCustomerData();
      clearDataFromFields();
    }
    if (isNotEmptyObj(createCustomerErrorResponseData)) {
      setShowErrorAlert(true);
      clearCreateCustomerData();
      clearDataFromFields();
    }
  }, [createCustomerResponseData, createCustomerErrorResponseData]);

  const [vehicleList, setVehiclesList] = useState<VehicleType[]>([]);

  const typesOfBusiness = [
    { code: "12", name: "Business" },
    { code: "122", name: "Personal" },
  ];

  const formik = useFormik({
    initialValues: {
      name: "",
      customerContact: "",
      customerId: "",
      typeOfBusiness: "",
      vehicleNumber: "",
      vehicleName: "",
    },
    onSubmit: (values) => {
      //
    },
  });

  useEffect(() => {
    getAllCustomer();
  }, []);

  useEffect(() => {
    if (isNotEmptyObj(customersList)) {
      setCustomers(customersList);
    }
  }, [customersList]);

  const handleChange = (
    type: string,
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    formik.setFieldValue(type, event.target.value);
  };

  const handleCustomerType = (value: string): void => {
    const selectedBusinessType = typesOfBusiness.find(
      (item) => item.code === value
    );
    if (selectedBusinessType)
      formik.setFieldValue("typeOfBusiness", selectedBusinessType.name);
  };

  const addVehicle = (): void => {
    setVehiclesList([
      ...vehicleList,
      {
        id: Math.random().toString(36).substring(2, 7),
        name: formik.values.vehicleName,
        number: formik.values.vehicleNumber,
      },
    ]);
    formik.setFieldValue("vehicleName", "");
    formik.setFieldValue("vehicleNumber", "");
  };

  const deleteVehicle = (id: string): void => {
    const filteredList = vehicleList.filter((item) => item.id !== id);
    setVehiclesList(filteredList);
  };

  const checkIfSubmissible = (): boolean => {
    if (
      formik.values.name !== "" &&
      formik.values.customerContact !== "" &&
      formik.values.typeOfBusiness !== "" &&
      vehicleList.length !== 0 &&
      isValidContact &&
      isValidCustomerName
    )
      return true;
    return false;
  };

  const createCustomer = (): void => {
    if (checkIfSubmissible()) {
      const createRequest: CreateCustomerRequestType = {
        name: formik.values.name,
        contact: formik.values.customerContact,
        vehicles: vehicleList,
        isBusiness: formik.values.typeOfBusiness === "Business",
      };
      callCreateCustomer(createRequest);
    }
  };

  const clearDataFromFields = (): void => {
    formik.resetForm();
    setVehiclesList([]);
  };

  const validateCustomerInputs = (
    type: string,
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    switch (type) {
      case "name": {
        const similarCustomer = customers?.find(
          (item) => item.customerName === event.target.value
        );

        if (similarCustomer) setIsValidCustomerName(false);
        else setIsValidCustomerName(true);
        break;
      }
      case "customerContact": {
        const similarCustomer = customers?.find(
          (item) => item.customerContact === event.target.value
        );
        if (similarCustomer) setIsValidContact(false);
        else setIsValidContact(true);
        break;
      }
    }
  };
  return (
    <div className="add-customer-modal">
      {showSuccessAlert && (
        <Snackbar
          open={showSuccessAlert}
          autoHideDuration={4000}
          onClose={(): void => setShowSuccessAlert(false)}
          message="Note archived"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            severity="success"
            onClose={(): void => setShowSuccessAlert(false)}
          >
            <AlertTitle>Success</AlertTitle>
            Successfully added customer
          </Alert>
        </Snackbar>
      )}
      {showErrorAlert && (
        <Snackbar
          open={showErrorAlert}
          autoHideDuration={4000}
          onClose={(): void => setShowErrorAlert(false)}
          message="Note archived"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            severity="error"
            onClose={(): void => setShowErrorAlert(false)}
          >
            <AlertTitle>Failed to add customer</AlertTitle>
            Error while creating customer. Please try again later
          </Alert>
        </Snackbar>
      )}
      <div className="add-customer-modal-container">
        <div className="add-customer-header is-flex is-align-items-center is-justify-content-space-between">
          Add Customer
          <IconButton onClick={(e): void => closeModal(e)}>
            <ClearIcon color="error" />
          </IconButton>
        </div>
        <div className="add-customer-form-container">
          <div className="add-customer-form">
            <div className="customer-input-fields">
              <TextField
                id="customer-name-sa"
                label="Customer Name"
                value={formik.values.name}
                fullWidth
                variant="outlined"
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  handleChange("name", e);
                  validateCustomerInputs("name", e);
                }}
                error={!isValidCustomerName}
                helperText={
                  isValidCustomerName ? "" : "Customer already exists"
                }
              />
            </div>
            <div className="customer-input-fields">
              <TextField
                id="customer-contact"
                label="Customer Contact"
                value={formik.values.customerContact}
                fullWidth
                variant="outlined"
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  handleChange("customerContact", e);
                  validateCustomerInputs("customerContact", e);
                }}
                error={!isValidContact}
                helperText={isValidContact ? "" : "Duplicate contact number"}
              />
            </div>

            <div className="customer-input-fields">
              <DropDownInput
                items={typesOfBusiness}
                value={formik.values.typeOfBusiness}
                placeholder="Select business type"
                name="business-name"
                onChange={handleCustomerType}
              />
            </div>
            <div className="vehicles-list-container">
              <div className="add-vehicle-title">Add customer vehicles</div>
              <div className="columns">
                <div className="column">
                  <TextField
                    id="vehicle-name"
                    label="Vehicle Name"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={formik.values.vehicleName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                      handleChange("vehicleName", e)
                    }
                  />
                </div>
                <div className="column">
                  <TextField
                    id="vehicle-number"
                    label="Vehicle Number"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={formik.values.vehicleNumber}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                      handleChange("vehicleNumber", e)
                    }
                  />
                </div>
                <div className="column  is-2 is-flex is-align-items-center">
                  <IconButton
                    onClick={addVehicle}
                    disabled={formik.values.vehicleName === ""}
                  >
                    <AddCircleIcon />
                  </IconButton>
                </div>
              </div>
              {vehicleList && vehicleList.length !== 0 && (
                <div className="vehicles-added-list">
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableBody>
                        {vehicleList.map((row) => (
                          <TableRow
                            key={row.id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row.name}
                            </TableCell>
                            <TableCell align="right">{row.number}</TableCell>
                            <TableCell align="right">
                              <IconButton
                                onClick={(): void => deleteVehicle(row.id)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
            </div>
            <div className="submit-reset-button-container is-flex is-justify-content-flex-end">
              <div className="reset-button">
                <Button
                  variant="contained"
                  onClick={(): void => {
                    clearDataFromFields();
                  }}
                  startIcon={<RestartAltIcon />}
                >
                  Reset
                </Button>
              </div>
              <div>
                <Button
                  variant="contained"
                  onClick={(): void => {
                    createCustomer();
                  }}
                  color="success"
                  disabled={!checkIfSubmissible()}
                >
                  Create customer
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCustomer;
