import { createTheme } from "@mui/system";
import { PaymentMode } from "../features/types/enumerables";

const SUCCESS_STATUS_CODES = [200, 201];
const FAILURE_STATUS_CODES = {
  400: 400,
  401: 401,
  403: 403,
  422: 422,
  409: 409,
};
const storageHelper = {
  USERID: "USERID",
  EMAIL: "EMAIL",
  NAME: "NAME",
  SESSIONTIMEOUT: "SESSIONTIMEOUT",
}; // TODO: has to move this to localStorageHelper file
const COOKIES = {
  AUTHTOKEN: "AUTHTOKEN",
};

const paymentModeList = [
  {
    code: "1",
    name: PaymentMode.CASH,
  },
  {
    code: "2",
    name: PaymentMode.CARD,
  },
  {
    code: "3",
    name: PaymentMode.UPI,
  },
];

const paymentModeListForAdmin = [
  {
    code: "1",
    name: PaymentMode.CASH,
  },
  {
    code: "2",
    name: PaymentMode.CARD,
  },
  {
    code: "3",
    name: PaymentMode.UPI,
  },
  {
    code: "4",
    name: PaymentMode.BANKTRANSFER,
  },
  {
    code: "5",
    name: PaymentMode.CHEQUE,
  },
];

const employeeList = [
  {
    code: "2",
    name: "Yeshoda",
  },
  {
    code: "8",
    name: "Anoop",
  },
  {
    code: "21",
    name: "Aswin",
  },
  {
    code: "22",
    name: "Aaromal",
  },
  {
    code: "23",
    name: "Ajith",
  },
  {
    code: "24",
    name: "Alwin",
  },
  {
    code: "25",
    name: "Sanu",
  },
  {
    code: "27",
    name: "Rony",
  },
  {
    code: "28",
    name: "Diyo",
  },
  {
    code: "29",
    name: "Athul",
  },
  {
    code: "30",
    name: "Nitheesh",
  },
  {
    code: "31",
    name: "Thomas",
  },
  {
    code: "16",
    name: "Ananthu",
  },
  {
    code: "32",
    name: "Alan",
  },
  {
    code: "33",
    name: "Nakul",
  },
  {
    code: "34",
    name: "Athul K B",
  },
];

const employeeListToShow = [
  {
    code: "1",
    name: "Muneer",
  },
  {
    code: "2",
    name: "Yeshoda",
  },
  {
    code: "3",
    name: "Pappachan",
  },
  {
    code: "4",
    name: "Vipin",
  },
  {
    code: "5",
    name: "Sreedevi",
  },
  {
    code: "6",
    name: "Binda",
  },
  {
    code: "7",
    name: "Aswathy",
  },
  {
    code: "8",
    name: "Anoop",
  },
  {
    code: "9",
    name: "Joshy",
  },
  {
    code: "ADMIN",
    name: "Admin",
  },
  {
    code: "10",
    name: "Vishwa",
  },
  {
    code: "11",
    name: "Jijimol",
  },
  {
    code: "12",
    name: "Swalih",
  },
  {
    code: "13",
    name: "Noufal",
  },
  {
    code: "14",
    name: "Nizam",
  },
  {
    code: "15",
    name: "Basith",
  },
  {
    code: "16",
    name: "Ananthu",
  },
  {
    code: "17",
    name: "Abay",
  },
  {
    code: "18",
    name: "Sabu",
  },
  {
    code: "19",
    name: "Ali",
  },
  {
    code: "20",
    name: "Akshay",
  },
  {
    code: "21",
    name: "Aswin",
  },
  {
    code: "22",
    name: "Aaromal",
  },
  {
    code: "23",
    name: "Ajith",
  },
  {
    code: "24",
    name: "Alwin",
  },
  {
    code: "25",
    name: "Sanu",
  },
  {
    code: "26",
    name: "Sabari",
  },
  {
    code: "27",
    name: "Rony",
  },
  {
    code: "28",
    name: "Diyo",
  },
  {
    code: "29",
    name: "Athul",
  },
  {
    code: "30",
    name: "Nitheesh",
  },
  {
    code: "31",
    name: "Thomas",
  },
  {
    code: "32",
    name: "Alan",
  },
  {
    code: "33",
    name: "Nakul",
  },
  {
    code: "34",
    name: "Athul K B",
  },
];

const expenseTypeList = [
  {
    code: "SALARY",
    name: "Salary",
  },
  {
    code: "OWNERS_DRAWINGS",
    name: "Owners Drawings",
  },
  {
    code: "MISCELLANEOUS",
    name: "Miscellaneous",
  },
  {
    code: "DISCOUNT",
    name: "Discount",
  },
  {
    code: "MS_HSD_PURCHASE",
    name: "MS HSD Purchase",
  },
  {
    code: "LUBES_GAS_PURCHASE",
    name: "Lub and Gas Purchase",
  },
  {
    code: "BANK_EXPENSES",
    name: "Bank Expenses",
  },
];

const apiEndPoints = {
  login: "/authenticate",
  getCustomerList: "/getCustomers",
  createCustomer: "/createCustomer",
  getProductList: "/getProducts",
  createDailySale: "/createDailySalesRecord",
  fetchTodaysPrice: "/todaysPrice",
  saveTodaysPrice: "/saveTodaysPrice",
  fetchDailySales: "/fetchDailySales",
  getSalesList: "/getSalesList",
  getCustomersList: "/customers/list",
  getCustomerDetails: "/customers/details",
  getCustomerCredits: "/customers/credits",
  getCustomerPayments: "/customers/payments",
  addCustometPayment: "/customers/add/payment",
  loadDashboard: "/loadDashboard",
  downloadClosingReport: "/loadDashboard/dailyClosingReport",
  addStartingReadings: "/readings/starting",
  addClosingReadings: "/readings/closing",
  addSettlements: "/addSettlements",
  getEmployeeDetails: "/employee/getAttendance",
  getEmployeeRecords: "/employee/getRecords",
  addAttendance: "/employee/addAttendance",
  getStatement: "/customer/statements",
  deleteDailySalesRecord: "/delete/dailySalesRecord",
  expenses: "/reports/expenses",
  addExpenses: "/expenses",
  profitLoss: "/reports/profitLoss",
};

const FormSubmitRefEmptyObject = {
  current: {
    handleFormSubmit: (): void => {
      //
    },
  },
};

export {
  SUCCESS_STATUS_CODES,
  FAILURE_STATUS_CODES,
  apiEndPoints,
  storageHelper,
  COOKIES,
  FormSubmitRefEmptyObject,
  paymentModeList,
  employeeList,
  expenseTypeList,
  employeeListToShow,
  paymentModeListForAdmin,
};
