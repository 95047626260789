import { useState } from "react";
import { createStore } from "reusable";
import { ResponseType } from "../../utils/types";
import {
  EmployeeSalesListRequestType,
  EmployeeSalesListResponseType,
} from "../types/types";
import SalesListService from "./Service";

const EmployeeStore = createStore(() => {
  const [employeeSaleListResp, setEmployeeSaleListResp] =
    useState<EmployeeSalesListResponseType>();

  const [employeeSaleListRespError, setEmployeeSaleListRespError] =
    useState<ResponseType<EmployeeSalesListResponseType>>();

  const clearEmployeeSalesList = (): void => {
    setEmployeeSaleListResp({} as EmployeeSalesListResponseType);
    setEmployeeSaleListRespError(
      {} as ResponseType<EmployeeSalesListResponseType>
    );
  };

  const getEmployeeSaleList = (payload: EmployeeSalesListRequestType): void => {
    SalesListService.getEmployeeSalesList(payload)
      .then((response) => {
        setEmployeeSaleListResp(response.data);
      })
      .catch((error) => {
        setEmployeeSaleListRespError(error);
      });
  };

  return {
    employeeSaleListResp,
    employeeSaleListRespError,
    getEmployeeSaleList,
    clearEmployeeSalesList,
  };
});

export default EmployeeStore;
