import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";
import "./index.scss";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import AddAttendanceModal from "./AddAttendanceModal";

interface OptionButtonsProps {
  name: string;
  icon: React.ReactNode;
  handleClick: () => void;
}

const OptionButtons = (props: OptionButtonsProps): JSX.Element => {
  const { name, icon, handleClick } = props;

  return (
    <div className="options-button" onClick={handleClick}>
      <div>{icon}</div>
      <div className="option-name">{name}</div>
    </div>
  );
};

const SaveRecordContent = (): JSX.Element => {
  const navigate = useNavigate();
  const [showAttendanceModal, setShowAttendanceModal] = useState(false);
  return (
    <>
      {showAttendanceModal && (
        <AddAttendanceModal
          closeModal={(): void => setShowAttendanceModal(false)}
        />
      )}
      <div className="save-records-container is-flex is-justify-content-center is-align-items-center">
        <div className="">
          <div className="heading-3 headers-save">Save Records</div>
          <div className="save-recods-options flex flex-grow">
            <div className="options-container">
              <OptionButtons
                name="Closing Sheet"
                icon={<ReceiptLongOutlinedIcon sx={{ fontSize: 100 }} />}
                handleClick={(): void => navigate("/addSales")}
              />
            </div>
            <div className="options-container">
              <OptionButtons
                name="Settlements"
                icon={<AccountBalanceWalletIcon sx={{ fontSize: 100 }} />}
                handleClick={(): void => {}}
              />
            </div>
            <div className="options-container">
              <OptionButtons
                name="Attendence"
                icon={<MoreTimeIcon sx={{ fontSize: 100 }} />}
                handleClick={(): void => {
                  setShowAttendanceModal(true);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const SaveRecords: React.FunctionComponent = () => {
  return (
    <div>
      <Layout content={<SaveRecordContent />} />
    </div>
  );
};

export default SaveRecords;
