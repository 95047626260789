/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Modal from "../../components/Modal";
import "./index.scss";
import ClearIcon from "@mui/icons-material/Clear";
import {
  IconButton,
  TextField,
  Button,
  Alert,
  Snackbar,
  AlertTitle,
} from "@mui/material";
import { isNotEmptyObj, stripZerosAndAlpha } from "../../utils/helper";
import AddSalesState from "./Store";

interface Props {
  closeModal: () => void;
  date?: Date;
}

const AddPriceModal = (props: Props): JSX.Element => {
  const { closeModal, date } = props;
  const [msPrice, setMsPrice] = useState("");
  const [hsdPrice, setHsdPrice] = useState("");
  // const [isPriceEditable, setIsPriceEditable] = useState(true);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const {
    saveTodaysPrice,
    savePriceResponse,
    savePriceResponseError,
    clearSavePriceResponses,
    getProductList,
  } = AddSalesState();

  const handlePriceChange = (
    type: string,
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    let input = stripZerosAndAlpha(event.target.value).replace(
      /^(\d+.?\d{0,2})\d*$/,
      "$1"
    );
    if (type === "ms") setMsPrice(input);
    if (type === "hsd") setHsdPrice(input);
  };

  useEffect(() => {
    if (isNotEmptyObj(savePriceResponse)) {
      setShowSuccessAlert(true);
      setTimeout(() => {
        closeModal();
      }, 2000);
      clearSavePriceResponses();
      getProductList(date || new Date());
    }
    if (isNotEmptyObj(savePriceResponseError)) {
      setShowErrorAlert(true);
      setMsPrice("");
      setHsdPrice("");
      clearSavePriceResponses();
    }
  }, [savePriceResponse, savePriceResponseError]);

  const savePrice = (): void => {
    const saveDate = date ? date : new Date();
    const payload = {
      date: saveDate.toLocaleDateString(),
      msPrice: msPrice,
      hsdPrice: hsdPrice,
    };
    saveTodaysPrice(payload);
  };
  return (
    <div className="add-price-modal">
      {showSuccessAlert && (
        <Snackbar
          open={showSuccessAlert}
          autoHideDuration={4000}
          onClose={(): void => setShowSuccessAlert(false)}
          message="Note archived"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            severity="success"
            onClose={(): void => setShowSuccessAlert(false)}
            className="sucess-alert"
          >
            <AlertTitle>Success</AlertTitle>
            Successfully added prices
          </Alert>
        </Snackbar>
      )}
      {showErrorAlert && (
        <Snackbar
          open={showErrorAlert}
          autoHideDuration={4000}
          onClose={(): void => setShowErrorAlert(false)}
          message="Note archived"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            severity="error"
            onClose={(): void => setShowErrorAlert(false)}
            className="sucess-alert"
          >
            <AlertTitle>Error</AlertTitle>
            Couldn't add prices. Please try again.
          </Alert>
        </Snackbar>
      )}
      <div className="add-price-modal-container">
        <div className="add-price-header is-flex is-align-items-center is-justify-content-space-between">
          {`Add Price for ${date ? date.toLocaleDateString() : "Today"}`}
          <IconButton onClick={(): void => closeModal()}>
            <ClearIcon color="error" />
          </IconButton>
        </div>

        <div className="add-price-form-container">
          <div className="add-price-form">
            <div className="columns">
              <div className="column">
                <div className="customer-input-fields">
                  <TextField
                    type="number"
                    id="ms-price-input"
                    label="MS Price"
                    fullWidth
                    variant="outlined"
                    value={msPrice}
                    // disabled={!isPriceEditable}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                      handlePriceChange("ms", e)
                    }
                  />
                </div>
              </div>
              <div className="column">
                <div className="customer-input-fields">
                  <TextField
                    type="number"
                    id="hsd-price-input"
                    label="HSD Price"
                    fullWidth
                    variant="outlined"
                    // disabled={!isPriceEditable}
                    value={hsdPrice}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                      handlePriceChange("hsd", e)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="submit-reset-button-container is-flex is-justify-content-flex-end">
          <div>
            <Button
              variant="contained"
              onClick={(): void => {
                savePrice();
              }}
              color="success"
            >
              Save Price
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const PriceModal = (props: Props): JSX.Element => {
  const { closeModal, date } = props;
  const [show, setShow] = useState(true);
  return (
    <Modal
      show={show}
      closeModal={(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        setShow(false);
        // closeModal(e);
      }}
      content={<AddPriceModal closeModal={closeModal} date={date} />}
      persistent
    />
  );
};

export default PriceModal;
