/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { useFormik } from "formik";
import {
  TextField,
  Button,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Snackbar,
  Alert,
  AlertTitle,
} from "@mui/material";
import TimePicker from "@mui/lab/TimePicker";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import "./index.scss";
import Modal from "../../components/Modal";
import ADDICON from "../../images/add-button.svg";
import {
  ExpenseType,
  PaymentsRecievedType,
  CreateDailySaleRecordRequestType,
  CreditSalesType,
} from "../types/types";
import AddSalesState from "../AddSaleDetails/Store";
import { employeeList, expenseTypeList } from "../../utils/appConstants";
import DropDownInput from "../../components/DropdownInput";
import { AccountCircle } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import {
  isNotEmptyObj,
  parseDateFrom,
  parseDateTimeFrom,
} from "../../utils/helper";
import * as LocalStorageHelper from "../../utils/localStorageHelper";
import { useNavigate } from "react-router-dom";
import { NozzleReadings } from "../AddSaleDetails/types";

const SalesPreviewContent = (): JSX.Element => {
  const preview = window.location.href.split("/")[4];
  const employeeId = window.location.href.split("/")[5];
  const sheetId = window.location.href.split("/")[6];

  const navigate = useNavigate();

  const now = new Date();
  const {
    getAllCustomer,
    fetchDailySalesRecord,
    fetchDailySalesResponse,
    fetchDailySalesRespError,
    clearDailySalesResponse,
    deleteDailySalesRecord,
    deleteSalesRecord,
    deleteSalesRecordError,
    clearDeleteSalesRecordResponses,
  } = AddSalesState();

  const [isPreview, setIsPreview] = useState(false);

  const [cashTotal, setCashTotal] = useState<number>(0);
  const [totalReceivables, setTotalReceivables] = useState<number>(0);
  const [totalRecieved, setTotalRecieved] = useState<number>(0);
  const [showTotalModal, setShowTotalModal] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showDeleteErrorAlert, setShowDeleteErrorAlert] = useState(false);
  const [showExpenseForm, setShowExpenseForm] = useState(false);
  const [showRecievedForm, setShowRecievedForm] = useState(false);
  const [expenseList, setExpenseList] = useState<ExpenseType[]>([]);
  const [date, setDate] = useState<Date | null>(new Date());
  const [currentSheetId, setCurrentSheetId] = useState("");
  const [startTime, setStartTime] = useState<Date | null>(
    new Date(now.setHours(6, 0, 0))
  );
  const [nozzleReadings, setNozzleReadings] = useState<NozzleReadings[]>([]);

  const [endTime, setEndTime] = useState<Date | null>(
    new Date(new Date().setMinutes(0, 0))
  );

  const [creditSalesList, setCreditSalesList] = useState<CreditSalesType[]>([]);

  const [paymentRecievedList, setPaymentRecievedList] = useState<
    PaymentsRecievedType[]
  >([]);

  const [diff, setDiff] = useState<number>(0);

  const formik = useFormik({
    initialValues: {
      employeeId: "",
      employeeName: "",
      startTime: new Date(now.setHours(6, 0, 0)).toLocaleString("en-IN") || "",
      endTime: now.toLocaleString("en-IN"),
      date: now.toLocaleDateString(),
      cash2000: "",
      cash500: "",
      cash100: "",
      cash200: "",
      cash50: "",
      cash20: "",
      cash10: "",
      coins: "",
      advancePaid: "",
      cardSales: "",
      upiSales: "",
      cashTotal: "",
      testMs: "",
      testHsd: "",
      expenseDescription: "",
      expenseAmount: "",
      expenseType: "",
      customerId: "",
      customerName: "",
      amount: "",
      paymentMode: "",
    },
    onSubmit: () => {
      console.log("");
    },
  });

  const updateTotalCash = (): void => {
    let total = 0;
    total += Number(formik.values.cash2000) * 2000;
    total += Number(formik.values.cash500) * 500;
    total += Number(formik.values.cash200) * 200;
    total += Number(formik.values.cash100) * 100;
    total += Number(formik.values.cash50) * 50;
    total += Number(formik.values.cash20) * 20;
    total += Number(formik.values.cash10) * 10;
    total += Number(formik.values.coins);
    total += Number(formik.values.advancePaid);
    setCashTotal(total);
  };

  useEffect(() => {
    if (preview && employeeId && sheetId) {
      setIsPreview(true);
      const payload = {
        employeeId: employeeId,
        sheetId: sheetId,
      };
      fetchDailySalesRecord(payload);
    }
  }, []);

  useEffect(() => {
    if (isNotEmptyObj(deleteSalesRecord)) {
      setShowSuccessAlert(true);
      clearDeleteSalesRecordResponses();
      setTimeout(() => {
        navigate("/dashboard");
      }, 2000);
    }
    if (deleteSalesRecordError) {
    }
  }, [deleteSalesRecord, deleteSalesRecordError]);

  useEffect(() => {
    updateTotalCash();
  }, [
    formik.values.cash2000,
    formik.values.cash500,
    formik.values.cash200,
    formik.values.cash100,
    formik.values.cash50,
    formik.values.cash20,
    formik.values.cash10,
    formik.values.coins,
    formik.values.advancePaid,
  ]);

  useEffect(() => {
    if (isNotEmptyObj(fetchDailySalesResponse)) {
      const {
        employeeId,
        employeeName,
        startTime: previewStartTime,
        endTime: previewEndTime,
        date: previewDate,
        nozzleReadings: nozzleReads,
        cash2000,
        cash500,
        cash100,
        cash200,
        cash50,
        cash20,
        cash10,
        coins,
        advancePaid,
        cardSales,
        upiSales,
        testMs,
        testHsd,
        creditSalesRequestList: previewCreditList,
        expenseRequestList: previewExpenseList,
        paymentReceivedRequestDTO: previewReceivedList,
        totalAmountReceived: previewTotalAmontReceived,
        totalAmountReceivable: previewTotalReceivables,
        diff: previewDiff,
      } = fetchDailySalesResponse as CreateDailySaleRecordRequestType;
      formik.setFieldValue("employeeId", employeeId);
      formik.setFieldValue("employeeName", employeeName);
      formik.setFieldValue("cash2000", cash2000);
      formik.setFieldValue("cash500", cash500);
      formik.setFieldValue("cash100", cash100);
      formik.setFieldValue("cash200", cash200);
      formik.setFieldValue("cash50", cash50);
      formik.setFieldValue("cash20", cash20);
      formik.setFieldValue("cash10", cash10);
      formik.setFieldValue("coins", coins);
      formik.setFieldValue("advancePaid", advancePaid);
      formik.setFieldValue("cardSales", cardSales);
      formik.setFieldValue("upiSales", upiSales);
      formik.setFieldValue("testMs", testMs);
      formik.setFieldValue("testHsd", testHsd);
      setCreditSalesList(previewCreditList);
      setExpenseList(previewExpenseList);
      setPaymentRecievedList(previewReceivedList);
      setTotalReceivables(Number(previewTotalReceivables));
      setTotalRecieved(Number(previewTotalAmontReceived));
      setDiff(Number(previewDiff));
      setDate(parseDateFrom(previewDate));
      setStartTime(parseDateTimeFrom(previewStartTime));
      setEndTime(parseDateTimeFrom(previewEndTime));
      setNozzleReadings(nozzleReads);
      clearDailySalesResponse();
    }
    if (isNotEmptyObj(fetchDailySalesRespError)) {
      setShowErrorAlert(true);
      clearDailySalesResponse();
    }
  }, [fetchDailySalesResponse, fetchDailySalesRespError]);

  const renderTotalModal = (): JSX.Element => {
    return (
      <div className="total-modal-container">
        <div className="total-container is-flex is-align-items-center">
          <div className="total-title">Total Receivable Amount: </div>
          <div className="total-amount">
            {totalReceivables.toLocaleString("en-IN")}
          </div>
        </div>
        <div className="total-container is-flex is-align-items-center">
          <div className="total-title">Total Amount Received: </div>
          <div className="total-amount">
            {totalRecieved.toLocaleString("en-IN")}
          </div>
        </div>
        <div className="total-container is-flex is-align-items-center">
          <div className="total-title">Difference: </div>
          <div className={`total-amount ${diff > 0 ? "redded" : "greened"}`}>
            {diff.toLocaleString("en-IN")}
          </div>
        </div>
      </div>
    );
  };

  const getExpenseType = (value: string): string => {
    const expense = expenseTypeList.find((item) => item.code === value);
    return expense ? expense.name : "";
  };

  return (
    <>
      <Modal
        show={showTotalModal}
        content={renderTotalModal()}
        closeModal={(): void => setShowTotalModal(false)}
        contentClassName="total-modal-content"
      />
      {showErrorAlert && (
        <Snackbar
          open={showErrorAlert}
          autoHideDuration={4000}
          onClose={(): void => setShowErrorAlert(false)}
          message="Note archived"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            severity="error"
            onClose={(): void => setShowErrorAlert(false)}
          >
            <AlertTitle>Failed to load the record</AlertTitle>
            {`${
              isNotEmptyObj(fetchDailySalesRespError)
                ? fetchDailySalesRespError?.error
                : "Error"
            }  occured during the process. Please try again.`}
          </Alert>
        </Snackbar>
      )}
      {showSuccessAlert && (
        <Snackbar
          open={showSuccessAlert}
          autoHideDuration={4000}
          onClose={(): void => setShowSuccessAlert(false)}
          message="Note archived"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            severity="success"
            onClose={(): void => setShowSuccessAlert(false)}
          >
            <AlertTitle>Success</AlertTitle>
            Successfully deleted the record.
          </Alert>
        </Snackbar>
      )}

      {showDeleteErrorAlert && (
        <Snackbar
          open={showDeleteErrorAlert}
          autoHideDuration={4000}
          onClose={(): void => setShowDeleteErrorAlert(false)}
          message="Note archived"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            severity="error"
            onClose={(): void => setShowDeleteErrorAlert(false)}
          >
            <AlertTitle>Error</AlertTitle>
            {`Couldn't delete sales record. ${deleteSalesRecordError?.message}`}
          </Alert>
        </Snackbar>
      )}

      <div className="">
        <div className="add-sales-details">
          <div className="add-details-header-container is-flex is-justify-content-space-between">
            <div className="add-details-header">Daily Sales Record</div>
            <div className="add-details-button-container is-flex is-justify-content-flex-end">
              <div className="button-container">
                <Button
                  variant="outlined"
                  onClick={(): void => {
                    // calculateTotal();
                    setShowTotalModal(true);
                  }}
                >
                  Show Total
                </Button>
              </div>
              {LocalStorageHelper.getItem(LocalStorageHelper.KEYS.ROLE) ===
                "ADMIN" && (
                <div className="button-container">
                  <Button
                    variant="contained"
                    onClick={(): void => {
                      // calculateTotal();
                      deleteDailySalesRecord({
                        sheetId: sheetId,
                      });
                    }}
                    color="error"
                  >
                    Delete Record
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <DropDownInput
                value={formik.values.employeeName}
                items={employeeList}
                placeholder="Select Person"
                name="select-person-name"
                width={300}
                icon={<AccountCircle />}
              />
            </div>
          </div>
          <div className="columns">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <div className="column">
                <DesktopDatePicker
                  value={date}
                  label="Date desktop"
                  inputFormat="dd/MM/yyyy"
                  renderInput={(params: any) => <TextField {...params} />}
                  onChange={(): void => {}}
                  disabled
                />
              </div>
              <div className="column">
                <TimePicker
                  value={startTime}
                  label="Start Time"
                  renderInput={(params: any) => <TextField {...params} />}
                  onChange={(): void => {}}
                  disabled
                  views={["hours"]}
                />
              </div>
              <div className="column">
                <TimePicker
                  value={endTime}
                  label="End Time"
                  renderInput={(params: any) => <TextField {...params} />}
                  onChange={(): void => {}}
                  disabled
                  views={["hours"]}
                />
              </div>
            </LocalizationProvider>
          </div>

          <div className="sale-details-form-container">
            <div className="nozzle-reading-header">Nozzle Readings</div>
            <div>
              {nozzleReadings &&
                nozzleReadings.map((item, index) => (
                  <div className="columns">
                    <div className="column">
                      <TextField
                        type="number"
                        id="daily-starting-reading-1"
                        label={`Starting Reading Nozzle ${index + 1}`}
                        fullWidth
                        value={item.starting}
                        variant="outlined"
                      />
                    </div>
                    <div className="column">
                      <TextField
                        type="number"
                        id="daily-starting-reading-1"
                        label={`Closing Reading Nozzle ${index + 1}`}
                        fullWidth
                        value={item.closing}
                        variant="outlined"
                      />
                    </div>
                    <div className="column">
                      <TextField
                        type="text"
                        id="daily-starting-reading-1"
                        label="Product"
                        fullWidth
                        value={item.product}
                        variant="outlined"
                      />
                    </div>
                  </div>
                ))}
            </div>
            <div className="columns">
              <div className="column is-5 is-flex is-justify-content-center">
                <div>
                  <div className="cash-header text-align-center">
                    Cash Denominations
                  </div>
                  <div className="is-flex is-align-items-baseline">
                    <div className="cash-denomination">2000: </div>
                    <TextField
                      type="number"
                      className="denomination-input"
                      id="daily-starting-reading-1"
                      sx={{ width: 80 }}
                      label="2000"
                      value={formik.values.cash2000}
                      variant="standard"
                      size="small"
                    />
                  </div>
                  <div className="is-flex is-align-items-baseline">
                    <div className="cash-denomination">500: </div>
                    <TextField
                      type="number"
                      className="denomination-input"
                      id="daily-starting-reading-1"
                      sx={{ width: 80 }}
                      value={formik.values.cash500}
                      label="500"
                      variant="standard"
                      size="small"
                    />
                  </div>
                  <div className="is-flex is-align-items-baseline">
                    <div className="cash-denomination">200: </div>
                    <TextField
                      type="number"
                      className="denomination-input"
                      id="daily-starting-reading-1"
                      sx={{ width: 80 }}
                      value={formik.values.cash200}
                      label="200"
                      variant="standard"
                      size="small"
                    />
                  </div>
                  <div className="is-flex is-align-items-baseline">
                    <div className="cash-denomination">100: </div>
                    <TextField
                      type="number"
                      className="denomination-input"
                      id="daily-starting-reading-1"
                      sx={{ width: 80 }}
                      value={formik.values.cash100}
                      label="100"
                      variant="standard"
                      size="small"
                    />
                  </div>
                  <div className="is-flex is-align-items-baseline">
                    <div className="cash-denomination">50: </div>
                    <TextField
                      type="number"
                      className="denomination-input"
                      id="daily-starting-reading-1"
                      sx={{ width: 80 }}
                      value={formik.values.cash50}
                      label="50"
                      variant="standard"
                      size="small"
                    />
                  </div>
                  <div className="is-flex is-align-items-baseline">
                    <div className="cash-denomination">20: </div>
                    <TextField
                      type="number"
                      className="denomination-input"
                      id="daily-starting-reading-1"
                      sx={{ width: 80 }}
                      value={formik.values.cash20}
                      label="20"
                      variant="standard"
                      size="small"
                    />
                  </div>
                  <div className="is-flex is-align-items-baseline">
                    <div className="cash-denomination">10: </div>
                    <TextField
                      type="number"
                      className="denomination-input"
                      id="daily-starting-reading-1"
                      sx={{ width: 80 }}
                      value={formik.values.cash10}
                      label="10"
                      variant="standard"
                      size="small"
                    />
                  </div>
                  <div className="is-flex is-align-items-baseline">
                    <div className="cash-denomination">Coins: </div>
                    <TextField
                      type="number"
                      className="denomination-input"
                      id="daily-starting-reading-1"
                      sx={{ width: 80, textAlign: "center" }}
                      value={formik.values.coins}
                      label="Coins"
                      variant="standard"
                      size="small"
                    />
                  </div>
                  <div className="is-flex is-align-items-baseline">
                    <div className="cash-denomination">Advance Paid: </div>
                    <TextField
                      type="number"
                      className="denomination-input"
                      id="daily-starting-reading-1"
                      sx={{ width: 120, textAlign: "center" }}
                      value={formik.values.advancePaid}
                      label="Advance Paid"
                      variant="standard"
                      size="small"
                    />
                  </div>
                  {cashTotal !== 0 && (
                    <div className="cash-total-container is-flex is-align-items-center">
                      <div className="cash-total-title">Cash Total: </div>
                      <div className="cash-total-amount">
                        {cashTotal.toLocaleString("en-IN")}
                      </div>
                    </div>
                  )}
                  <div className="payment-details-container is-flex is-align-items-center is-justify-content-center">
                    <div className="payment-details">Card Sales</div>
                    <TextField
                      type="number"
                      id="daily-starting-reading-1"
                      label="Card Sales"
                      variant="outlined"
                      value={formik.values.cardSales}
                      sx={{ width: 160, textAlign: "center" }}
                    />
                  </div>
                  <div className="payment-details-container is-flex is-align-items-center is-justify-content-center">
                    <div className="payment-details">UPI Sales</div>
                    <TextField
                      type="number"
                      id="daily-starting-reading-1"
                      label="UPI Sales"
                      variant="outlined"
                      value={formik.values.upiSales}
                      sx={{ width: 160, textAlign: "center" }}
                    />
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="credit-sales-header-container is-flex is-justify-content-space-between is-align-items-center">
                  <div className="credit-sale-title">Pump Test</div>
                </div>
                {(formik.values.testMs || formik.values.testHsd) && (
                  <div className="test-form-container">
                    <div className="columns">
                      <div className="column is-flex is-justify-content-center">
                        <TextField
                          type="number"
                          id="test-ms"
                          label="Test MS Amount"
                          variant="outlined"
                          value={formik.values.testMs}
                          sx={{ width: 300 }}
                        />
                      </div>
                      <div className="column is-flex is-justify-content-center">
                        <TextField
                          type="number"
                          id="test-hsd"
                          label="Test HSD Amount"
                          variant="outlined"
                          value={formik.values.testHsd}
                          sx={{ width: 300 }}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="credit-sales-header-container is-flex is-justify-content-space-between is-align-items-center">
                  <div className="credit-sale-title">Credit Sale</div>
                </div>
                <div className="credit-list-table">
                  {creditSalesList.length > 0 && (
                    <TableContainer>
                      <Table
                        sx={{ minWidth: 600, fontFamily: "Roboto" }}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>Sl.No</TableCell>
                            <TableCell align="right">Customer Name</TableCell>
                            <TableCell align="right">Product</TableCell>
                            <TableCell align="right">Quantity</TableCell>
                            <TableCell align="right">Amount</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {creditSalesList.map((row, index) => (
                            <TableRow
                              key={row.name}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {index + 1}
                              </TableCell>
                              <TableCell scope="row">{row.name}</TableCell>
                              <TableCell align="right">{row.product}</TableCell>
                              <TableCell scope="row">{row.quantity}</TableCell>
                              <TableCell scope="row">{row.amount}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </div>
                <div className="expense-header-container is-flex is-justify-content-space-between">
                  <div className="expense-header">Expenses</div>
                  {!isPreview && (
                    <Button
                      variant="outlined"
                      onClick={(): void => {
                        setShowExpenseForm(!showExpenseForm);
                      }}
                      startIcon={<img src={ADDICON} alt="add-button" />}
                    >
                      Add Expense
                    </Button>
                  )}
                </div>
                {expenseList.length !== 0 && (
                  <>
                    <div className="expense-list-title">Expenses List</div>
                    <TableContainer>
                      <Table
                        sx={{ minWidth: 600, fontFamily: "Roboto" }}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell align="right">Sl.No</TableCell>
                            <TableCell align="right">Description</TableCell>
                            <TableCell align="right">Type</TableCell>
                            <TableCell align="right">Amount</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {expenseList.map((row, index) => (
                            <TableRow
                              key={row.expenseId}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                align="right"
                              >
                                {index + 1}
                              </TableCell>
                              <TableCell align="right">
                                {row.description}
                              </TableCell>
                              <TableCell align="right">
                                {getExpenseType(row.expenseType)}
                              </TableCell>
                              <TableCell align="right">{row.amount}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
                <div className="expense-header-container is-flex is-justify-content-space-between">
                  <div className="expense-header">Payments Recieved</div>
                  {!isPreview && (
                    <Button
                      variant="outlined"
                      onClick={(): void => {
                        setShowRecievedForm(!showRecievedForm);
                        getAllCustomer();
                      }}
                      startIcon={<img src={ADDICON} alt="add-button" />}
                    >
                      Add Payment
                    </Button>
                  )}
                </div>
                {paymentRecievedList.length !== 0 && (
                  <>
                    <div className="expense-list-title">
                      Payments Received List
                    </div>
                    <TableContainer>
                      <Table
                        sx={{ minWidth: 600, fontFamily: "Roboto" }}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell align="right">Sl.No</TableCell>
                            <TableCell align="right">Customer</TableCell>
                            <TableCell align="right">Amount</TableCell>
                            <TableCell align="right">Payment Mode</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {paymentRecievedList.map((row, index) => (
                            <TableRow
                              key={row.paymentId}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                align="right"
                              >
                                {index + 1}
                              </TableCell>
                              <TableCell align="right">
                                {row.customerName}
                              </TableCell>
                              <TableCell align="right">{row.amount}</TableCell>
                              <TableCell align="right">
                                {row.paymentMode}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const SalesPreview: React.FunctionComponent = () => {
  return (
    <div>
      <Layout content={<SalesPreviewContent />} />
    </div>
  );
};

export default SalesPreview;
