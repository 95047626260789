import React from 'react';
import ReactDOM from 'react-dom';
import "./styles.css";
import "./bulma_config.scss";
import "./css/index.scss";
import "./utils/i18n";
import { ReusableProvider } from "reusable";
import Router from "./Router";
import * as serviceWorker from "./serviceWorker";
import { useAxiosLoader } from "./utils/api";
import { WindMillLoading } from "react-loadingg";
import { RectGraduallyShowLoading } from "react-loadingg";

const LoadingProgress = (): JSX.Element => {
  return (
    <>
      {useAxiosLoader() && (
        <>
          <div className="loading-animation-container">
            <div className="animation-box">
              <div className="animation-content">
                <WindMillLoading
                  color="#1976d2"
                  size="large"
                  speed={1}
                  style={{ margin: "auto", position: "absolute", inset: 0 }}
                />
                <RectGraduallyShowLoading
                  color="#1976d2"
                  size="large"
                  speed={1}
                  style={{
                    margin: "auto",
                    position: "absolute",
                    inset: 0,
                    top: 110,
                    height: 5,
                    width: 90,
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
// Todo: have to take up for session management
// const PasswordLogin = (): JSX.Element => {
//   return <PasswordLoginForm show={useUnauthorised()} />;
// };

ReactDOM.render(
  <ReusableProvider>
    <LoadingProgress />
    {/* <PasswordLogin /> */}

    <Router />
  </ReusableProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
