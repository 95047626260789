import React from "react";
import {
  Routes,
  Route,
  BrowserRouter as Router,
  Outlet,
  Navigate,
  useLocation,
} from "react-router-dom";

import Login from "./features/Login";
import NotFound from "./components/Error";
import Dashboard from "./features/Dashboard";
import AddSalesDetails from "./features/AddSaleDetails";
import SalesPreview from "./features/SalesPreview";
import SalesList from "./features/SalesList";
import CustomersList from "./features/Customers";
import CustomerDetails from "./features/Customers/CustomerDetails";
import ErrorRoute from "./features/404";
import { CONSTVALS, getItem, KEYS } from "./utils/localStorageHelper";
import SaveRecords from "./features/SaveRecords";
import Employees from "./features/Employees";
import Reports from "./features/Reports";
import Expenses from "./features/Reports/Expenses";
import ProfitLoss from "./features/Reports/ProfitLoss";

interface PrivateRouteProps {
  element?: JSX.Element | null;
}

const privateRoutesRegistry = [
  {
    path: "/dashboard",
    element: <Dashboard />,
    index: false,
  },
  {
    path: "/salesList",
    element: <SalesList />,
    index: false,
  },
  {
    path: "/customers",
    element: <CustomersList />,
    index: false,
  },
  {
    path: "/customerDetails/*",
    element: <CustomerDetails />,
    index: false,
  },
  {
    path: "/addSales",
    element: <AddSalesDetails />,
    index: false,
  },
  {
    path: "/saveRecords",
    element: <SaveRecords />,
    index: false,
  },
  {
    path: "/sales/preview/*",
    element: <SalesPreview />,
    index: false,
  },
  {
    path: "/employees",
    element: <Employees />,
    index: false,
  },
  {
    path: "/reports",
    element: <Reports />,
    index: false,
  },
  {
    path: "/expenses",
    element: <Expenses />,
    index: false,
  },
  {
    path: "/profit-loss",
    element: <ProfitLoss />,
    index: false,
  },
];

const PrivateRoute = ({ element }: PrivateRouteProps): JSX.Element => {
  const location = useLocation();

  if (getItem(KEYS.ISLOGGEDIN) === CONSTVALS.LOGGEDINFLAG) {
    return element || <Outlet />;
  }
  return <Navigate to="/login" state={{ from: location }} replace />;
};

const LoginRoute = ({ element }: PrivateRouteProps): JSX.Element => {
  const location = useLocation();

  if (getItem(KEYS.ISLOGGEDIN) === CONSTVALS.LOGGEDINFLAG) {
    return <Navigate to="/dashboard" state={{ from: location }} replace />;
  }
  return <Login />;
};

// const LoggedInRoute = ({ component, ...rest }: RouteProps): JSX.Element => {
//   if (!component) {
//     throw Error("component is undefined");
//   }
//   const Component = component;

//   const render = (props: RouteComponentProps<{}>): React.ReactNode => {
//     if (!(getItem(KEYS.ISLOGGEDIN) === CONSTVALS.LOGGEDINFLAG)) {
//       return <Component {...props} />;
//     }
//     return <Redirect to={{ pathname: "/dashboard" }} />;
//   };

//   return <Route {...rest} element={render} />;
// };

const AppRoutes: React.FunctionComponent<{}> = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginRoute />} />
        {/* : For authorized routes */}
        <Route element={<PrivateRoute />}>
          {privateRoutesRegistry.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        </Route>
        <Route element={ErrorRoute} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
