import React from "react";
import "./index.scss";

interface PropType {
  show: boolean;
  closeModal: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  content: JSX.Element;
  onKeydown?: () => void;
  contentClassName?: string;
  className?: string;
  persistent?: boolean;
}

const Modal: React.FunctionComponent<PropType> = (props: PropType) => {
  const {
    show,
    closeModal,
    content,
    onKeydown,
    contentClassName,
    className,
    persistent,
  } = props;

  const destroyModal = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    if (!persistent && closeModal) {
      closeModal(event);
    }
  };

  if (!show) return null;
  return (
    <div
      className={`overlay modal-container ${className}`}
      onClick={destroyModal}
      onKeyDown={onKeydown}
      aria-hidden="true"
    >
      <div className={`modal-content-in ${contentClassName}`}>{content}</div>
    </div>
  );
};

Modal.defaultProps = {
  onKeydown: (): void => {
    //
  },
  persistent: false,
  contentClassName: "",
  className: "",
};

export default Modal;
