import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layout";
import "./index.scss";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { TextField } from "@mui/material";
import ProfitLossTable from "./ProfitLossTable";
import { ProfitLossResponseData } from "./types";
import ProfitLossStore from "./Store";
import { isNotEmptyObj } from "../../../utils/helper";

const ProfitLossContent = (): JSX.Element => {
  const today = new Date();
  const [selectedMonth, setSelectedMonth] = useState<Date>(
    new Date(today.getFullYear(), today.getMonth(), 1)
  );

  const handleDateChange = (newValue: Date | null) => {
    if (newValue != null) {
      setSelectedMonth(newValue);
    }
  };
  const {
    getProfitLosssReport,
    getProfitLosssReportSuccess,
    getProfitLosssReportError,
    clearGetProfitLosssReportResponses,
  } = ProfitLossStore();

  const [profitLossData, setProfitLossData] =
    useState<ProfitLossResponseData>();
  useEffect(() => {
    getProfitLosssReport({
      month: selectedMonth.toLocaleDateString("en-IN"),
    });
  }, [selectedMonth]);

  useEffect(() => {
    if (isNotEmptyObj(getProfitLosssReportSuccess)) {
      setProfitLossData(getProfitLosssReportSuccess);
    }
    if (isNotEmptyObj(getProfitLosssReportError)) {
      clearGetProfitLosssReportResponses();
    }
  }, [getProfitLosssReportSuccess, getProfitLosssReportError]);

  return (
    <div className="expenses-page-container">
      <div className="expenses-page-headers">
        <div className="heading-3 text-bold">{`Horizontal Profit/Loss Report of ${selectedMonth.toLocaleString(
          "default",
          {
            month: "long",
            year: "numeric",
          }
        )}`}</div>
        <div>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              views={["year", "month"]}
              openTo="month"
              value={selectedMonth}
              label="Change Month"
              onChange={handleDateChange}
              renderInput={(params: any) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
      </div>
      <div>
        <ProfitLossTable data={profitLossData} />
      </div>
    </div>
  );
};

const ProfitLoss: React.FunctionComponent = () => {
  return (
    <div>
      <Layout content={<ProfitLossContent />} />
    </div>
  );
};

export default ProfitLoss;
