/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Modal from "../../../components/Modal";
import ClearIcon from "@mui/icons-material/Clear";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
  Button,
  IconButton,
  TextField,
  Alert,
  Snackbar,
  AlertTitle,
} from "@mui/material";
import { useFormik } from "formik";
import { isNotEmptyObj, stripZerosAndAlpha } from "../../../utils/helper";
import { isToday } from "date-fns";
import DashboardState from "../Store";

interface Props {
  closeModal: () => void;
  isStartingDone: boolean;
  date: Date;
}

const AddReadingsModalContent = (props: Props): JSX.Element => {
  const { closeModal, isStartingDone, date } = props;

  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const {
    addReadings,
    addReadingsSuccess,
    addReadingsError,
    clearAddReadingResponses,
  } = DashboardState();

  const formik = useFormik({
    initialValues: {
      startingNozzle1: "",
      startingNozzle2: "",
      startingNozzle3: "",
      startingNozzle4: "",
      startingNozzle5: "",
      startingNozzle6: "",
      closingNozzle1: "",
      closingNozzle2: "",
      closingNozzle3: "",
      closingNozzle4: "",
      closingNozzle5: "",
      closingNozzle6: "",
      testMs: "15",
      testHsd: "15",
      openingStockMs: "",
      openingStockHsd: "",
      openingStockLubricants: "",
      openingStockGas: "",
      closingStockMs: "",
      closingStockHsd: "",
      closingStockLubricants: "",
      closingStockGas: "",
    },
    onSubmit: (value) => {
      //
    },
  });

  const handleChange = (
    type: string,
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    let input = event.target.value;
    input = stripZerosAndAlpha(input);
    formik.setFieldValue(type, input);
  };

  const saveReadings = (): void => {
    if (!isStartingDone)
      addReadings(
        {
          date: date.toLocaleDateString(),
          nozzle1: formik.values.startingNozzle1,
          nozzle2: formik.values.startingNozzle2,
          nozzle3: formik.values.startingNozzle3,
          nozzle4: formik.values.startingNozzle4,
          nozzle5: formik.values.startingNozzle5,
          nozzle6: formik.values.startingNozzle6,
          stocks: [
            {
              productCode: "MS",
              value: formik.values.openingStockMs,
            },
            {
              productCode: "HSD",
              value: formik.values.openingStockHsd,
            },
            {
              productCode: "LUBE",
              value: formik.values.openingStockLubricants,
            },
            {
              productCode: "GAS",
              value: formik.values.openingStockGas,
            },
          ],
        },
        "starting"
      );
    else
      addReadings(
        {
          date: date.toLocaleDateString(),
          nozzle1: formik.values.closingNozzle1,
          nozzle2: formik.values.closingNozzle2,
          nozzle3: formik.values.closingNozzle3,
          nozzle4: formik.values.closingNozzle4,
          nozzle5: formik.values.closingNozzle5,
          nozzle6: formik.values.closingNozzle6,
          testMs: formik.values.testMs,
          testHsd: formik.values.testHsd,
          stocks: [
            {
              productCode: "MS",
              value: formik.values.closingStockMs,
            },
            {
              productCode: "HSD",
              value: formik.values.closingStockHsd,
            },
            {
              productCode: "LUBE",
              value: formik.values.closingStockLubricants,
            },
            {
              productCode: "GAS",
              value: formik.values.closingStockGas,
            },
          ],
        },
        "closing"
      );
  };

  const isSubmissible = (): boolean => {
    if (isStartingDone) {
      if (
        formik.values.closingNozzle1 &&
        formik.values.closingNozzle2 &&
        formik.values.closingNozzle3 &&
        formik.values.closingNozzle4 &&
        formik.values.closingNozzle5 &&
        formik.values.closingNozzle6
      )
        return true;
      return false;
    } else {
      if (
        formik.values.startingNozzle1 &&
        formik.values.startingNozzle2 &&
        formik.values.startingNozzle3 &&
        formik.values.startingNozzle4 &&
        formik.values.startingNozzle5 &&
        formik.values.startingNozzle6
      )
        return true;
      return false;
    }
  };

  useEffect(() => {
    if (isNotEmptyObj(addReadingsSuccess)) {
      setShowSuccess(true);
      clearAddReadingResponses();
      setTimeout(() => {
        closeModal();
      }, 2000);
    }
    if (isNotEmptyObj(addReadingsError)) {
      setShowError(true);
      clearAddReadingResponses();
    }
  }, [addReadingsSuccess, addReadingsError]);

  return (
    <div className="add-readings-modal">
      {showSuccess && (
        <Snackbar
          open={showSuccess}
          autoHideDuration={4000}
          onClose={(): void => setShowSuccess(false)}
          message="Note archived"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            severity="success"
            onClose={(): void => setShowSuccess(false)}
            className="sucess-alert"
          >
            <AlertTitle>Success</AlertTitle>
            Successfully added readings
          </Alert>
        </Snackbar>
      )}
      {showError && (
        <Snackbar
          open={showError}
          autoHideDuration={4000}
          onClose={(): void => setShowError(false)}
          message="Note archived"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            severity="error"
            onClose={(): void => setShowError(false)}
            className="sucess-alert"
          >
            <AlertTitle>Error</AlertTitle>
            Couldn't add readings. Please try again.
          </Alert>
        </Snackbar>
      )}
      <div className="add-readings-container">
        <div className="header-container is-flex is-justify-content-space-between is-align-items-center">
          <div className="credit-sale-header">{`Add Readings for ${
            !isToday(date) ? date.toLocaleDateString() : "Today"
          }`}</div>
          <IconButton onClick={(): void => closeModal()}>
            <ClearIcon color="error" />
          </IconButton>
        </div>
        <div className="add-readings-form">
          {!isStartingDone ? (
            <>
              <div className="columns">
                <div className="column">
                  <TextField
                    id="startingNozzle1"
                    label="Starting Nozzle 1"
                    variant="outlined"
                    value={formik.values.startingNozzle1}
                    sx={{ width: 250 }}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      handleChange("startingNozzle1", e);
                    }}
                  />
                </div>
                <div className="column">
                  <TextField
                    id="startingNozzle2"
                    label="Starting Nozzle 2"
                    variant="outlined"
                    value={formik.values.startingNozzle2}
                    sx={{ width: 250 }}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      handleChange("startingNozzle2", e);
                    }}
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <TextField
                    id="startingNozzle3"
                    label="Starting Nozzle 3"
                    variant="outlined"
                    value={formik.values.startingNozzle3}
                    sx={{ width: 250 }}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      handleChange("startingNozzle3", e);
                    }}
                  />
                </div>
                <div className="column">
                  <TextField
                    id="startingNozzle4"
                    label="Starting Nozzle 4"
                    variant="outlined"
                    value={formik.values.startingNozzle4}
                    sx={{ width: 250 }}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      handleChange("startingNozzle4", e);
                    }}
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <TextField
                    id="startingNozzle3"
                    label="Starting Nozzle 5"
                    variant="outlined"
                    value={formik.values.startingNozzle5}
                    sx={{ width: 250 }}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      handleChange("startingNozzle5", e);
                    }}
                  />
                </div>
                <div className="column">
                  <TextField
                    id="startingNozzle4"
                    label="Starting Nozzle 6"
                    variant="outlined"
                    value={formik.values.startingNozzle6}
                    sx={{ width: 250 }}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      handleChange("startingNozzle6", e);
                    }}
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <TextField
                    id="stock-ms"
                    label="Opening Stock MS"
                    variant="outlined"
                    value={formik.values.openingStockMs}
                    sx={{ width: 250 }}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      handleChange("openingStockMs", e);
                    }}
                  />
                </div>
                <div className="column">
                  <TextField
                    id="stock-hsd"
                    label="Opening Stock HSD"
                    variant="outlined"
                    value={formik.values.openingStockHsd}
                    sx={{ width: 250 }}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      handleChange("openingStockHsd", e);
                    }}
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <TextField
                    id="stock-lubricants"
                    label="Opening Stock Lubricants"
                    variant="outlined"
                    value={formik.values.openingStockLubricants}
                    sx={{ width: 250 }}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      handleChange("openingStockLubricants", e);
                    }}
                  />
                </div>
                <div className="column">
                  <TextField
                    id="stock-gas"
                    label="Opening Stock Gas"
                    variant="outlined"
                    value={formik.values.openingStockGas}
                    sx={{ width: 250 }}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      handleChange("openingStockGas", e);
                    }}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="columns">
                <div className="column">
                  <TextField
                    id="closingNozzle1"
                    label="Closing Nozzle 1"
                    variant="outlined"
                    value={formik.values.closingNozzle1}
                    sx={{ width: 250 }}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      handleChange("closingNozzle1", e);
                    }}
                  />
                </div>
                <div className="column">
                  <TextField
                    id="closingNozzle2"
                    label="Closing Nozzle 2"
                    variant="outlined"
                    value={formik.values.closingNozzle2}
                    sx={{ width: 250 }}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      handleChange("closingNozzle2", e);
                    }}
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <TextField
                    id="closingNozzle3"
                    label="Closing Nozzle 3"
                    variant="outlined"
                    value={formik.values.closingNozzle3}
                    sx={{ width: 250 }}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      handleChange("closingNozzle3", e);
                    }}
                  />
                </div>
                <div className="column">
                  <TextField
                    id="closingNozzle4"
                    label="Closing Nozzle 4"
                    variant="outlined"
                    value={formik.values.closingNozzle4}
                    sx={{ width: 250 }}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      handleChange("closingNozzle4", e);
                    }}
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <TextField
                    id="closingNozzle3"
                    label="Closing Nozzle 5"
                    variant="outlined"
                    value={formik.values.closingNozzle5}
                    sx={{ width: 250 }}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      handleChange("closingNozzle5", e);
                    }}
                  />
                </div>
                <div className="column">
                  <TextField
                    id="closingNozzle4"
                    label="Closing Nozzle 6"
                    variant="outlined"
                    value={formik.values.closingNozzle6}
                    sx={{ width: 250 }}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      handleChange("closingNozzle6", e);
                    }}
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <TextField
                    id="test-Ms"
                    label="Test MS"
                    variant="outlined"
                    value={formik.values.testMs}
                    sx={{ width: 250 }}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      handleChange("testMs", e);
                    }}
                  />
                </div>
                <div className="column">
                  <TextField
                    id="test-hsd"
                    label="Test HSD"
                    variant="outlined"
                    value={formik.values.testHsd}
                    sx={{ width: 250 }}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      handleChange("testHsd", e);
                    }}
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <TextField
                    id="stock-ms"
                    label="Closing Stock MS"
                    variant="outlined"
                    value={formik.values.closingStockMs}
                    sx={{ width: 250 }}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      handleChange("closingStockMs", e);
                    }}
                  />
                </div>
                <div className="column">
                  <TextField
                    id="stock-hsd"
                    label="Closing Stock HSD"
                    variant="outlined"
                    value={formik.values.closingStockHsd}
                    sx={{ width: 250 }}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      handleChange("closingStockHsd", e);
                    }}
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <TextField
                    id="stock-lubricants"
                    label="Closing Stock Lubricants"
                    variant="outlined"
                    value={formik.values.closingStockLubricants}
                    sx={{ width: 250 }}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      handleChange("closingStockLubricants", e);
                    }}
                  />
                </div>
                <div className="column">
                  <TextField
                    id="stock-gas"
                    label="Closing Stock Gas"
                    variant="outlined"
                    value={formik.values.closingStockGas}
                    sx={{ width: 250 }}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      handleChange("closingStockGas", e);
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        <div className="save-readings-container is-flex is-justify-content-flex-end">
          <div className="reset-button">
            <Button
              variant="contained"
              onClick={(): void => {}}
              startIcon={<RestartAltIcon />}
            >
              Reset
            </Button>
          </div>
          <div>
            <Button
              variant="contained"
              onClick={(): void => {
                saveReadings();
              }}
              disabled={!isSubmissible()}
              color="success"
            >
              Save Readings
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const AddReadingsModal = (props: Props): JSX.Element => {
  const [show, setShow] = useState(true);
  const { closeModal } = props;

  return (
    <Modal
      show={show}
      closeModal={(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        setShow(false);
        closeModal();
      }}
      content={<AddReadingsModalContent {...props} />}
      persistent
    />
  );
};

export default AddReadingsModal;
