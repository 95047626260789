import React from "react";
import "./index.scss";
import { ProfitLossResponseData } from "./types";
interface Props {
  data: ProfitLossResponseData | undefined;
}

const ProfitLossTable = (props: Props) => {
  const { data } = props;
  return (
    <>
      <div className="financial-table">
        <div className="section-container">
          <h3 className="section-title">Expense</h3>
          <div className="table">
            <div className="table-row">
              <div className="table-cell">OPENING STOCK</div>
              <div></div>
              <div className="table-cell text-right">
                {data?.openingStock.toLocaleString("en-IN") || 0.0}
              </div>
            </div>
            <div className="table-row">
              <div className="table-cell">PURCHASES</div>
              <div></div>
              <div className="table-cell text-right">
                {data?.purchases.toLocaleString("en-IN") || 0.0}
              </div>
            </div>
            <div className="table-row">
              <div className="table-cell">VENDOR CREDITS</div>
              <div></div>
              <div className="table-cell text-right">
                {data?.vendorCredits.toLocaleString("en-IN") || 0.0}
              </div>
            </div>
            <div className="table-row">
              <div className="table-cell">INVENTORY ADJUSTMENT</div>
              <div></div>
              <div className="table-cell text-right">
                {data?.inventoryAdjustments.toLocaleString("en-IN") || 0.0}
              </div>
            </div>

            <div className="table-row section-subtotal">
              <div className="table-cell bold">COST OF GOODS SOLD</div>
            </div>
            <div className="table-row">
              <div></div>
              <div className="table-cell bold text-right">
                TOTAL COST OF GOODS SOLD
              </div>
              <div className="table-cell bold text-right">
                {data?.totalCostOfGoodsSold.toLocaleString("en-IN") || 0.0}
              </div>
            </div>
            <div className="table-row">
              <div className="table-cell">SALARY PAYMENTS</div>
              <div></div>
              <div className="table-cell text-right">
                {data?.salaryPayments.toLocaleString("en-IN") || 0.0}
              </div>
            </div>
            <div className="table-row">
              <div className="table-cell">UTILITY AND MISC EXPENSES</div>
              <div></div>
              <div className="table-cell text-right">
                {data?.utilityAndMisExpenses.toLocaleString("en-IN") || 0.0}
              </div>
            </div>
            <div className="table-row">
              <div className="table-cell">BANKING EXPENSES</div>
              <div></div>
              <div className="table-cell text-right">
                {data?.bankingExpenses.toLocaleString("en-IN") || 0.0}
              </div>
            </div>
            <div className="table-row section-subtotal">
              <div className="table-cell bold">OPERATING EXPENSE</div>
            </div>
            <div className="table-row">
              <div></div>
              <div className="table-cell bold text-right">
                TOTAL OPERATING EXPENSE
              </div>
              <div className="table-cell bold text-right">
                {data?.totalOperatingExpenses.toLocaleString("en-IN") || 0.0}
              </div>
            </div>
            <div className="table-row mt-2">
              <div className="table-cell">OWNERS DRAWINGS</div>
              <div></div>
              <div className="table-cell text-right">
                {data?.nonOperatingExpenses.toLocaleString("en-IN") || 0.0}
              </div>
            </div>
            <div className="table-row section-subtotal">
              <div className="table-cell bold">NON OPERATING EXPENSE</div>
            </div>
            <div className="table-row">
              <div></div>
              <div className="table-cell bold text-right">
                TOTAL NON OPERATING EXPENSE
              </div>
              <div className="table-cell bold text-right">
                {data?.totalNonOperatingExpenses.toLocaleString("en-IN") || 0.0}
              </div>
            </div>

            <div className="table-row net-profit-loss">
              <div className="table-cell bold">NET PROFIT/LOSS</div>
              <div></div>
              <div className="table-cell bold text-right">
                {data?.netProfitOrLoss.toLocaleString("en-IN") || 0.0}
              </div>
            </div>
          </div>
        </div>

        <div className="section-container income">
          <h3 className="section-title">Income</h3>
          <div className="table">
            <div className="table-row">
              <div className="table-cell">SALES</div>
              <div></div>
              <div className="table-cell text-right">
                {data?.sales.toLocaleString("en-IN") || 0.0}
              </div>
            </div>
            <div className="table-row section-subtotal">
              <div className="table-cell bold">OPERATING INCOME</div>
            </div>
            <div className="table-row">
              <div></div>
              <div className="table-cell bold text-right">
                TOTAL OPERATING INCOME
              </div>
              <div className="table-cell bold text-right">
                {data?.totalOperatingIncome.toLocaleString("en-IN") || 0.0}
              </div>
            </div>

            <div className="table-row mt-2">
              <div className="table-cell">CLOSING STOCK</div>
              <div></div>
              <div className="table-cell text-right">
                {data?.closingStock.toLocaleString("en-IN") || 0.0}
              </div>
            </div>

            <div className="table-row section-subtotal">
              <div className="table-cell bold">NON OPERATING INCOME</div>
            </div>
            <div className="table-row">
              <div></div>
              <div className="table-cell bold text-right">
                TOTAL NON OPERATING INCOME
              </div>
              <div className="table-cell bold text-right">
                {data?.totalNonOperatingIncome.toLocaleString("en-IN") || 0.0}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="totals-table m-0">
        <div className="section-container p-0">
          <div className="table">
            <div className="table-row total-row">
              <div className="table-cell bold">Total</div>
              <div></div>
              <div className="table-cell text-right bold">
                {data?.totalExpense.toLocaleString("en-IN") || 0.0}
              </div>
            </div>
          </div>
        </div>
        <div className="section-container income">
          <div className="table">
            <div className="table-row total-row">
              <div className="table-cell bold">Total</div>
              <div></div>
              <div className="table-cell text-right bold">
                {data?.totalIncome.toLocaleString("en-IN") || 0.0}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfitLossTable;
