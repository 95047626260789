/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Snackbar,
  Alert,
  AlertTitle,
  IconButton,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Modal from "../../components/Modal";
import { AddAttendanceType } from "../Employees/types";
import "./index.scss";
import ClearIcon from "@mui/icons-material/Clear";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useFormik } from "formik";
import { AccountCircle } from "@mui/icons-material";
import DropDownInput from "../../components/DropdownInput";
import { employeeList } from "../../utils/appConstants";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import DeleteIcon from "@mui/icons-material/Delete";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import EmployeeStore from "../Employees/Store";
import { isNotEmptyObj } from "../../utils/helper";

interface Props {
  closeModal: () => void;
}

const AddAttendanceModalContent = (props: Props): JSX.Element => {
  const { closeModal } = props;
  const {
    addAttendance,
    addAttendanceSuccess,
    addAttendanceError,
    clearAddAttendanceResponses,
  } = EmployeeStore();
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [date, setDate] = useState<Date | null>(new Date());
  const now = new Date();
  const [startTime, setStartTime] = useState<Date | null>(
    new Date(now.setHours(6, 0, 0))
  );

  const [endTime, setEndTime] = useState<Date | null>(
    new Date(new Date().setMinutes(0, 0))
  );
  const [attendanceList, setAttendanceList] = useState<AddAttendanceType[]>([]);

  const formik = useFormik({
    initialValues: {
      employeeId: "",
      employeeName: "",
      date: date?.toLocaleDateString() || "",
      startHour: startTime?.toLocaleString("en-IN") || "",
      endHour: endTime?.toLocaleString("en-IN") || "",
    },
    onSubmit: (value) => {
      //
    },
  });

  const handleDateChange = (newValue: Date | null) => {
    if (newValue != null) {
      formik.setFieldValue("date", newValue?.toLocaleDateString());
      setDate(newValue);
      setStartTime(new Date(newValue.setHours(6, 0, 0)));
      formik.setFieldValue(
        "startHour",
        new Date(newValue.setHours(6, 0, 0)).toLocaleString("en-IN")
      );
      setEndTime(new Date(newValue.setHours(now.getHours(), 0, 0)));
      formik.setFieldValue(
        "endHour",
        new Date(newValue.setHours(now.getHours(), 0, 0)).toLocaleString(
          "en-IN"
        )
      );
    }
  };

  const handleTimeChange = (time: Date | null, type: string) => {
    if (time != null) {
      formik.setFieldValue(type, time?.toLocaleString("en-IN"));
      switch (type) {
        case "startHour":
          setStartTime(time);
          return;
        case "endHour":
          setEndTime(time);
          return;
      }
    }
  };

  const handleEmployeeSelection = (value: string): void => {
    const employee = employeeList.find((item) => item.code === value);
    if (employee) formik.setFieldValue("employeeName", employee.name);
    if (employee) formik.setFieldValue("employeeId", employee.code);
  };

  const resetAttendanceStateValue = (): void => {
    setDate(now);
    setStartTime(new Date(now.setHours(6, 0, 0)));
    setEndTime(new Date(new Date().setMinutes(0, 0)));
  };

  const addAttendances = (): void => {
    if (startTime && endTime) {
      setAttendanceList([
        ...attendanceList,
        {
          id: Math.random().toString(36).substring(2, 7),
          employeeId: formik.values.employeeId,
          employeeName: formik.values.employeeName,
          date: formik.values.date,
          startHour: formik.values.startHour,
          endHour: formik.values.endHour,
          workHours: (
            (endTime?.getTime() - startTime?.getTime()) /
            (60 * 60 * 1000)
          ).toFixed(1),
        },
      ]);
      formik.resetForm();
      resetAttendanceStateValue();
    }
  };

  const saveAttendance = (): void => {
    addAttendance({
      list: attendanceList.map((item) => {
        return {
          employeeId: item.employeeId,
          employeeName: item.employeeName,
          date: item.date,
          startHour: item.startHour,
          endHour: item.endHour,
        };
      }),
    });
  };

  const deleteAttendance = (id: string): void =>
    setAttendanceList(attendanceList.filter((item) => item.id !== id));

  useEffect(() => {
    if (isNotEmptyObj(addAttendanceSuccess)) {
      setShowSuccess(true);
      clearAddAttendanceResponses();
      setTimeout(() => {
        closeModal();
      }, 3000);
    }
    if (isNotEmptyObj(addAttendanceError)) {
      setShowError(true);
      clearAddAttendanceResponses();
      setAttendanceList([]);
    }
  }, [addAttendanceSuccess, addAttendanceError]);

  const getTimeOnly = (value: string): string => {
    const timeString = value.split(",")[1];
    const timevalues = timeString.split(":");
    return `${timevalues[0]}:${timevalues[1]} ${timeString.slice(-2)}`;
  };

  const resetAttendaceForm = (): void => {
    setAttendanceList([]);
  };

  return (
    <div className="add-attendance-modal">
      {showSuccess && (
        <Snackbar
          open={showSuccess}
          autoHideDuration={3000}
          onClose={(): void => setShowSuccess(false)}
          message="Note archived"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            severity="success"
            onClose={(): void => setShowSuccess(false)}
            className="sucess-alert"
          >
            <AlertTitle>Success</AlertTitle>
            Successfully added attendances
          </Alert>
        </Snackbar>
      )}
      {showError && (
        <Snackbar
          open={showError}
          autoHideDuration={4000}
          onClose={(): void => setShowError(false)}
          message="Note archived"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            severity="error"
            onClose={(): void => setShowError(false)}
            className="sucess-alert"
          >
            <AlertTitle>Error</AlertTitle>
            Couldn't add attendances. Please try again later.
          </Alert>
        </Snackbar>
      )}
      <div className="add-attendance-container">
        <div className="header-container is-flex is-justify-content-space-between is-align-items-center">
          <div className="attendance-header">Add Attendance</div>
          <IconButton onClick={(): void => closeModal()}>
            <ClearIcon color="error" />
          </IconButton>
        </div>
        <div className="add-attendance-form">
          <div className="columns">
            <div className="column">
              <DropDownInput
                value={formik.values.employeeName}
                items={employeeList}
                placeholder="Select Person"
                name="select-person-name"
                onChange={handleEmployeeSelection}
                width={300}
                icon={<AccountCircle />}
              />
            </div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <div className="column">
                <DesktopDatePicker
                  value={date}
                  label="Date"
                  inputFormat="dd/MM/yyyy"
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
              <div className="column">
                <DesktopTimePicker
                  PopperProps={{
                    popperOptions: {
                      strategy: "fixed",
                    },
                  }}
                  value={startTime}
                  label="Start Time"
                  onChange={(value) => handleTimeChange(value, "startHour")}
                  renderInput={(params) => <TextField {...params} />}
                  views={["hours"]}
                />
              </div>
              <div className="column">
                <DesktopTimePicker
                  PopperProps={{
                    popperOptions: {
                      strategy: "fixed",
                    },
                  }}
                  value={endTime}
                  label="End Time"
                  onChange={(value) => handleTimeChange(value, "endHour")}
                  renderInput={(params) => <TextField {...params} />}
                  views={["hours"]}
                />
              </div>
            </LocalizationProvider>
            <div className="column is-1 is-flex is-align-items-center">
              {formik.values.employeeId && (
                <IconButton onClick={addAttendances}>
                  <AddCircleIcon />
                </IconButton>
              )}
            </div>
          </div>
          <div className="attendance-table-container">
            {attendanceList.length !== 0 && (
              <>
                <TableContainer>
                  <Table
                    sx={{ minWidth: 600, fontFamily: "Roboto" }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align="right">Sl.No</TableCell>
                        <TableCell align="right">Employee</TableCell>
                        <TableCell align="right">Date</TableCell>
                        <TableCell align="center">Start Hour</TableCell>
                        <TableCell align="center">End Hour</TableCell>
                        <TableCell align="center">Work Hours</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {attendanceList.map((row, index) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell component="th" scope="row" align="right">
                            {index + 1}
                          </TableCell>
                          <TableCell align="right">
                            {row.employeeName}
                          </TableCell>
                          <TableCell align="right">{row.date}</TableCell>
                          <TableCell align="center">
                            {getTimeOnly(row.startHour)}
                          </TableCell>
                          <TableCell align="center">
                            {getTimeOnly(row.endHour)}
                          </TableCell>
                          <TableCell align="center">{row.workHours}</TableCell>
                          <TableCell align="center">
                            <IconButton
                              onClick={(): void => deleteAttendance(row.id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </div>
        </div>
        <div className="save-attendance-container is-flex is-justify-content-flex-end">
          <div className="reset-button">
            <Button
              variant="contained"
              onClick={(): void => {
                resetAttendaceForm();
              }}
              startIcon={<RestartAltIcon />}
            >
              Reset
            </Button>
          </div>
          <div>
            <Button
              variant="contained"
              onClick={(): void => {
                saveAttendance();
              }}
              //   disabled={!isSubmissible()}
              color="success"
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const AddAttendanceModal = (props: Props): JSX.Element => {
  const [show, setShow] = useState(true);
  const { closeModal } = props;
  return (
    <Modal
      show={show}
      closeModal={(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        setShow(false);
        closeModal();
      }}
      content={<AddAttendanceModalContent {...props} />}
      persistent
    />
  );
};

export default AddAttendanceModal;
