import React from "react";
import "./index.scss";
import "./media.scss";
import ErroSvg from "../../images/Error_page.svg";

const ErrorRoute = (): JSX.Element => {
  return (
    <div className="error-page">
      <div className="title-wrapper">
        <div>
          <div className="code">404</div>
          <div className="subtitle">Page not found</div>
        </div>
      </div>
      <div className="bottom-wrapper">
        <div>
          <div className="alert-not-found">Something went wrong here.</div>
          <div className="msg-not-found-wrapper">
            <div className="message-not-found">
              I'm working on it, and will have this fixed as soon as possible.
            </div>
          </div>
        </div>
      </div>
      <img src={ErroSvg} alt="" className="error-img" />
    </div>
  );
};

export default ErrorRoute;
