/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TablePagination,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import "./index.scss";
import {
  CustomerPaymentsResponseType,
  CustomerPaymentType,
} from "../../types/types";
import CustomerState from "../Store";
import { isNotEmptyObj } from "../../../utils/helper";

interface CustomerDetailPageProps {
  customerId: string;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#3981DE",
    color: theme.palette.common.white,
    fontWeight: "600",
    fontSize: "15px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const PaymentListTable = (props: CustomerDetailPageProps): JSX.Element => {
  const { customerId } = props;
  const { getCustomerPaymentList, customerPaymentResponse } = CustomerState();

  const [paymentList, setPaymentList] = useState<CustomerPaymentType[]>();
  const [pageNumber, setPageNumber] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(20);
  const [totalCount, setTotalCount] = useState<number>(0);

  useEffect(() => {
    if (
      !isNotEmptyObj(customerPaymentResponse) ||
      customerPaymentResponse?.customerId !== customerId
    ) {
      const payload = {
        customerId: customerId,
        entriesPerPage: entriesPerPage,
        pageNumber: pageNumber,
      };
      getCustomerPaymentList(payload);
    }
  }, []);

  useEffect(() => {
    if (
      !isNotEmptyObj(customerPaymentResponse) ||
      customerPaymentResponse?.customerId !== customerId
    ) {
      const payload = {
        customerId: customerId,
        entriesPerPage: entriesPerPage,
        pageNumber: pageNumber,
      };
      getCustomerPaymentList(payload);
    }
  }, [customerId]);

  useEffect(() => {
    if (isNotEmptyObj(customerPaymentResponse)) {
      const {
        list,
        pageNumber: pageNo,
        entriesPerPage: entriesInPage,
        totalCount: total,
      } = customerPaymentResponse as CustomerPaymentsResponseType;
      setPaymentList(list);
      setPageNumber(pageNo);
      setEntriesPerPage(entriesInPage);
      setTotalCount(total);
    }
  }, [customerPaymentResponse]);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ): void => {
    if (event) {
      const payload = {
        customerId: customerId,
        entriesPerPage: entriesPerPage,
        pageNumber: page,
      };
      getCustomerPaymentList(payload);
      setPageNumber(page);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEntriesPerPage(+event.target.value);
    setPageNumber(0);
    const payload = {
      customerId: customerId,
      entriesPerPage: entriesPerPage,
      pageNumber: 0,
    };
    getCustomerPaymentList(payload);
    setPageNumber(0);
  };

  return (
    <>
      {customerId && (
        <div>
          <TableContainer className="sales-table">
            <Table
              sx={{ fontFamily: "Roboto", borderRadius: 3 }}
              aria-label="simple table"
            >
              <TableHead className="sales-table-head">
                <TableRow>
                  <StyledTableCell align="center">Date</StyledTableCell>
                  <StyledTableCell align="right">Amount</StyledTableCell>
                  <StyledTableCell align="center">Payment Mode</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paymentList &&
                  paymentList.map((row, index) => (
                    <>
                      <StyledTableRow
                        key={row.paymentId}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                        className="sales-list-table-row"
                      >
                        <StyledTableCell align="center">
                          {row.date}
                        </StyledTableCell>
                        <StyledTableCell style={{ textAlign: "right" }}>
                          {row.amount !== ""
                            ? Number(row.amount).toLocaleString("en-IN")
                            : 0}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.paymentMode}
                        </StyledTableCell>
                      </StyledTableRow>
                    </>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            count={totalCount}
            rowsPerPageOptions={[20, 30]}
            component="div"
            rowsPerPage={entriesPerPage}
            page={pageNumber}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      )}
    </>
  );
};

export default PaymentListTable;
