import React, { useState } from "react";
import { IconButton, TextField, Button, TextFieldProps } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./index.scss";
import {
  formateDateToLongString,
  formateDateToMonthString,
} from "../../utils/helper";
import { isToday } from "date-fns";
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from "@mui/x-date-pickers";

interface PropsType {
  today: Date;
  value: Date;
  onChange: (value: Date) => void;
}

const DashboardDatePicker = (props: PropsType): JSX.Element => {
  const { today, value, onChange } = props;
  const [selectedDate, setSelectedDate] = useState<Date>(value);

  const changeSelectedDate = (value: Date | null): void => {
    if (value) {
      setSelectedDate(value);
      onChange(value);
    }
  };

  return (
    <div className="date-picker-container">
      <div className="is-flex is-justify-content-space-between is-align-items-center">
        <div className="select-today-button">
          <Button
            variant="contained"
            onClick={(): void => {
              changeSelectedDate(today);
            }}
            color="success"
          >
            Today
          </Button>
        </div>
        <div className="center-container is-flex is-justify-content-space-between is-align-items-center">
          <div className="go-left-arrow">
            <IconButton
              onClick={(): void => {
                const previousDate = new Date(selectedDate);
                previousDate.setDate(previousDate.getDate() - 1);
                changeSelectedDate(previousDate);
              }}
            >
              <ArrowBackIosIcon />
            </IconButton>
          </div>
          <div className="is-hidden-touch show-selected-date-details">
            {formateDateToLongString(value)}
          </div>
          <div className="is-hidden-desktop show-selected-date-details-tab">
            {formateDateToMonthString(value)}
          </div>
          <div className="go-right-arrow">
            <IconButton
              onClick={(): void => {
                const nextDate = new Date(selectedDate);
                nextDate.setDate(nextDate.getDate() + 1);
                changeSelectedDate(nextDate);
              }}
              disabled={isToday(selectedDate)}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </div>
        </div>
        <div className="select-date-from-picker">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              value={selectedDate}
              label="Date"
              inputFormat="dd/MM/yyyy"
              onChange={changeSelectedDate}
              renderInput={(params: any) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
      </div>
    </div>
  );
};

export default DashboardDatePicker;
