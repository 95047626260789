/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { Button, TextField } from "@mui/material";
import "./index.scss";
import LoginImg from "../../images/login.png";
import LoginState from "./Store";
import { isNotEmptyObj } from "../../utils/helper";
import * as LocalStorageHelper from "./../../utils/localStorageHelper";
import { LoginResponseType } from "../types/types";

const LoginContent = (): JSX.Element => {
  const { callLoginApi, loginResponseData } = LoginState();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    onSubmit: (value) => {
      callLoginApi({
        userName: value.username,
        password: value.password,
      });
    },
  });

  const handleUsernameChange = (value: string): void => {
    formik.setFieldValue("username", value);
  };

  const handlePasswordChange = (value: string): void => {
    formik.setFieldValue("password", value);
  };

  useEffect(() => {
    if (isNotEmptyObj(loginResponseData)) {
      const { name, userId, role } = loginResponseData as LoginResponseType;
      LocalStorageHelper.setItem(LocalStorageHelper.KEYS.NAME, name);
      LocalStorageHelper.setItem(LocalStorageHelper.KEYS.USERID, userId);
      LocalStorageHelper.setItem(LocalStorageHelper.KEYS.ROLE, role);
      navigate("/dashboard");
    }
  }, [loginResponseData]);

  return (
    <div className="login-content-container">
      <div className="container is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
        <div className="login-content-layout">
          <div className="login-image">
            <img src={LoginImg} alt="login_img" />
            <div className="login-form">
              <form onSubmit={formik.handleSubmit}>
                <div className="login-form-container">
                  <div className="login-input">
                    <TextField
                      id="username"
                      label="Username"
                      fullWidth
                      value={formik.values.username}
                      variant="outlined"
                      onChange={(
                        e: React.ChangeEvent<HTMLInputElement>
                      ): void => handleUsernameChange(e.target.value)}
                    />
                  </div>
                  <div className="login-input">
                    <TextField
                      id="password"
                      label="Password"
                      type="password"
                      fullWidth
                      value={formik.values.password}
                      variant="outlined"
                      onChange={(
                        e: React.ChangeEvent<HTMLInputElement>
                      ): void => handlePasswordChange(e.target.value)}
                    />
                  </div>
                  <div className="login-button is-flex is-justify-content-center">
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{ width: 170 }}
                    >
                      Login
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const Login: React.FunctionComponent = () => {
  return (
    <div>
      <LoginContent />
    </div>
  );
};

export default Login;
