export enum Products {
  MS = "MS",
  HSD = "HSD",
}

export enum PaymentMode {
  CASH = "CASH",
  CARD = "CARD",
  UPI = "UPI",
  BANKTRANSFER = "BANKTRANSFER",
  CHEQUE = "CHEQUE",
}
