/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  IconButton,
  TextField,
  Button,
  Alert,
  Snackbar,
  AlertTitle,
  styled,
  TableCell,
  tableCellClasses,
  TableRow,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import { findEmployeeName, isNotEmptyObj } from "../../../utils/helper";
import DashboardState from "../Store";
import { DashboardDataResponseType } from "../types";
import "./index.scss";
import DashboardDatePicker from "../../../components/DashboardDatePicker";
import AmountCard from "../../../components/AmountCard";
import AddchartIcon from "@mui/icons-material/Addchart";
import { useNavigate } from "react-router-dom";
import { employeeList } from "../../../utils/appConstants";
import AddReadingsModal from "./AddReadingsModal";
import AddSettlements from "./AddSettlements";
import Modal from "../../../components/Modal";
import BalanceIcon from "@mui/icons-material/Balance";
import AddCardIcon from "@mui/icons-material/AddCard";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#3981DE",
    color: theme.palette.common.white,
    fontWeight: "600",
    fontSize: "15px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const DashboardContent = (): JSX.Element => {
  const navigate = useNavigate();
  const {
    selectedDate,
    setSelectedDate,
    loadDashboardData,
    dashboardData,
    dashboardDataResponseError,
    clearDashboardData,
    downloadDailyClosingReport,
    clearDownloadReponseData,
    downloadReportError,
  } = DashboardState();

  const [data, setData] = useState<DashboardDataResponseType>();
  const [showDashboardApiError, setShowDashboardApiError] = useState(false);
  const [showReadingsModal, setShowReadingsModal] = useState(false);
  const [showReadings, setShowReadings] = useState(false);
  const [showCashFlow, setShowCashFlow] = useState(false);

  const [showAddSettlementModal, setShowAddSettlementModal] = useState(false);
  const [showDownloadReportError, setShowDownloadReportError] = useState(false);

  useEffect(() => {
    loadDashboardData({
      date: selectedDate.toLocaleDateString(),
      employeeStrings: employeeList.map((item) => item.code),
    });
  }, []);

  useEffect(() => {
    if (isNotEmptyObj(dashboardData)) {
      setData(dashboardData);
    }
    if (isNotEmptyObj(dashboardDataResponseError)) {
      setShowDashboardApiError(true);
      setData({} as DashboardDataResponseType);
      clearDashboardData();
    }
  }, [dashboardData, dashboardDataResponseError]);

  useEffect(() => {
    if (isNotEmptyObj(downloadReportError)) {
      setShowDownloadReportError(true);
    }
  }, [downloadReportError]);

  const pushToEmployeeSalesDetails = (id: string): void => {
    navigate(`/sales/preview/1/${id}`);
  };

  const getTotalCardUpi = (): string => {
    return Number(
      (Number(data?.totalCard) + Number(data?.totalUPI)).toFixed(2)
    ).toLocaleString("en-IN");
  };

  const getSettledCardUpi = (): string => {
    return Number(
      (Number(data?.cardSales) + Number(data?.upiSales)).toFixed(2)
    ).toLocaleString("en-IN");
  };

  const renderTotalModal = (): JSX.Element => {
    return (
      <div className="readings-modal-container">
        {data && (
          <>
            <div className="readings-container is-flex is-align-items-center">
              <div className="readings-title">Starting Nozzle 1: </div>
              <div className="readings-amount">{data.startingNozzle1}</div>
            </div>
            <div className="readings-container is-flex is-align-items-center">
              <div className="readings-title">Starting Nozzle 2: </div>
              <div className="readings-amount">{data.startingNozzle2}</div>
            </div>
            <div className="readings-container is-flex is-align-items-center">
              <div className="readings-title">Starting Nozzle 3: </div>
              <div className="readings-amount">{data.startingNozzle3}</div>
            </div>
            <div className="readings-container is-flex is-align-items-center">
              <div className="readings-title">Starting Nozzle 4: </div>
              <div className="readings-amount">{data.startingNozzle4}</div>
            </div>
            <div className="readings-container is-flex is-align-items-center">
              <div className="readings-title">Closing Nozzle 1: </div>
              <div className="readings-amount">{data.closingNozzle1}</div>
            </div>
            <div className="readings-container is-flex is-align-items-center">
              <div className="readings-title">Closing Nozzle 2: </div>
              <div className="readings-amount">{data.closingNozzle2}</div>
            </div>
            <div className="readings-container is-flex is-align-items-center">
              <div className="readings-title">Closing Nozzle 3: </div>
              <div className="readings-amount">{data.closingNozzle3}</div>
            </div>
            <div className="readings-container is-flex is-align-items-center">
              <div className="readings-title">Closing Nozzle 4: </div>
              <div className="readings-amount">{data.closingNozzle4}</div>
            </div>
          </>
        )}
      </div>
    );
  };

  const getAmountForTotalSales = (): string =>
    (
      Number(data?.totalMSSales) * Number(data?.msPrice) +
      Number(data?.totalHSDSales) * Number(data?.hsdPrice)
    ).toFixed(0);

  const getTotalIncome = (): string =>
    (
      Number(data?.totalCash) +
      Number(data?.cardSales) +
      Number(data?.upiSales)
    ).toFixed(2);

  const getBalance = (): number =>
    Number(getAmountForTotalSales()) +
    Number(data?.totalPaymentReceived) -
    (Number(getTotalIncome()) +
      Number(data?.totalCredit) +
      Number(data?.totalExpense));

  const renderCashFlowModal = (): JSX.Element => {
    return (
      <div className="cashflow-modal-container">
        <div className="cashflow-header">Cash Flow Statement</div>
        {data && (
          <>
            <div className="readings-container is-flex is-align-items-center">
              <div className="readings-title">Total Amount By Sales: </div>
              <div className="readings-amount">
                {Number(getAmountForTotalSales()).toLocaleString("en-IN")}
              </div>
            </div>
            <div className="readings-container is-flex is-align-items-center">
              <div className="readings-title">Total Cash Incoming: </div>
              <div className="readings-amount">
                {Number(getTotalIncome()).toLocaleString("en-IN")}
              </div>
            </div>
            <div className="readings-container is-flex is-align-items-center">
              <div className="readings-title">Total Credits Made: </div>
              <div className="readings-amount">
                {Number(data.totalCredit).toLocaleString("en-IN")}
              </div>
            </div>
            <div className="readings-container is-flex is-align-items-center">
              <div className="readings-title">Total Payments Received: </div>
              <div className="readings-amount">
                {Number(data.totalPaymentReceived).toLocaleString("en-IN")}
              </div>
            </div>
            <div className="readings-container is-flex is-align-items-center">
              <div className="readings-title">Total Expenses: </div>
              <div className="readings-amount">
                {Number(data.totalExpense).toLocaleString("en-IN")}
              </div>
            </div>
            <div className="readings-container is-flex is-align-items-center">
              <div className="readings-title">Balance: </div>
              <div
                className={`readings-amount ${getBalance() > 0 ? "redded" : "greened"
                  }`}
              >
                {getBalance().toLocaleString("en-IN")}
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      {showReadingsModal && (
        <AddReadingsModal
          date={selectedDate}
          isStartingDone={data ? data.isStartingDone : false}
          closeModal={(): void => {
            setShowReadingsModal(false);
            loadDashboardData({
              date: selectedDate.toLocaleDateString(),
              employeeStrings: employeeList.map((item) => item.code),
            });
          }}
        />
      )}
      {showAddSettlementModal && (
        <AddSettlements
          date={selectedDate}
          closeModal={(): void => {
            setShowAddSettlementModal(false);
            loadDashboardData({
              date: selectedDate.toLocaleDateString(),
              employeeStrings: employeeList.map((item) => item.code),
            });
          }}
        />
      )}
      <Modal
        show={showReadings}
        content={renderTotalModal()}
        closeModal={(): void => setShowReadings(false)}
        contentClassName="readings-modal-content"
      />
      <Modal
        show={showCashFlow}
        content={renderCashFlowModal()}
        closeModal={(): void => setShowCashFlow(false)}
        contentClassName="cashflow-modal-content"
      />
      {showDashboardApiError && (
        <Snackbar
          open={showDashboardApiError}
          autoHideDuration={4000}
          onClose={(): void => setShowDashboardApiError(false)}
          message="Note archived"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            severity="error"
            onClose={(): void => setShowDashboardApiError(false)}
            className="sucess-alert"
          >
            <AlertTitle>Error</AlertTitle>
            Couldn't load dashboard data for this date.
          </Alert>
        </Snackbar>
      )}
      {showDownloadReportError && (
        <Snackbar
          open={showDownloadReportError}
          autoHideDuration={4000}
          onClose={(): void => setShowDownloadReportError(false)}
          message="Note archived"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            severity="error"
            onClose={(): void => setShowDownloadReportError(false)}
            className="sucess-alert"
          >
            <AlertTitle>Error</AlertTitle>
            Couldn't download report for this date.
          </Alert>
        </Snackbar>
      )}
      <div className="dashboard-container">
        <div className="date-picker-con">
          <DashboardDatePicker
            today={new Date()}
            value={selectedDate}
            onChange={(value): void => {
              setSelectedDate(value);
              loadDashboardData({
                date: value.toLocaleDateString(),
                employeeStrings: employeeList.map((item) => item.code),
              });
            }}
          />
        </div>
        {isNotEmptyObj(data) && data ? (
          <>
            <div className="total-sales-container">
              <div className="sales-container">
                <div className="total-sales-heading is-flex is-justify-content-space-between is-align-items-center">
                  <div>Total Sales</div>
                  {data.totalMSSales && data.totalHSDSales && (
                    <Button
                      variant="outlined"
                      onClick={(): void => {
                        setShowReadings(true);
                      }}
                    >
                      View Readings
                    </Button>
                  )}
                </div>
                <div className="columns is-multiline">
                  {data.totalMSSales && data.totalHSDSales && (
                    <>
                      <div className="column">
                        <AmountCard
                          name="MS Sales"
                          value={Number(data.totalMSSales).toFixed(0)}
                          className=""
                        />
                      </div>
                      <div className="column">
                        <AmountCard
                          name="HSD Sales"
                          value={Number(data.totalHSDSales).toFixed(0)}
                          className=""
                        />
                      </div>
                    </>
                  )}
                  <div className="column">
                    <AmountCard
                      name="MS Price"
                      value={data.msPrice}
                      className=""
                    />
                  </div>
                  <div className="column">
                    <AmountCard
                      name="HSD Price"
                      value={data.hsdPrice}
                      className=""
                    />
                  </div>
                  {!(data.totalMSSales && data.totalMSSales) && (
                    <div className="column is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
                      <Button
                        onClick={(): void => {
                          setShowReadingsModal(true);
                        }}
                        variant="contained"
                        startIcon={<AddchartIcon />}
                      >
                        Add Readings
                      </Button>
                    </div>
                  )}
                </div>
              </div>
              <div className="total-sales-heading is-flex is-justify-content-space-between is-align-items-center">
                Total Amount Recieved
                {data.totalMSSales && data.totalHSDSales && data.cardSales && (
                  <div className="is-flex is-justify-content-end is-align-items-center">
                    <Button
                      className="mr-2"
                      variant="outlined"
                      onClick={(): void => {
                        setShowCashFlow(true);
                      }}
                      startIcon={<BalanceIcon />}
                      color="success"
                    >
                      View Cash Flow
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={(): void => {
                        downloadDailyClosingReport({
                          date: selectedDate.toLocaleDateString(),
                          employeeStrings: employeeList.map(
                            (item) => item.code
                          ),
                        });
                      }}
                      startIcon={<CloudDownloadIcon />}
                      color="success"
                    >
                      Download Report
                    </Button>
                  </div>
                )}
              </div>
              <div className="columns is-multiline">
                <div className="column">
                  <AmountCard
                    name="Total Cash"
                    value={data.totalCash}
                    className=""
                  />
                </div>
                <div className="column">
                  <AmountCard
                    name="Total Card"
                    value={data.totalCard}
                    className="amount-card-container-small"
                  />
                </div>
                {data.cardSales && (
                  <div className="column">
                    <AmountCard
                      name="Card Settled"
                      value={data.cardSales}
                      className="amount-card-container-small"
                    />
                  </div>
                )}
                <div className="column">
                  <AmountCard
                    name="Total UPI"
                    value={data.totalUPI}
                    className="amount-card-container-small"
                  />
                </div>
                {data.upiSales && (
                  <div className="column">
                    <AmountCard
                      name="UPI Settled"
                      value={data.upiSales}
                      className="amount-card-container-small"
                    />
                  </div>
                )}
                {data.cardSales === "" && (
                  <div className="column is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
                    <Button
                      onClick={(): void => {
                        setShowAddSettlementModal(true);
                      }}
                      variant="contained"
                      startIcon={<AddCardIcon />}
                    >
                      Add Settlments
                    </Button>
                  </div>
                )}
              </div>
              <div className="settlement-details">
                <div className="is-flex m-4">
                  <div className="settle-header">{"Total Card & UPI: "}</div>
                  <div>{getTotalCardUpi()}</div>
                </div>
                <div className="is-flex m-4">
                  <div className="settle-header">{"Settled Card & UPI: "}</div>
                  <div>{getSettledCardUpi()}</div>
                </div>
              </div>
            </div>
            {data.employeeSalesList && (
              <div className="employee-sales-container">
                <div className="total-sales-heading">Employee Sales List</div>
                <div>
                  <TableContainer className="sales-table">
                    <Table
                      sx={{ fontFamily: "Roboto", borderRadius: 3 }}
                      aria-label="simple table"
                    >
                      <TableHead className="sales-table-head">
                        <TableRow>
                          <StyledTableCell align="right">Sl.No</StyledTableCell>
                          <StyledTableCell align="right">
                            Employee
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            Starting Readings
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            Closing Readings
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            Cash Sales
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            Card Sales
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            UPI Sales
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            Difference
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.employeeSalesList &&
                          data.employeeSalesList.map((row, index) => (
                            <StyledTableRow
                              key={row.sheetId}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                              className="sales-list-table-row"
                            >
                              <StyledTableCell
                                onClick={(): void =>
                                  pushToEmployeeSalesDetails(row.sheetId)
                                }
                                component="th"
                                scope="row"
                                align="right"
                              >
                                {index + 1}
                              </StyledTableCell>
                              <StyledTableCell
                                onClick={(): void =>
                                  pushToEmployeeSalesDetails(row.sheetId)
                                }
                                align="right"
                              >
                                {row.employeeName}
                              </StyledTableCell>
                              <StyledTableCell
                                onClick={(): void =>
                                  pushToEmployeeSalesDetails(row.sheetId)
                                }
                                align="right"
                              >
                                {row.nozzleReadings.map((inx) => inx.starting).join(',')}
                              </StyledTableCell>
                              <StyledTableCell
                                onClick={(): void =>
                                  pushToEmployeeSalesDetails(row.sheetId)
                                }
                                align="right"
                              >
                                {row.nozzleReadings.map((inx) => inx.closing).join(',')}
                              </StyledTableCell>
                              <StyledTableCell
                                onClick={(): void =>
                                  pushToEmployeeSalesDetails(row.sheetId)
                                }
                                align="right"
                              >
                                {row.cashSales !== "" ? row.cashSales : 0}
                              </StyledTableCell>
                              <StyledTableCell
                                onClick={(): void =>
                                  pushToEmployeeSalesDetails(row.sheetId)
                                }
                                align="right"
                              >
                                {row.cardSales !== "" ? row.cardSales : 0}
                              </StyledTableCell>
                              <StyledTableCell
                                onClick={(): void =>
                                  pushToEmployeeSalesDetails(row.sheetId)
                                }
                                align="right"
                              >
                                {row.upiSales !== "" ? row.upiSales : 0}
                              </StyledTableCell>
                              <StyledTableCell
                                onClick={(): void =>
                                  pushToEmployeeSalesDetails(row.sheetId)
                                }
                                align="right"
                              >
                                {row.diff}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            )}
            {data.creditSalesList && (
              <div className="credit-sales-container">
                <div className="totals-container is-flex is-justify-content-space-between is-align-items-center">
                  <div className="total-sales-heading">Credit Sales List</div>
                  <div className="total-amounts">{`Total: ${data.totalCredit}`}</div>
                </div>

                <div>
                  <TableContainer className="sales-table">
                    <Table
                      sx={{ fontFamily: "Roboto", borderRadius: 3 }}
                      aria-label="simple table"
                    >
                      <TableHead className="sales-table-head">
                        <TableRow>
                          <StyledTableCell align="right">Sl.No</StyledTableCell>
                          <StyledTableCell align="right">
                            Customer
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            Vehicle
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            Product
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            Quantity
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            Amount
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            Employee
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.creditSalesList &&
                          data.creditSalesList.map((row, index) => (
                            <StyledTableRow
                              key={row.saleId}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                              className="sales-list-table-row"
                            >
                              <StyledTableCell
                                component="th"
                                scope="row"
                                align="right"
                              >
                                {index + 1}
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                {row.name}
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                {`${row.vehicleName} | ${row.vehicleNumber}`}
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                {row.product}
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                {row.quantity}
                              </StyledTableCell>

                              <StyledTableCell align="right">
                                {row.amount}
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                {findEmployeeName(row.employeeId)}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            )}

            <div className="columns is-multiline">
              <div className="column">
                {data.paymentReceivedList && (
                  <div className="payments-sales-container">
                    <div className="totals-container is-flex is-justify-content-space-between is-align-items-center">
                      <div className="total-sales-heading">
                        Payments Recieved List
                      </div>
                      <div className="total-amounts">{`Total: ${data.totalPaymentReceived}`}</div>
                    </div>

                    <div>
                      <TableContainer className="sales-table">
                        <Table
                          sx={{ fontFamily: "Roboto", borderRadius: 3 }}
                          aria-label="simple table"
                        >
                          <TableHead className="sales-table-head">
                            <TableRow>
                              <StyledTableCell align="right">
                                Sl.No
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                Customer
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                Payment Mode
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                Amount
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                Employee
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data.paymentReceivedList &&
                              data.paymentReceivedList.map((row, index) => (
                                <StyledTableRow
                                  key={row.paymentId}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                  className="sales-list-table-row"
                                >
                                  <StyledTableCell
                                    component="th"
                                    scope="row"
                                    align="right"
                                  >
                                    {index + 1}
                                  </StyledTableCell>
                                  <StyledTableCell align="right">
                                    {row.customerName}
                                  </StyledTableCell>
                                  <StyledTableCell align="right">
                                    {row.paymentMode}
                                  </StyledTableCell>
                                  <StyledTableCell align="right">
                                    {row.amount}
                                  </StyledTableCell>
                                  <StyledTableCell align="right">
                                    {findEmployeeName(row.employeeId)}
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                )}
              </div>
              <div className="column">
                {data.expenseList && (
                  <div className="payments-sales-container">
                    <div className="totals-container is-flex is-justify-content-space-between is-align-items-center">
                      <div className="total-sales-heading">Expenses List</div>
                      <div className="total-amounts">{`Total: ${data.totalExpense}`}</div>
                    </div>

                    <div>
                      <TableContainer className="sales-table">
                        <Table
                          sx={{ fontFamily: "Roboto", borderRadius: 3 }}
                          aria-label="simple table"
                        >
                          <TableHead className="sales-table-head">
                            <TableRow>
                              <StyledTableCell align="right">
                                Sl.No
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                Description
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                Type
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                Amount
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data.expenseList &&
                              data.expenseList.map((row, index) => (
                                <StyledTableRow
                                  key={row.expenseId}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                  className="sales-list-table-row"
                                >
                                  <StyledTableCell
                                    component="th"
                                    scope="row"
                                    align="right"
                                  >
                                    {index + 1}
                                  </StyledTableCell>
                                  <StyledTableCell align="right">
                                    {row.description}
                                  </StyledTableCell>
                                  <StyledTableCell align="right">
                                    {row.expenseType}
                                  </StyledTableCell>
                                  <StyledTableCell align="right">
                                    {row.amount}
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="columns is-multiline">
              <div className="column">
                {data.attendanceRecords.attendanceRecords && (
                  <div className="payments-sales-container">
                    <div className="totals-container">
                      <div className="total-sales-heading">Attendance List</div>
                    </div>

                    <div>
                      <TableContainer className="sales-table">
                        <Table
                          sx={{ fontFamily: "Roboto", borderRadius: 3 }}
                          aria-label="simple table"
                        >
                          <TableHead className="sales-table-head">
                            <TableRow>
                              <StyledTableCell align="right">
                                Sl.No
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                Employee
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                Start Hour
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                End Hour
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                Total Hours
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data.attendanceRecords.attendanceRecords &&
                              data.attendanceRecords.attendanceRecords.map(
                                (row, index) => (
                                  <StyledTableRow
                                    key={`${row.employeeId}${row.totalWorkHours}`}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                    className="sales-list-table-row"
                                  >
                                    <StyledTableCell
                                      component="th"
                                      scope="row"
                                      align="right"
                                    >
                                      {index + 1}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                      {row.employeeName}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                      {row.startHour}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                      {row.endHour}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                      {row.totalWorkHours}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                )
                              )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <div className="no-data-container is-flex is-justify-content-center is-align-items-center">
            <div className="heading-4">No data found</div>
          </div>
        )}
      </div>
    </>
  );
};

export default DashboardContent;
