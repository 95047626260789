import { useState } from "react";
import { createStore } from "reusable";
import { ResponseType } from "../../../utils/types";
import { ProfitLossRequestData, ProfitLossResponseData } from "./types";
import ProfitLossService from "./Service";

const ProfitLossStore = createStore(() => {
  const [getProfitLosssReportSuccess, setGetProfitLosssReportSuccess] =
    useState<ProfitLossResponseData>();

  const [getProfitLosssReportError, setGetProfitLosssReportError] =
    useState<ResponseType<null>>();

  const clearGetProfitLosssReportResponses = (): void => {
    setGetProfitLosssReportSuccess({} as ProfitLossResponseData);
    setGetProfitLosssReportError({} as ResponseType<null>);
  };

  const getProfitLosssReport = (payload: ProfitLossRequestData): void => {
    ProfitLossService.getProfitLoss(payload)
      .then((response) => {
        setGetProfitLosssReportSuccess(response.data);
      })
      .catch((error) => {
        setGetProfitLosssReportError(error);
      });
  };

  return {
    getProfitLosssReportSuccess,
    getProfitLosssReportError,
    clearGetProfitLosssReportResponses,
    getProfitLosssReport,
  };
});

export default ProfitLossStore;
